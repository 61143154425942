/** @jsx jsx */
import { gql, useMutation, useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import { ProgramStepType, RestrictionType } from '@goodwatt/shared/dist/types'
import { useTheme } from 'emotion-theming'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import ActionCard from '../../../components/Card/ActionCard'
import DocumentCard from '../../../components/Card/DocumentCard'
import { IconTypes } from '../../../components/Icon'
import Loader from '../../../components/Loader'
import { useNotifications } from '../../../contexts/NotificationContext'
import usePromiseLazyQuery from '../../../hooks/usePromiseLazyQuery'
import { depositDoneAction } from '../../../redux/deposit'
import { StoreState } from '../../../redux/rootReducer'
import theme from '../../../styles/theme'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import {
  QUERY_GET_EMPLOYEE_DASHBOARD,
  QUERY_IDENTITY_CARD_BACK_DOCUMENT,
  QUERY_IDENTITY_CARD_FRONT_DOCUMENT,
  QUERY_PROOF_OF_ADDRESS_DOCUMENT,
} from '../../../utils/gql/queries'
import {
  DeploymentType,
  DeleteIdentityDocumentMutation,
  DeleteIdentityDocumentMutationVariables,
  EmployeeDocumentType,
  GetEmployeeDashboardQuery,
  GetEmployeeDashboardQueryVariables,
  GetEmployeeDepositLinkQuery,
  GetEmployeeDepositLinkQueryVariables,
  GetEmployeeIdentityCardBackQuery,
  GetEmployeeIdentityCardBackQueryVariables,
  GetEmployeeIdentityCardFrontQuery,
  GetEmployeeIdentityCardFrontQueryVariables,
  GetEmployeeProofOfAddressQuery,
  GetEmployeeProofOfAddressQueryVariables,
} from '../../../__generated__/graphql'
import UploadProfileDocuments from './UploadProfileDocuments'

const QUERY_GET_CAUTION_LINK = gql`
  query GetEmployeeDepositLink($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      getDepositLink
    }
  }
`

const MUTATION_DELETE_IDENTITY_DOCUMENT = gql`
  mutation DeleteIdentityDocument($documentType: employeeDocumentType!) {
    deleteIdentityDocument(documentType: $documentType)
  }
`

export enum DocStatus {
  NEED_UPLOAD = 'NEED_UPLOAD',
  VALIDATING = 'WAITING_VALIDATION',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
}

export const getStatus = (
  document: string | null | undefined,
  isVerified: boolean | null | undefined,
): DocStatus | undefined => {
  if (!document && isVerified === null) return DocStatus.NEED_UPLOAD
  if (document && isVerified === null) return DocStatus.VALIDATING
  if (document && isVerified) return DocStatus.VERIFIED
  if (document && isVerified === false) return DocStatus.REJECTED
}

export const getDepositStatus = (
  depositId: string | null | undefined,
  depositExpirationDate: Date | null | undefined,
): DocStatus => {
  if (!depositId) return DocStatus.NEED_UPLOAD
  if (depositExpirationDate && new Date() >= new Date(depositExpirationDate))
    return DocStatus.NEED_UPLOAD
  if (depositId) return DocStatus.VERIFIED
  return DocStatus.NEED_UPLOAD
}

const useQueryParameters = () => {
  return new URLSearchParams(useLocation().search)
}

const EmployeeDocuments: React.FC = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const params = useQueryParameters()
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<'ID_CARD' | 'ADDRESS' | null>(null)
  const { depositDone } = useSelector((state: StoreState) => state.deposit)
  const userId = useSelector((state: StoreState) => state.user.id)
  const notifications = useNotifications()
  const justHasDoneDeposit = params.get('deposit') === 'done'
  const { data, loading, refetch, startPolling, stopPolling } = useQuery<
    GetEmployeeDashboardQuery,
    GetEmployeeDashboardQueryVariables
  >(QUERY_GET_EMPLOYEE_DASHBOARD, {
    fetchPolicy: 'no-cache',
    variables: { where: { userId } },
  })
  const getEmployeeDepositLink = usePromiseLazyQuery<
    GetEmployeeDepositLinkQuery,
    GetEmployeeDepositLinkQueryVariables
  >(QUERY_GET_CAUTION_LINK)
  const queryGetProofOfAddress = usePromiseLazyQuery<
    GetEmployeeProofOfAddressQuery,
    GetEmployeeProofOfAddressQueryVariables
  >(QUERY_PROOF_OF_ADDRESS_DOCUMENT)
  const queryGetIdentityFront = usePromiseLazyQuery<
    GetEmployeeIdentityCardFrontQuery,
    GetEmployeeIdentityCardFrontQueryVariables
  >(QUERY_IDENTITY_CARD_FRONT_DOCUMENT)
  const queryGetIdentityBack = usePromiseLazyQuery<
    GetEmployeeIdentityCardBackQuery,
    GetEmployeeIdentityCardBackQueryVariables
  >(QUERY_IDENTITY_CARD_BACK_DOCUMENT)

  const [deleteIdentityDocument] = useMutation<
    DeleteIdentityDocumentMutation,
    DeleteIdentityDocumentMutationVariables
  >(MUTATION_DELETE_IDENTITY_DOCUMENT)

  const identityStatus = useMemo(
    () =>
      getStatus(
        data?.employee?.identityCardFrontFilePath,
        data?.employee?.identityCardVerified,
      ),
    [data],
  )

  const addressStatus = useMemo(
    () =>
      getStatus(
        data?.employee?.proofOfAddressFilePath,
        data?.employee?.proofOfAddressVerified,
      ),
    [data],
  )

  const depositStatus = useMemo(() => {
    if (data?.employee?.depositByCheque === true) {
      return DocStatus.VERIFIED
    }
    if (depositDone === true) return DocStatus.VERIFIED
    if (justHasDoneDeposit) {
      dispatch(depositDoneAction())
      return DocStatus.VERIFIED
    }
    return getDepositStatus(
      data?.employee?.depositId,
      data?.employee?.depositExpirationDate,
    )
  }, [data, justHasDoneDeposit, dispatch, depositDone])

  const onDepositCardClicked = useCallback(() => {
    if (depositStatus === DocStatus.VERIFIED) return
    getEmployeeDepositLink({
      where: {
        userId,
      },
    })
      .then(r => {
        if (r.data?.employee?.getDepositLink)
          window.location.replace(r.data?.employee?.getDepositLink)
      })
      .catch(apiErrorCatcher(notifications))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositStatus, getEmployeeDepositLink, userId])

  const getVariant = (
    status: DocStatus | undefined,
  ): 'secondary' | 'primary' | 'tertiary' => {
    if (status === DocStatus.NEED_UPLOAD || status === DocStatus.REJECTED)
      return 'secondary'
    if (status === DocStatus.VALIDATING || status === DocStatus.VERIFIED)
      return 'tertiary'
    return 'tertiary'
  }

  const getIconColor = (status: DocStatus | undefined): string => {
    if (status === DocStatus.NEED_UPLOAD) return theme.colors.primary
    if (status === DocStatus.VALIDATING) return theme.colors.yellowLegend
    if (status === DocStatus.REJECTED) return theme.colors.redLegend
    if (status === DocStatus.VERIFIED) return theme.colors.greenLegend
    return theme.colors.primary
  }

  const getProofOfAddress = () => {
    if (addressStatus !== DocStatus.NEED_UPLOAD) {
      queryGetProofOfAddress({
        where: { userId },
      })
        .then(r => {
          if (r.data?.employee?.getPresignedProofOfAddressDocument)
            window.open(
              r.data?.employee?.getPresignedProofOfAddressDocument,
              '_blank',
            )
        })
        .catch(apiErrorCatcher(notifications))
    }
  }

  const getIdentityCardFront = async () => {
    if (
      data?.employee?.identityCardFrontFilePath &&
      identityStatus !== DocStatus.NEED_UPLOAD
    ) {
      const result = await queryGetIdentityFront({ where: { userId } })
      const doc = result.data?.employee?.getPresignedIdentityCardFrontDocument
      if (doc) window.open(doc, '_blank')
    }
  }

  const getIdentityCardBack = async () => {
    if (
      data?.employee?.identityCardBackFilePath &&
      identityStatus !== DocStatus.NEED_UPLOAD
    ) {
      const result = await queryGetIdentityBack({ where: { userId } })
      const doc = result.data?.employee?.getPresignedIdentityCardBackDocument
      if (doc) window.open(doc, '_blank')
    }
  }

  const deleteDocument = (documentType: EmployeeDocumentType) => {
    deleteIdentityDocument({
      variables: {
        documentType,
      },
    })
      .then(() => {
        refetch()
      })
      .catch(apiErrorCatcher(notifications))
  }

  const cautionStatus = useMemo((): DocStatus => {
    if (data?.employee?.depositByCheque === true) {
      return DocStatus.VERIFIED
    }

    if (
      data?.employee?.depositId &&
      data?.employee?.depositExpirationDate &&
      new Date() < new Date(data?.employee?.depositExpirationDate)
    )
      return DocStatus.VERIFIED
    return getDepositStatus(
      data?.employee?.depositId,
      data?.employee?.depositExpirationDate,
    )
  }, [data])

  // TODO: What if user have multiple deployments?
  const employeeDeployment = data?.employee?.employeeDeployments
    ? data.employee.employeeDeployments[0]
    : null

  const employeeDeploymentType =
    employeeDeployment?.deployment?.deploymentType || DeploymentType.None
  const employeeDeploymentStatus = employeeDeployment?.status || ''
  const employeeDeploymentProgramStep =
    employeeDeployment?.deployment?.programStep?.stepNbr || 0

  const showDepositRemember =
    employeeDeploymentType === DeploymentType.Discovery &&
    (employeeDeploymentStatus === 'SELECTED' ||
      employeeDeploymentStatus === 'BENEFICIARY') &&
    cautionStatus !== DocStatus.VERIFIED &&
    [
      ProgramStepType.PREPARING_LOAN,
      ProgramStepType.LOAN_BIKES_IN_PROGRESS,
    ].includes(employeeDeploymentProgramStep)

  React.useEffect(() => {
    startPolling(5000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  if (loading && !data) return <Loader />

  return (
    <div css={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
      <div css={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
        <div css={wrapperStyles}>
          <ActionCard
            variant={getVariant(identityStatus)}
            iconColor={getIconColor(identityStatus)}
            label={((): string => {
              if (identityStatus === DocStatus.NEED_UPLOAD) return t('employee.documents.idCardStatus.upload'); // prettier-ignore
              if (identityStatus === DocStatus.VALIDATING) return t('employee.documents.idCardStatus.waitingValidation'); // prettier-ignore
              if (identityStatus === DocStatus.REJECTED) return t('employee.documents.idCardStatus.refused'); // prettier-ignore
              if (identityStatus === DocStatus.VERIFIED) return t('employee.documents.idCardStatus.accepted'); // prettier-ignore
              return ''
            })()}
            actions={[
              ...(identityStatus !== DocStatus.NEED_UPLOAD &&
              data?.employee?.identityCardFrontFilePath
                ? [
                    {
                      icon: 'download' as IconTypes,
                      onClick: () => {
                        getIdentityCardFront()
                      },
                    },
                  ]
                : []),
              ...(identityStatus !== DocStatus.NEED_UPLOAD &&
              data?.employee?.identityCardBackFilePath
                ? [
                    {
                      icon: 'download' as IconTypes,
                      onClick: () => {
                        getIdentityCardBack()
                      },
                    },
                  ]
                : []),
              ...(identityStatus === DocStatus.VALIDATING &&
              data?.employee?.identityCardFrontFilePath
                ? [
                    {
                      icon: 'trash' as IconTypes,
                      onClick: () => {
                        deleteDocument(EmployeeDocumentType.IdCard)
                      },
                    },
                  ]
                : []),
            ]}
            onCardClick={
              identityStatus === DocStatus.NEED_UPLOAD ||
              identityStatus === DocStatus.REJECTED
                ? () => setOpenModal('ID_CARD')
                : undefined
            }
            icon="idCard"
          />
        </div>
        <div css={wrapperStyles}>
          <ActionCard
            variant={getVariant(addressStatus)}
            iconColor={getIconColor(addressStatus)}
            icon="address"
            label={((): string => {
              if (addressStatus === DocStatus.NEED_UPLOAD) return t('employee.documents.proofOfAddressStatus.upload'); // prettier-ignore
              if (addressStatus === DocStatus.VALIDATING) return t('employee.documents.proofOfAddressStatus.waitingValidation'); // prettier-ignore
              if (addressStatus === DocStatus.REJECTED) return t('employee.documents.proofOfAddressStatus.refused'); // prettier-ignore
              if (addressStatus === DocStatus.VERIFIED) return t('employee.documents.proofOfAddressStatus.accepted'); // prettier-ignore
              return ''
            })()}
            actions={[
              ...(addressStatus !== DocStatus.NEED_UPLOAD &&
              data?.employee?.proofOfAddressFilePath
                ? [
                    {
                      icon: 'download' as IconTypes,
                      onClick: () => {
                        getProofOfAddress()
                      },
                    },
                  ]
                : []),
              ...(addressStatus === DocStatus.VALIDATING &&
              data?.employee?.proofOfAddressFilePath
                ? [
                    {
                      icon: 'trash' as IconTypes,
                      onClick: () => {
                        deleteDocument(EmployeeDocumentType.Address)
                      },
                    },
                  ]
                : []),
            ]}
            onCardClick={
              addressStatus === DocStatus.NEED_UPLOAD ||
              addressStatus === DocStatus.REJECTED
                ? () => setOpenModal('ADDRESS')
                : undefined
            }
          />
        </div>

        {data?.employee?.selectLoan &&
          data?.employee?.contractUrl &&
          data?.employee?.company?.programStep?.stepNbr >=
            ProgramStepType.PREPARING_LOAN && (
            <div css={wrapperStyles}>
              <ActionCard
                variant="tertiary"
                label="Mon contrat de prêt"
                icon="conventionAlt"
                iconColor="#828282"
                actions={[
                  {
                    icon: 'download' as IconTypes,
                    onClick: () =>
                      data?.employee?.contractUrl
                        ? window.open(data.employee.contractUrl, '_blank')
                        : null,
                  },
                ]}
              />
            </div>
          )}

        {data?.employee?.remissionOrderUrl &&
          data?.employee?.company?.programStep?.stepNbr >=
            ProgramStepType.PREPARING_LOAN && (
            <div css={wrapperStyles}>
              <ActionCard
                variant="tertiary"
                label="Mon bon de remise"
                icon="conventionAlt"
                iconColor="#828282"
                actions={[
                  {
                    icon: 'download' as IconTypes,
                    onClick: () =>
                      data?.employee?.remissionOrderUrl
                        ? window.open(data.employee.remissionOrderUrl, '_blank')
                        : null,
                  },
                ]}
              />
            </div>
          )}

        {showDepositRemember && (
          <div css={wrapperStyles}>
            <ActionCard
              variant={getVariant(depositStatus)}
              label={
                depositStatus === DocStatus.VERIFIED
                  ? t('employee.documents.depositStatus.accepted')
                  : t('employee.documents.depositStatus.upload')
              }
              icon="deposit"
              iconColor={getIconColor(depositStatus)}
              onCardClick={
                depositStatus === DocStatus.NEED_UPLOAD
                  ? onDepositCardClicked
                  : undefined
              }
            />
          </div>
        )}
      </div>
      <div css={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {data?.employee?.documentList?.map(doc => (
          <DocumentCard
            key={doc?.id}
            id={doc?.id as string}
            refetch={refetch}
            title={doc?.title as string}
            filePath={doc?.filePath as string}
            size={doc?.size as number}
            date={doc?.createdAt}
            documentRestrictions={doc?.restrictions as string[]}
            deletable={doc?.restrictions?.includes(RestrictionType.EMPLOYEE_D)}
            downloadable={doc?.restrictions?.includes(
              RestrictionType.EMPLOYEE_R,
            )}
          />
        ))}
      </div>
      <UploadProfileDocuments
        isOpen={openModal}
        closeModal={() => setOpenModal(null)}
        refetch={refetch}
      />
    </div>
  )
}

export default EmployeeDocuments

const wrapperStyles = {
  margin: '0 24px 24px 0',
  [theme.media.mobile]: {
    margin: '0 0px 24px 0',
    width: '100%',
  },
}
