/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { Row } from 'react-table'

import {
  CompanySelectedEmployeesTableRow,
  CompanySelectedEmployeesTableRowDetails,
} from '../../../types/AnimatorCompanyEmployees'
import Icon from '../../Icon'
import { DeploymentType } from '../../../__generated__/graphql'

interface EmployeeDocumentCellProps {
  setModal: (employeeId: string) => void
  row: Row<
    CompanySelectedEmployeesTableRow &
      Partial<CompanySelectedEmployeesTableRowDetails>
  >
  deploymentType: DeploymentType | null | undefined
}

const EmployeeDocumentCell: React.FC<EmployeeDocumentCellProps> = ({
  row: { original: row },
  setModal,
  deploymentType,
}) => {
  const theme = useTheme()

  const identityColor = (): string => {
    if (row.employee.identityCardVerified) return theme.colors.greenLegend
    if (row.employee.identityCardVerified === false)
      return theme.colors.redLegend
    if (
      row.employee.identityCardFrontFilePath &&
      (row.identityCardVerified === null ||
        row.identityCardVerified === undefined)
    )
      return theme.colors.orangeLegend
    return theme.colors.gray1
  }

  const proofOfAddressColor = (): string => {
    if (row.employee.proofOfAddressVerified) return theme.colors.greenLegend
    if (row.employee.proofOfAddressVerified === false)
      return theme.colors.redLegend
    if (
      row.employee.proofOfAddressFilePath &&
      (row.proofOfAddressVerified === null ||
        row.proofOfAddressVerified === undefined)
    )
      return theme.colors.orangeLegend
    return theme.colors.gray1
  }

  const depositColor = (): string => {
    // if (row.employee.depositReleasedAt) {
    //   return theme.colors.gray1
    // }
    if (row.employee.depositByCheque) {
      return theme.colors.greenLegend
    }
    if (
      row.employee.depositId &&
      row.employee.depositExpirationDate &&
      new Date() < new Date(row.employee.depositExpirationDate)
    )
      return theme.colors.greenLegend
    if (
      row.employee.depositId &&
      row.employee.depositExpirationDate &&
      new Date() >= new Date(row.employee.depositExpirationDate)
    )
      return theme.colors.redLegend
    if (!row.employee.depositId) return theme.colors.gray1
    return theme.colors.gray1
  }

  return (
    <div
      onClick={() => setModal(row.employee.id)}
      css={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
      }}
    >
      <div css={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Icon
          type="identity"
          color={identityColor()}
          customSize={{
            width: 26,
            height: 26,
          }}
        />
        <Icon
          type="home2"
          color={proofOfAddressColor()}
          customSize={{
            width: 26,
            height: 26,
          }}
        />
        {deploymentType !== DeploymentType.Rental && (
          <Icon
            type="mastercard"
            color={depositColor()}
            customSize={{
              width: 26,
              height: 26,
            }}
          />
        )}
      </div>
    </div>
  )
}

export default EmployeeDocumentCell
