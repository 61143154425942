import { gql, useQuery } from '@apollo/client'

export const QUERY_COMPANIES_WITH_DEPLOYMENTS = gql`
  query companiesWithDeployments(
    $multiFilter: String
    $type: String
    $fleet: String
    $programStep: Int
    $programSteps: [Int]
    $department: String
    $archived: Boolean
    $take: Int
    $skip: Int
    $orderBy: CompanyOrderByInput
  ) {
    companiesSearch(
      multiFilter: $multiFilter
      type: $type
      fleet: $fleet
      programSteps: $programSteps
      programStep: $programStep
      department: $department
      archived: $archived
      onlyShowAssignedCompanies: true
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      name
      subscriptionCode
      siteCount
      deploymentCount
    }
  }
`

export interface UseCompaniesWithDeploymentsParam {
  variables?: Record<string, unknown>
}

export const useCompaniesWithDeployments = ({
  variables,
}: UseCompaniesWithDeploymentsParam) => {
  return useQuery(QUERY_COMPANIES_WITH_DEPLOYMENTS, {
    variables,
  })
}
