import React from 'react'
import { useTranslation } from 'react-i18next'

import { UserRoles } from '../redux/user'
import { IconTypes } from '../components/Icon'
import { useSelector } from 'react-redux'
import { StoreState } from '../redux/rootReducer'
import { useQuery } from '@apollo/client'
import {
  QUERY_ANIMATOR_DEPLOYMENT,
  QUERY_GET_COMPANY,
  QUERY_GET_EMPLOYEE_EMPLOYEEDEPLOYMENT,
} from '../utils/gql/queries'
import {
  GetCompanyProfileQuery,
  GetCompanyProfileQueryVariables,
  GetDeploymentQuery,
  GetDeploymentQueryVariables,
  GetEmployeeEmployeeDeploymentQuery,
  GetEmployeeEmployeeDeploymentQueryVariables,
} from '../__generated__/graphql'

import { useRouteMatch } from 'react-router-dom'

export type NavItem = NavItemActive | NavItemIdle

export interface NavItemActive {
  icon: IconTypes
  title: string
  url: string
}

export interface NavItemIdle {
  icon: IconTypes
  title: string
  url: string
  collapsible: boolean
  idleHidden: true
  subNav: SubNavItem[]
}

export interface SubNavItem {
  title: string
  lastPathUrl: string
  titleRedirectUrl?: string
  overideUrl?: boolean
  groupTitle?: string
}

const useMenu = (): { [userRoleName in UserRoles]: NavItem[] } => {
  const { t } = useTranslation()
  const submenuName = useSelector(
    (state: StoreState) => state.menu.subMenuTitle,
  )
  const { role, id } = useSelector((state: StoreState) => state.user)

  const administratorDeploymentMatch = useRouteMatch<{ deploymentId?: string }>(
    '/administrator/dashboard/:deploymentId',
  )

  const animatorDeploymentMatch = useRouteMatch<{ deploymentId?: string }>(
    '/animator/dashboard/:deploymentId',
  )

  const companyDeploymentMatch = useRouteMatch<{ deploymentId?: string }>(
    '/company/dashboard/:deploymentId',
  )

  const deploymentId =
    animatorDeploymentMatch?.params.deploymentId ??
    companyDeploymentMatch?.params.deploymentId ??
    administratorDeploymentMatch?.params.deploymentId

  const { data: deploymentQuery } = useQuery<
    GetDeploymentQuery,
    GetDeploymentQueryVariables
  >(QUERY_ANIMATOR_DEPLOYMENT, {
    variables: { id: deploymentId as string },
    skip:
      deploymentId == null ||
      (role !== UserRoles.ADMIN &&
        role !== UserRoles.ANIMATOR &&
        role !== UserRoles.COMPANY),
  })

  const animatorCompanyMatch = useRouteMatch<{ companyId?: string }>(
    '/animator/companies/:companyId',
  )

  const administratorCompanyMatch = useRouteMatch<{ companyId?: string }>(
    '/administrator/companies/:companyId',
  )

  const companyId =
    animatorCompanyMatch?.params.companyId ??
    administratorCompanyMatch?.params.companyId

  const { data: companyQuery } = useQuery<
    GetCompanyProfileQuery,
    GetCompanyProfileQueryVariables
  >(QUERY_GET_COMPANY, {
    variables: { where: { id: companyId } },
    skip: companyId == null,
  })

  const { data: employeeDeployments } = useQuery<
    GetEmployeeEmployeeDeploymentQuery,
    GetEmployeeEmployeeDeploymentQueryVariables
  >(QUERY_GET_EMPLOYEE_EMPLOYEEDEPLOYMENT, {
    variables: {
      where: {
        employee: {
          userId: {
            equals: id,
          },
        },
      },
    },
    skip: id == null || id === '' || role !== UserRoles.EMPLOYEE,
  })

  let employeeHasDeploymentActive = false

  if (employeeDeployments) {
    const findEd = employeeDeployments.employeeDeployments?.find(
      ed =>
        ed.deployment?.programStep?.stepNbr >= 3 &&
        (ed.status === 'SELECTED' || ed.status === 'BENEFICIARY'),
    )

    if (findEd) {
      employeeHasDeploymentActive = true
    }
  }

  const animatorMenu: NavItem[] = React.useMemo(() => {
    return [
      // {
      //   icon: 'home',
      //   title: t('animator.menu.home'),
      //   url: '/animator/dashboard',
      // },
      // {
      //   icon: 'profile',
      //   title: t('animator.menu.profile'),
      //   url: '/animator/profile',
      // },
      {
        icon: 'home',
        title: t('animator.menu.deployments.title'),
        url: '/animator/dashboard',
        collapsible: deploymentQuery != null,
        subNav: deploymentQuery
          ? [
              {
                title: t('animator.menu.deployments.subMenu.progress'),
                lastPathUrl: `/animator/dashboard/${deploymentId}/progress`,
                overideUrl: true,
                groupTitle: deploymentQuery.deployment?.name,
              },
              {
                title: t('animator.menu.deployments.subMenu.monitoring'),
                lastPathUrl: `/animator/dashboard/${deploymentId}/monitoring`,
                overideUrl: true,
              },
              {
                title: t('animator.menu.deployments.subMenu.users'),
                lastPathUrl: `/animator/dashboard/${deploymentId}/users`,
                overideUrl: true,
              },
            ]
          : undefined,
      },
      {
        icon: 'company',
        title: t('animator.menu.company.title'),
        url: '/animator/companies',
        collapsible: companyQuery != null,
        subNav: companyQuery
          ? [
              {
                title: t('animator.menu.company.subMenu.progress'),
                lastPathUrl: `/animator/companies/${companyId}/progress`,
                overideUrl: true,
                groupTitle: companyQuery.company?.name,
              },
              {
                title: t('animator.menu.company.subMenu.profile'),
                lastPathUrl: `/animator/companies/${companyId}/profile`,
                overideUrl: true,
              },
              {
                title: t('animator.menu.company.subMenu.employee'),
                lastPathUrl: `/animator/companies/${companyId}/employees`,
                overideUrl: true,
              },
            ]
          : undefined,
      },
      {
        icon: 'bikeMenu',
        title: t('animator.menu.bike'),
        url: '/animator/bikes',
      },
      {
        icon: 'target',
        idleHidden: true,
        collapsible: false,
        url: '/animator/company',
        title: submenuName,
        subNav: [
          {
            title: t('animator.menu.company.subMenu.progress'),
            lastPathUrl: '/progress',
          },
          {
            title: t('animator.menu.company.subMenu.profile'),
            lastPathUrl: '/profile',
          },
          {
            title: t('animator.menu.company.subMenu.employee'),
            lastPathUrl: '/employees',
          },
          {
            title: t('animator.menu.company.subMenu.monitoring'),
            lastPathUrl: '/monitoring',
          },
          {
            title: t('animator.menu.company.subMenu.document'),
            lastPathUrl: '/documents',
          },
        ],
      },
      {
        icon: 'entrepriseSelect',
        idleHidden: false,
        collapsible: true,
        url: '/animator/application',
        title: t('animator.menu.application'),
        subNav: [
          {
            title: t('animator.menu.faq'),
            overideUrl: true,
            lastPathUrl: '/animator/application/faq',
          },
          {
            title: t('animator.menu.advices'),
            overideUrl: true,
            lastPathUrl: '/animator/application/advices',
          },
          {
            title: t('animator.menu.quizzes'),
            overideUrl: true,
            lastPathUrl: '/animator/application/quizzes',
          },
        ],
      },
    ]
  }, [t, submenuName, deploymentQuery, companyQuery, deploymentId, companyId])

  const companyMenu: NavItem[] = React.useMemo(() => {
    const deploymentName = deploymentQuery?.deployment?.name

    return [
      {
        icon: 'home',
        title: t('company.menu.sites'),
        url: '/company/dashboard',
        collapsible: deploymentName != null,
        subNav: deploymentName
          ? [
              {
                title: submenuName,
                titleRedirectUrl: `/company/dashboard`,
              },
              {
                title: t('company.menu.monitoring'),
                overideUrl: true,
                lastPathUrl: `/company/dashboard/${deploymentId}/monitoring`,
              },
              {
                title: t('company.menu.siteEmployees'),
                overideUrl: true,
                lastPathUrl: `/company/dashboard/${deploymentId}/employees`,
              },
            ]
          : undefined,
      },
      {
        icon: 'users',
        title: t('company.menu.employees'),
        url: '/company/employees',
      },
      {
        icon: 'target',
        title: t('company.menu.myCompany'),
        url: '/company/profile',
        idleHidden: false,
        collapsible: false,
        subNav: [
          {
            title: t('company.menu.informations'),
            overideUrl: true,
            lastPathUrl: '/company/infos',
          },
          {
            title: t('company.menu.allReferrers'),
            overideUrl: true,
            lastPathUrl: '/company/referrers',
          },
          {
            title: t('company.menu.allSites'),
            overideUrl: true,
            lastPathUrl: '/company/sites',
          },
        ],
      },
      {
        icon: 'helpContact',
        title: t('company.menu.helpAndContact'),
        url: '/company/help',
      },
    ]
  }, [t, submenuName, deploymentId, deploymentQuery])

  const employeeMenu: NavItem[] = React.useMemo(
    () => [
      {
        icon: 'home',
        title: t('employee.menu.home'),
        url: '/employee/dashboard',
      },
      // {
      //   icon: 'profile',
      //   title: t('employee.menu.profile'),
      //   url: '/employee/profile',
      // },
      // {
      //   icon: 'documents',
      //   title: t('employee.menu.documents'),
      //   url: '/employee/documents',
      // },
      // {
      //   icon: 'distance',
      //   title: t('employee.menu.path'),
      //   url: '/employee/path',
      // },
      ...(employeeHasDeploymentActive
        ? [
            {
              icon: 'graph',
              title: t('employee.menu.monitoring'),
              url: '/employee/monitoring',
            } as NavItemActive,
          ]
        : []),
      // {
      //   icon: 'youtube',
      //   title: t('employee.menu.videos'),
      //   url: '/employee/tutos',
      // },
      // ...(companyProgramStep?.stepNbr === ProgramStepType.LOAN_BIKES_IN_PROGRESS
      //   ? [
      //       {
      //         icon: 'bikeMenu',
      //         title: t('employee.menu.bikeMaterials'),
      //         url: '/employee/materials',
      //       } as NavItemActive,
      //     ]
      //   : []),
      {
        icon: 'contact',
        title: t('employee.menu.help'),
        url: '/employee/help',
      },
    ],
    [employeeHasDeploymentActive, t],
  )

  const administratorMenu: NavItem[] = React.useMemo(
    () => [
      {
        icon: 'home',
        title: t('animator.menu.deployments.adminTitle'),
        url: '/administrator/dashboard',
        collapsible: deploymentQuery != null,
        subNav: deploymentQuery
          ? [
              {
                title: t('animator.menu.deployments.subMenu.progress'),
                lastPathUrl: `/administrator/dashboard/${deploymentId}/progress`,
                overideUrl: true,
                groupTitle: deploymentQuery.deployment?.name,
              },
              {
                title: t('animator.menu.deployments.subMenu.monitoring'),
                lastPathUrl: `/administrator/dashboard/${deploymentId}/monitoring`,
                overideUrl: true,
              },
              {
                title: t('animator.menu.deployments.subMenu.users'),
                lastPathUrl: `/administrator/dashboard/${deploymentId}/users`,
                overideUrl: true,
              },
            ]
          : undefined,
      },
      {
        icon: 'company',
        title: t('animator.menu.company.adminTitle'),
        url: '/administrator/companies',
        collapsible: companyQuery != null,
        subNav: companyQuery
          ? [
              {
                title: t('animator.menu.company.subMenu.progress'),
                lastPathUrl: `/administrator/companies/${companyId}/progress`,
                overideUrl: true,
                groupTitle: companyQuery.company?.name,
              },
              {
                title: t('animator.menu.company.subMenu.profile'),
                lastPathUrl: `/administrator/companies/${companyId}/profile`,
                overideUrl: true,
              },
              {
                title: t('animator.menu.company.subMenu.employee'),
                lastPathUrl: `/administrator/companies/${companyId}/employees`,
                overideUrl: true,
              },
            ]
          : undefined,
      },
      {
        icon: 'target',
        idleHidden: true,
        collapsible: false,
        url: '/administrator/company',
        title: submenuName,
        subNav: [
          {
            title: t('animator.menu.company.subMenu.progress'),
            lastPathUrl: '/progress',
          },
          {
            title: t('animator.menu.company.subMenu.profile'),
            lastPathUrl: '/profile',
          },
          {
            title: t('animator.menu.company.subMenu.employee'),
            lastPathUrl: '/employees',
          },
          {
            title: t('animator.menu.company.subMenu.monitoring'),
            lastPathUrl: '/monitoring',
          },
          {
            title: t('animator.menu.company.subMenu.document'),
            lastPathUrl: '/documents',
          },
        ],
      },
      {
        icon: 'bikeMenu',
        title: t('animator.menu.adminBike'),
        url: '/administrator/bikes',
      },
      // {
      //   icon: 'profile',
      //   title: t('animator.menu.profile'),
      //   url: '/administrator/profile',
      // },
      {
        icon: 'animators',
        title: t('animator.menu.animators'),
        url: '/administrator/animators',
      },
      {
        icon: 'entrepriseSelect',
        idleHidden: false,
        collapsible: true,
        url: '/administrator/application',
        title: t('animator.menu.application'),
        subNav: [
          {
            title: t('animator.menu.faq'),
            overideUrl: true,
            lastPathUrl: '/administrator/application/faq',
          },
          {
            title: t('animator.menu.advices'),
            overideUrl: true,
            lastPathUrl: '/administrator/application/advices',
          },
          {
            title: t('animator.menu.quizzes'),
            overideUrl: true,
            lastPathUrl: '/administrator/application/quizzes',
          },
          {
            title: t('animator.menu.exports'),
            overideUrl: true,
            lastPathUrl: '/administrator/application/exports',
          },
        ],
      },
    ],
    [t, submenuName, companyQuery, deploymentQuery, deploymentId, companyId],
  )

  /* eslint-enable @typescript-eslint/no-explicit-any */

  return {
    [UserRoles.ANIMATOR]: animatorMenu,
    [UserRoles.EMPLOYEE]: employeeMenu,
    [UserRoles.COMPANY]: companyMenu,
    [UserRoles.ADMIN]: administratorMenu,
  }
}

export default useMenu
