/** @jsx jsx */
import { gql, useQuery, useMutation } from '@apollo/client'
import { jsx } from '@emotion/core'
import { PackageAccessoryType } from '@goodwatt/shared/dist/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../../../components/Button'
import Modal from '../../../../../../components/Modal'
import Popup from '../../../../../../components/Popup'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../../../contexts/NotificationContext'
import i18n from '../../../../../../i18n/config'
import {
  resetReturnBikeForm,
  setReturnBikeForm,
} from '../../../../../../redux/forms'
import { StoreState } from '../../../../../../redux/rootReducer'

import apiErrorCatcher from '../../../../../../utils/apiErrorCatcher'
import { employeeNameFormatter } from '../../../../../../utils/formatter'
import {
  FinishPackageLoanMutation,
  FinishPackageLoanMutationVariables,
  GetEmployeePackageLoanQuery,
  GetEmployeePackageLoanQueryVariables,
  GetEmployeeQuery,
} from '../../../../../../__generated__/graphql'
import ReturnAccessoriesCondition from './ReturnAccessoriesCondition'

const QUERY_GET_EMPLOYEE_BIKE_LOAN = gql`
  query GetEmployeePackageLoan($employeeId: String!) {
    employee(where: { id: $employeeId }) {
      bikeLoan {
        id
        bike {
          id
          stickerId
        }
        loanPackages {
          id
          note
          packageAccessory {
            name
          }
          stateOfUse {
            name
          }
          bikeLoanAttachments {
            id
            title
            size
          }
        }
      }
    }
  }
`

const MUTATION_FINISH_PACKAGE_LOAN = gql`
  mutation FinishPackageLoan(
    $bikeLoanId: ID!
    $employeeId: ID!
    $loanPackages: [LoanPackageInput!]
  ) {
    finishPackageLoan(
      bikeLoanId: $bikeLoanId
      employeeId: $employeeId
      loanPackages: $loanPackages
    )
  }
`

interface ReturnBikeModalProps {
  closeModal: () => void
  employee: GetEmployeeQuery['employee'] | null
  refetchTables: () => void
  modalIsOpen: boolean
}

const ReturnBikeModal: React.FC<ReturnBikeModalProps> = ({
  employee,
  closeModal,
  refetchTables,
  modalIsOpen,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { returnBikeForm } = useSelector((state: StoreState) => state.forms)
  const notifications = useNotifications()

  const { loading: loadingCheckReturn } = useQuery<
    GetEmployeePackageLoanQuery,
    GetEmployeePackageLoanQueryVariables
  >(QUERY_GET_EMPLOYEE_BIKE_LOAN, {
    variables: {
      employeeId: employee?.id || '',
    },
    skip: !employee?.id,
    onCompleted: (data: GetEmployeePackageLoanQuery) => {
      if (data?.employee?.bikeLoan) {
        //TODO: not correct bikeloan !!! missing deployment relation
        const loanAccessories = data.employee.bikeLoan[0].loanPackages.reduce<
          StoreState['forms']['returnBikeForm']['accessories']
        >(
          (acc, accessory) => ({
            ...acc,
            [accessory.packageAccessory.name as PackageAccessoryType]: {
              id: accessory.id,
              missing: false,
              stateOfUse: accessory.stateOfUse.name,
              note: accessory.note,
              attachments: accessory.bikeLoanAttachments,
            },
          }),
          {},
        )

        dispatch(
          setReturnBikeForm({
            stickerId: data.employee.bikeLoan[0].bike?.stickerId,
            bikeLoanId: data.employee.bikeLoan[0].id,
            accessories: loanAccessories,
          }),
        )
        return true
      }
    },
  })
  const [finishPackageLoan, { loading: loadingFinishBikeLoan }] = useMutation<
    FinishPackageLoanMutation,
    FinishPackageLoanMutationVariables
  >(MUTATION_FINISH_PACKAGE_LOAN)

  const loading = loadingCheckReturn && loadingFinishBikeLoan

  const onSubmit = async (): Promise<void> => {
    // Handle step 0 onNext
    if (!loading) {
      const loanPackages = Object.entries(returnBikeForm.accessories).map(
        ([partName, value]) => ({
          id: value?.id || '',
          name: partName,
          stateOfUse: value?.stateOfUse || '',
          note: value?.note,
        }),
      )
      await finishPackageLoan({
        variables: {
          bikeLoanId: returnBikeForm.bikeLoanId,
          employeeId: employee?.id || '',
          ...(loanPackages?.length ? { loanPackages } : {}),
        },
      })
        .then(() => {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: i18n.t(
              'animator.company.employees.selected.modals.employeeReturnBikeModal.successReturnPackagesNotification',
            ),
          })
          dispatch(resetReturnBikeForm())
          return refetchTables()
        })
        .then(() => closeModal())
        .catch(apiErrorCatcher(notifications))
    }
  }

  return (
    <Modal isOpen={modalIsOpen} onBackgroundClick={closeModal}>
      <Popup
        closable
        title={t(
          'animator.company.employees.selected.modals.employeeReturnBikeModal.returnPackagesCondition.title',
        )}
        onClose={closeModal}
        maxWidth={750}
        footer={
          <React.Fragment>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="tertiary"
                submit
                loading={loading}
                onClick={() => closeModal()}
              >
                {t('forms.button.cancel')}
              </Button>
            </div>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="primary"
                submit
                loading={loading}
                onClick={() => onSubmit()}
              >
                {t('forms.button.confirm')}
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <p
          css={{ textAlign: 'center', marginBottom: 20 }}
          dangerouslySetInnerHTML={{
            __html: t(
              'animator.company.employees.selected.modals.employeeReturnBikeModal.returnPackagesCondition.body',
              {
                employeeName: employeeNameFormatter(
                  employee?.firstName,
                  employee?.lastName,
                ),
              },
            ),
          }}
        />
        <ReturnAccessoriesCondition />
      </Popup>
    </Modal>
  )
}

export default ReturnBikeModal
