/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n/config'
import Button from '../Button'
import Modal from '../Modal'
import Popup from '../Popup'

interface ModalDelitionConfirmationProps {
  isOpen: boolean
  closeModal: () => void
  onDelete: () => void
}

const ModalDeletionConfirmation: React.FC<ModalDelitionConfirmationProps> = ({
  isOpen,
  closeModal,
  onDelete,
}) => {
  const { t } = useTranslation()

  const loading = false

  return (
    <Modal isOpen={isOpen} onBackgroundClick={closeModal}>
      <Popup
        closable={false}
        title={t('animator.company.documents.modals.title')}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button disabled={loading} onClick={closeModal} type="tertiary">
                {i18n.t('shared.button.cancel')}
              </Button>
            </div>
            <Button submit type="primary" loading={loading} onClick={onDelete}>
              {i18n.t('shared.button.confirm')}
            </Button>
          </Fragment>
        }
      >
        <p css={{ textAlign: 'center', padding: '0 18px' }}>
          {t('animator.company.documents.modals.deletionMessage')}
        </p>
      </Popup>
    </Modal>
  )
}

export default ModalDeletionConfirmation
