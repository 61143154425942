/** @jsx jsx */
import { useMutation } from '@apollo/client'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@goodwatt/client/src/components/Button'
import Modal from '@goodwatt/client/src/components/Modal'
import Popup from '@goodwatt/client/src/components/Popup'

import {
  useNotifications,
  NotificationTypes,
} from '@goodwatt/client/src/contexts/NotificationContext'

import i18n from '@goodwatt/client/src/i18n/config'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'

import { BikeModelTableRow } from '@goodwatt/client/src/types/AnimatorBikes'
import {
  DeleteOneBikeModelMutation,
  DeleteOneBikeModelMutationVariables,
} from '../../../__generated__/graphql'
import { MUTATION_DELETE_ONE_BIKEMODEL } from '../../../utils/gql/mutations'

interface ModalConfirmationProps {
  isOpen: boolean
  bikeModel: BikeModelTableRow | undefined
  closeModal: () => void
  refetch: () => void
}

const DeleteBikeModelModal: React.FC<ModalConfirmationProps> = ({
  isOpen,
  bikeModel,
  closeModal,
  refetch,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const notifications = useNotifications()
  const [deleteBikeModel, { loading }] = useMutation<
    DeleteOneBikeModelMutation,
    DeleteOneBikeModelMutationVariables
  >(MUTATION_DELETE_ONE_BIKEMODEL, {
    onCompleted: () => {
      notifications.newNotification({
        type: NotificationTypes.SUCCESS,
        message: t('animator.bikeModels.modal.delete.success'),
      })
      refetch()
      closeModal()
    },
    onError: e => {
      apiErrorCatcher(notifications)
      notifications.newNotification({
        type: NotificationTypes.ERROR,
        message: t('animator.bikeModels.modal.delete.failure', {
          modelName: bikeModel?.name,
        }),
      })
      closeModal()
    },
  })

  const onSubmit = async () => {
    if (!bikeModel?.id) return

    await deleteBikeModel({
      variables: { bikeModelId: bikeModel.id },
    })
  }

  return (
    <Modal isOpen={isOpen} onBackgroundClick={closeModal}>
      <Popup
        closable={false}
        title={t('animator.bikeModels.modal.delete.title')}
        titleColor={theme.colors.primary}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button
                disabled={loading}
                onClick={() => closeModal()}
                type="tertiary"
              >
                {i18n.t('shared.button.cancel')}
              </Button>
            </div>
            <Button submit type="primary" loading={loading} onClick={onSubmit}>
              {i18n.t('shared.button.confirm')}
            </Button>
          </Fragment>
        }
      >
        <p
          css={{
            textAlign: 'center',
            padding: '0 18px',
            color: theme.colors.redLegend,
            fontWeight: 700,
          }}
        >
          {t('animator.bikeModels.modal.delete.description')}
        </p>

        <p
          css={{ textAlign: 'center', padding: '0 18px', marginTop: 18 }}
          dangerouslySetInnerHTML={{
            __html: t('animator.bikeModels.modal.delete.confim', {
              modelName: bikeModel?.name,
            }),
          }}
        ></p>
      </Popup>
    </Modal>
  )
}

export default DeleteBikeModelModal
