/** @jsx jsx */
import { gql, useMutation } from '@apollo/client'
import { jsx } from '@emotion/core'
import { PackageAccessoryType } from '@goodwatt/shared/dist/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  FinishPackageLoanMutation,
  FinishPackageLoanMutationVariables,
} from '../../../../__generated__/graphql'
import { StoreState } from '../../../../redux/rootReducer'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import i18n from 'i18next'
import Button from '../../../../components/Button'
import { employeeNameFormatter } from '../../../../utils/formatter'
import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import { resetReturnBikeForm, setReturnBikeForm } from '../../../../redux/forms'
import { useEmployeeDeploymentBikeLoan } from '../hooks/useEmployeeDeploymentBikeLoan'
import ReturnAccessoriesCondition from '../../Company/Employees/SelectedEmployees/ReturnPackageModal/ReturnAccessoriesCondition'

const MUTATION_FINISH_PACKAGE_LOAN = gql`
  mutation FinishPackageLoan(
    $bikeLoanId: ID!
    $employeeId: ID!
    $loanPackages: [LoanPackageInput!]
  ) {
    finishPackageLoan(
      bikeLoanId: $bikeLoanId
      employeeId: $employeeId
      loanPackages: $loanPackages
    )
  }
`

export interface ReturnPackageModalProps {
  isOpen: boolean
  onClose: () => void
  employeeDeploymentId: string
}

export const ReturnPackageModal: React.FC<ReturnPackageModalProps> = ({
  employeeDeploymentId,
  onClose,
  isOpen,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { returnBikeForm } = useSelector((state: StoreState) => state.forms)
  const notifications = useNotifications()

  const { loading: loadingCheckReturn, data } = useEmployeeDeploymentBikeLoan(
    isOpen,
    employeeDeploymentId,
    {
      onCompleted: data => {
        if (data.employeeDeployment?.bikeLoan) {
          const loanAccessories =
            data.employeeDeployment.bikeLoan.loanPackages.reduce<
              StoreState['forms']['returnBikeForm']['accessories']
            >(
              (acc, accessory) => ({
                ...acc,
                [accessory.packageAccessory.name as PackageAccessoryType]: {
                  id: accessory.id,
                  missing: false,
                  stateOfUse: accessory.stateOfUse.name,
                  note: accessory.note,
                  attachments: accessory.bikeLoanAttachments,
                },
              }),
              {},
            )

          dispatch(
            setReturnBikeForm({
              stickerId: data.employeeDeployment.bikeLoan.bike?.stickerId,
              bikeLoanId: data.employeeDeployment.bikeLoan.id,
              accessories: loanAccessories,
            }),
          )
          return true
        }
      },
    },
  )
  const [finishPackageLoan, { loading: loadingFinishBikeLoan }] = useMutation<
    FinishPackageLoanMutation,
    FinishPackageLoanMutationVariables
  >(MUTATION_FINISH_PACKAGE_LOAN, {
    refetchQueries: ['GetDeploymentEmployees'],
  })

  const loading = loadingCheckReturn && loadingFinishBikeLoan

  const onSubmit = async (): Promise<void> => {
    // Handle step 0 onNext
    if (!loading) {
      const loanPackages = Object.entries(returnBikeForm.accessories).map(
        ([partName, value]) => ({
          id: value?.id || '',
          name: partName,
          stateOfUse: value?.stateOfUse || '',
          note: value?.note,
        }),
      )
      await finishPackageLoan({
        variables: {
          bikeLoanId: returnBikeForm.bikeLoanId,
          employeeId: data?.employeeDeployment?.employee.id || '',
          ...(loanPackages?.length ? { loanPackages } : {}),
        },
      })
        .then(() => {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: i18n.t(
              'animator.company.employees.selected.modals.employeeReturnBikeModal.successReturnPackagesNotification',
            ),
          })
          dispatch(resetReturnBikeForm())
        })
        .then(() => {
          onClose()
        })
        .catch(apiErrorCatcher(notifications))
    }
  }

  return (
    <Modal isOpen={isOpen} onBackgroundClick={onClose}>
      <Popup
        closable
        title={t(
          'animator.company.employees.selected.modals.employeeReturnBikeModal.returnPackagesCondition.title',
        )}
        onClose={onClose}
        maxWidth={750}
        footer={
          <React.Fragment>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="tertiary"
                submit
                loading={loading}
                onClick={() => onClose()}
              >
                {t('forms.button.cancel')}
              </Button>
            </div>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="primary"
                submit
                loading={loading}
                onClick={() => onSubmit()}
              >
                {t('forms.button.confirm')}
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <p
          css={{ textAlign: 'center', marginBottom: 20 }}
          dangerouslySetInnerHTML={{
            __html: t(
              'animator.company.employees.selected.modals.employeeReturnBikeModal.returnPackagesCondition.body',
              {
                employeeName: employeeNameFormatter(
                  data?.employeeDeployment?.employee.firstName,
                  data?.employeeDeployment?.employee.lastName,
                ),
              },
            ),
          }}
        />
        <ReturnAccessoriesCondition />
      </Popup>
    </Modal>
  )
}
