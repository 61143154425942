/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'

import Modal from '../../../components/Modal'
import Popup from '../../../components/Popup'
import Button from '../../../components/Button'
import Input from '../../../components/Input'

import {
  NotificationTypes,
  useNotifications,
} from '../../../contexts/NotificationContext'

import i18n from '../../../i18n/config'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import { MUTATION_CONFIRM_USER_EMAIL } from '../../../utils/gql/mutations'
import { useTheme } from 'emotion-theming'
import {
  CompanyReferrer,
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables,
} from '../../../__generated__/graphql'

const schema = yup.object().shape({
  email: yup.string().email().required(i18n.t('forms.errors.required')),
})

interface EditEmployeeInfoForm {
  email: string
}

interface ConfirmEmailModalProps {
  referrer: CompanyReferrer | null | undefined
  closeModal: () => void
  refetch: () => void
  modalIsOpen?: boolean
}

const ConfirmEmailModal: React.FC<ConfirmEmailModalProps> = ({
  referrer,
  closeModal,
  refetch,
  modalIsOpen = true,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const notifications = useNotifications()
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const [confirmEmail, { loading }] = useMutation<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >(MUTATION_CONFIRM_USER_EMAIL, {
    refetchQueries: ['GetCompanyProfile'],
  })

  const onSubmit = (inputData: EditEmployeeInfoForm) => {
    if (!referrer?.user?.id || !inputData.email) {
      return null
    }

    confirmEmail({
      variables: {
        userId: referrer?.user?.id,
        email: inputData.email,
      },
    })
      .then(result => {
        if (result.data?.confirmEmail) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: i18n.t('shared.notification.profileSuccessUpdate'),
          })
          refetch()
          closeModal()
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={modalIsOpen} onBackgroundClick={closeModal}>
      <Popup
        maxWidth={750}
        title={t('company.profile.confirmEmailModal.title')}
        onClose={closeModal}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginLeft: '-40px',
            }}
          >
            <Button type="tertiary" onClick={closeModal}>
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginRight: 40 }} />
            <Button
              loading={loading}
              type="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {t('company.profile.confirmEmailModal.CTA')}
            </Button>
          </div>
        }
      >
        <p
          css={{
            textAlign: 'center',
            whiteSpace: 'pre-line',
            marginBottom: 20,
          }}
        >
          {t('company.profile.confirmEmailModal.body', {
            employeeName: `${referrer?.firstName} ${referrer?.lastName}`,
          })}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            css={{
              width: '100%',
              padding: '0 12px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              [theme.media.mobile]: {
                padding: '0',
              },
            }}
          >
            <Input
              compact
              center
              type="text"
              name="email"
              defaultValue={referrer?.email}
              register={register}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default ConfirmEmailModal
