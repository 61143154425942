/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import Modal from '../../../../../components/Modal'
import Popup from '../../../../../components/Popup'
import Button from '../../../../../components/Button'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../../contexts/NotificationContext'

import i18n from '../../../../../i18n/config'
import apiErrorCatcher from '../../../../../utils/apiErrorCatcher'
import { MUTATION_DELETE_EMPLOYEE } from '../../../../../utils/gql/mutations'

import { employeeNameFormatter } from '../../../../../utils/formatter'
import theme from '../../../../../styles/theme'
import {
  DeleteEmployeeMutation,
  DeleteEmployeeMutationVariables,
  GetEmployeeQuery,
} from '../../../../../__generated__/graphql'

interface ConfirmDeleteModalProps {
  employee: GetEmployeeQuery['employee'] | null
  closeModal: () => void
  refetch: (newData?: Partial<GetEmployeeQuery['employee']>) => void
  modalIsOpen?: boolean
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  employee,
  closeModal,
  refetch,
  modalIsOpen = true,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()

  const [deletEmployee, { loading }] = useMutation<
    DeleteEmployeeMutation,
    DeleteEmployeeMutationVariables
  >(MUTATION_DELETE_EMPLOYEE)

  const onSubmit = () => {
    if (!employee?.id) return

    deletEmployee({
      variables: {
        employeeId: employee?.id,
      },
    })
      .then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: i18n.t('shared.notification.successEmployeeDelete'),
        })
        refetch()
        closeModal()
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={modalIsOpen} onBackgroundClick={closeModal}>
      <Popup
        maxWidth={750}
        separator={false}
        titleColor={theme.colors.redLegend}
        title={t('employee.profile.confirmDeleteModal.title')}
        onClose={closeModal}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Button type="tertiaryError" onClick={closeModal}>
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginRight: 40 }} />
            <Button loading={loading} type="primaryError" onClick={onSubmit}>
              {t('employee.profile.confirmDeleteModal.CTA')}
            </Button>
          </div>
        }
      >
        <p
          css={{
            textAlign: 'center',
            whiteSpace: 'pre-line',
          }}
          dangerouslySetInnerHTML={{
            __html: t('employee.profile.confirmDeleteModal.body', {
              employeeName: employeeNameFormatter(
                employee?.firstName,
                employee?.lastName,
              ),
            }),
          }}
        />
      </Popup>
    </Modal>
  )
}

export default ConfirmDeleteModal
