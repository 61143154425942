/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTheme } from 'emotion-theming'
import { useSelector } from 'react-redux'

import UserMenu from '../UserMenu'
import ContextualButton from '../ContextualButton'
import { StoreState } from '../../redux/rootReducer'
import { useTranslation } from 'react-i18next'
import Icon from '../../components/Icon'

interface DisconnectProps {
  closeMenu: () => void
}

const Disconnect: React.FC<DisconnectProps> = ({ closeMenu }) => {
  const theme = useTheme()
  const user = useSelector((state: StoreState) => state.user)
  const { t } = useTranslation()

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '1rem',
        paddingLeft: '1rem',
        fontSize: '1.6rem',
        lineHeight: '16px',
        fontFamily: theme.fontFamily,
        color: theme.colors.gray2,
        width: '100%',
        marginBottom: '42px',
        textTransform: 'uppercase',
        [theme.media.tablet]: {
          flexDirection: 'row-reverse',
          marginBottom: '0',
          justifyContent: 'flex-end',
          paddingLeft: '18px',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          [theme.media.tablet]: {
            marginLeft: '24px',
          },
        }}
      >
        {user.companyName && (
          <p
            css={{
              fontWeight: 500,
              color: theme.colors.gray1,
              lineHeight: '26px',
            }}
          >
            {user.companyName}
          </p>
        )}
        <span css={{ textTransform: 'initial', lineHeight: '26px' }}>
          {user.role === 'COMPANY' ? (
            <React.Fragment>
              <Icon type="tag" />
              <span css={{ marginLeft: '.5em' }}>{user.subscriptionCode}</span>
            </React.Fragment>
          ) : (
            t(`shared.header.${user.role.toLowerCase()}`)
          )}
        </span>
      </div>
      <ContextualButton user={user} />
      <UserMenu closeMenu={closeMenu} user={user} />
    </div>
  )
}

export default Disconnect
