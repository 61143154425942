/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CompanyMonitoringType } from '@goodwatt/shared/dist/types'
import i18n from '../../../../i18n/config'
import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'
import InputSelect from '../../../../components/InputSelect'
import DateTime from '../../../../components/DateTime'
import TextArea from '../../../../components/TextArea'
import Button from '../../../../components/Button'
import {
  useNotifications,
  NotificationTypes,
} from '../../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import {
  CompanyMonitoringTableModalActions,
  CompanyMonitoringTableAdd,
  CompanyMonitoringTableRow,
  ModalCompanyMonitoringSubmit,
} from '../../../../types/CompanyMonitoring'
import {
  AnimatorCompanyMonitoringGetModalDatasQuery,
  CreateOneCompanyMonitoringMutation,
  CreateOneCompanyMonitoringMutationVariables,
  UpdateOneCompanyMonitoringMutation,
  UpdateOneCompanyMonitoringMutationVariables,
} from '../../../../__generated__/graphql'

const schema = yup.object().shape({
  status: yup.object().shape({
    value: yup.string().required(i18n.t('forms.errors.required')),
  }),
  date: yup.date().required(i18n.t('forms.errors.required')),
  deadline: yup.date().required(i18n.t('forms.errors.required')),
  note: yup.string(),
})

const MUTATION_UPDATE_COMPANY_MONITORING = gql`
  mutation UpdateOneCompanyMonitoring(
    $data: CompanyMonitoringUpdateInput!
    $where: CompanyMonitoringWhereUniqueInput!
  ) {
    updateOneCompanyMonitoring(data: $data, where: $where) {
      id
      date
      deadline
      note
      type
      companyMonitoringStatus {
        id
        name
      }
    }
  }
`
const MUTATION_CREATE_COMPANY_MONITORING = gql`
  mutation CreateOneCompanyMonitoring($data: CompanyMonitoringCreateInput!) {
    createOneCompanyMonitoring(data: $data) {
      id
      date
      deadline
      note
      type
      companyMonitoringStatus {
        id
        name
      }
    }
  }
`

const QUERY_ANIMATOR_COMPANY_MONITORING_MODAL_DATAS = gql`
  query AnimatorCompanyMonitoringGetModalDatas {
    companyMonitoringStatuses {
      id
      name
    }
  }
`
interface AddOrEditModalProps {
  isOpen: boolean
  companyMonitoring?: CompanyMonitoringTableRow
  dataList?: AnimatorCompanyMonitoringGetModalDatasQuery
  companyId?: string
  deploymentId?: string
  type: '' | CompanyMonitoringTableAdd | CompanyMonitoringTableModalActions
  onClose: () => void
  updateList?: () => void
}

const AddOrEditCompanyMonitoringModal: React.FC<AddOrEditModalProps> = ({
  isOpen,
  onClose,
  companyMonitoring,
  type,
  companyId,
  deploymentId,
  updateList,
}) => {
  const { register, handleSubmit, errors, control, reset } =
    useForm<CompanyMonitoringTableRow>({
      resolver: yupResolver(schema),
    })
  React.useEffect(() => reset(), [companyMonitoring, reset, type])
  const { t } = useTranslation()
  const [updateOneCompanyMonitoring, { loading }] = useMutation<
    UpdateOneCompanyMonitoringMutation,
    UpdateOneCompanyMonitoringMutationVariables
  >(MUTATION_UPDATE_COMPANY_MONITORING, {
    refetchQueries: ['GetDeployment'],
  })
  const [createOneCompanyMonitoring, { loading: createLoading }] = useMutation<
    CreateOneCompanyMonitoringMutation,
    CreateOneCompanyMonitoringMutationVariables
  >(MUTATION_CREATE_COMPANY_MONITORING, {
    refetchQueries: ['GetDeployment'],
  })
  const { data: companyMonitoringsModalData } =
    useQuery<AnimatorCompanyMonitoringGetModalDatasQuery>(
      QUERY_ANIMATOR_COMPANY_MONITORING_MODAL_DATAS,
    )
  const notifications = useNotifications()
  const onSubmit = (data: ModalCompanyMonitoringSubmit) => {
    const handleSuccess = () => {
      if (type === CompanyMonitoringTableAdd.ADD) updateList?.()
      onClose()
      notifications.newNotification({
        type: NotificationTypes.SUCCESS,
        message: t(
          `animator.company.monitoring.modal.${type}.successNotification`,
        ),
      })
    }

    if (type === CompanyMonitoringTableAdd.ADD)
      return createOneCompanyMonitoring({
        variables: {
          data: {
            company: { connect: { id: companyId } },
            deployment: deploymentId
              ? { connect: { id: deploymentId } }
              : undefined,
            date: data.date,
            deadline: data.deadline,
            note: data.note,
            type: !!CompanyMonitoringType.MANUAL,
            companyMonitoringStatus: { connect: { id: data.status.value } },
          },
        },
      })
        .then(handleSuccess)
        .catch(apiErrorCatcher(notifications))

    updateOneCompanyMonitoring({
      variables: {
        where: { id: companyMonitoring!.id },
        data: {
          date: { set: data.date },
          deadline: { set: data.deadline },
          note: { set: data.note },
          companyMonitoringStatus: { connect: { id: data.status.value } },
        },
      },
    })
      .then(handleSuccess)
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={isOpen} onBackgroundClick={onClose}>
      <Popup
        closable
        title={t(`animator.company.monitoring.modal.${type}.title`)}
        onClose={onClose}
        maxWidth={750}
        footer={
          <React.Fragment>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="tertiary"
                submit
                loading={loading || createLoading}
                onClick={onClose}
              >
                {t('forms.button.cancel')}
              </Button>
            </div>
            <div css={{ padding: '0 7px' }}>
              <Button
                type="primary"
                submit
                loading={loading || createLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {t('forms.button.confirm')}
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <div css={{ padding: '30px 54px 38px' }}>
          <form>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '2rem 0',
              }}
            >
              <div css={{ width: 'calc(50% - 35px)' }}>
                <Controller
                  name="date"
                  control={control}
                  defaultValue={
                    companyMonitoring?.date
                      ? new Date(companyMonitoring.date)
                      : null
                  }
                  render={({ onChange, value }) => {
                    return (
                      <DateTime
                        label={t('forms.label.entryDate')}
                        onChange={onChange}
                        value={value}
                        error={!!errors.date}
                        helperText={errors?.date?.message}
                      />
                    )
                  }}
                />
              </div>
              <div css={{ width: 'calc(50% - 35px)' }}>
                <Controller
                  name="deadline"
                  control={control}
                  defaultValue={
                    companyMonitoring?.deadline
                      ? new Date(companyMonitoring.deadline)
                      : null
                  }
                  render={({ onChange, value }) => {
                    return (
                      <DateTime
                        label={t('forms.label.dueDate')}
                        onChange={onChange}
                        value={value}
                        error={!!errors.deadline}
                        helperText={errors?.deadline?.message}
                      />
                    )
                  }}
                />
              </div>
            </div>
            <Controller
              name="status"
              control={control}
              defaultValue={
                type !== CompanyMonitoringTableAdd.ADD &&
                companyMonitoring?.statusName
                  ? {
                      label: t(
                        `animator.company.monitoring.filter.companyMonitoringStatus.options.${companyMonitoring?.statusName}`,
                      ),
                      value: companyMonitoring.statusId,
                    }
                  : null
              }
              render={({ onChange, onBlur, value, name }) => (
                <InputSelect
                  label={t('forms.label.noteStatus')}
                  name={name}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  options={
                    companyMonitoringsModalData?.companyMonitoringStatuses.map(
                      ({ id, name }: any) => ({
                        label: t(
                          `animator.company.monitoring.filter.companyMonitoringStatus.options.${name}`,
                        ),
                        value: id,
                      }),
                    ) || []
                  }
                  error={!!errors.statusName}
                  helperText={errors?.statusName?.message}
                />
              )}
            />
            <div css={{ marginBottom: '2rem' }}>
              <Controller
                name="note"
                control={control}
                defaultValue={companyMonitoring?.note}
                render={({ onChange, onBlur, value, name }) => (
                  <TextArea
                    block
                    name={name}
                    value={value}
                    label={t('forms.label.note')}
                    onBlur={onBlur}
                    onChange={onChange}
                    rows={5}
                    withError={false}
                    register={register}
                    placeholder={t('forms.label.leaveANote')}
                    error={!!errors.note}
                    helperText={errors?.note?.message}
                  />
                )}
              />
            </div>
          </form>
        </div>
      </Popup>
    </Modal>
  )
}

export default AddOrEditCompanyMonitoringModal
