/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../redux/rootReducer'
import { useQuery } from '@apollo/client'
import { QUERY_GET_EMPLOYEE_DASHBOARD } from '../../../../utils/gql/queries'
import { getDepositStatus, DocStatus, getStatus } from '../../Documents'
import {
  DeploymentType,
  GetEmployeeDashboardQuery,
  GetEmployeeDashboardQueryVariables,
} from '../../../../__generated__/graphql'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ProgramStepType } from '@goodwatt/shared/dist/types'
import Loader from '../../../../components/Loader'

export const DocumentsAndCaution: React.FC = () => {
  const userId = useSelector((state: StoreState) => state.user.id)
  const { t } = useTranslation()

  const { data, loading } = useQuery<
    GetEmployeeDashboardQuery,
    GetEmployeeDashboardQueryVariables
  >(QUERY_GET_EMPLOYEE_DASHBOARD, {
    fetchPolicy: 'network-only',
    variables: {
      where: { userId: userId },
    },
  })

  const documentStatus = useMemo((): DocStatus => {
    if (
      data?.employee?.identityCardVerified &&
      data?.employee?.proofOfAddressVerified
    )
      return DocStatus.VERIFIED
    if (
      getStatus(
        data?.employee?.identityCardFrontFilePath,
        data?.employee?.identityCardVerified,
      ) === DocStatus.VALIDATING ||
      getStatus(
        data?.employee?.proofOfAddressFilePath,
        data?.employee?.proofOfAddressVerified,
      ) === DocStatus.VALIDATING
    )
      return DocStatus.VALIDATING
    return DocStatus.NEED_UPLOAD
  }, [data])

  const cautionStatus = useMemo((): DocStatus => {
    if (data?.employee?.depositByCheque === true) {
      return DocStatus.VERIFIED
    }

    if (
      data?.employee?.depositId &&
      data?.employee?.depositExpirationDate &&
      new Date() < new Date(data?.employee?.depositExpirationDate)
    )
      return DocStatus.VERIFIED
    return getDepositStatus(
      data?.employee?.depositId,
      data?.employee?.depositExpirationDate,
    )
  }, [data])

  const employeeDeployments = data?.employee?.employeeDeployments?.filter(
    ed => ed?.status === 'SELECTED' || ed?.status === 'BENEFICIARY',
  )

  const employeeDeploymentBeneficiary = employeeDeployments?.find(
    ed => ed?.status === 'BENEFICIARY',
  )

  const employeeDeploymentSelected = employeeDeployments?.find(
    ed => ed?.status === 'SELECTED',
  )

  const employeeDeployment =
    employeeDeploymentBeneficiary || employeeDeploymentSelected

  const employeeDeploymentType =
    employeeDeployment?.deployment?.deploymentType || DeploymentType.None
  const employeeDeploymentStatus = employeeDeployment?.status || ''
  const employeeDeploymentProgramStep =
    employeeDeployment?.deployment?.programStep?.stepNbr || 0

  const showDepositRemember =
    employeeDeploymentType === DeploymentType.Discovery &&
    //!data?.employee?.bikeLoan &&
    (employeeDeploymentStatus === 'SELECTED' ||
      employeeDeploymentStatus === 'BENEFICIARY') &&
    cautionStatus !== DocStatus.VERIFIED &&
    [
      ProgramStepType.PREPARING_LOAN,
      ProgramStepType.LOAN_BIKES_IN_PROGRESS,
    ].includes(employeeDeploymentProgramStep)

  if (loading && !data) return <Loader />

  return documentStatus === DocStatus.NEED_UPLOAD ? (
    <div
      css={{
        backgroundColor: '#FF0015',
        borderRadius: '12px',
        padding: '10px',
        textAlign: 'center',
        maxWidth: '80%',
        marginBottom: '20px',
      }}
    >
      {t('employee.warning.missingDocuments')}
      <Link
        to="/employee/documents"
        css={{
          color: 'black',
          fontWeight: 'bold',
        }}
      >
        {t('employee.warning.missingDocumentsLink')}
      </Link>
    </div>
  ) : showDepositRemember ? (
    <div
      css={{
        backgroundColor: '#FF0015',
        borderRadius: '12px',
        padding: '10px',
        textAlign: 'center',
        maxWidth: '80%',
        marginBottom: '20px',
      }}
    >
      {t('employee.warning.missingCaution')}
      <Link
        to="/employee/documents"
        css={{
          color: 'black',
          fontWeight: 'bold',
        }}
      >
        {t('employee.warning.missingCautionLink')}
      </Link>
    </div>
  ) : (
    <div></div>
  )
}
