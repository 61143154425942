/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex } from '../../../components/Flex'
import React, { Fragment, useMemo } from 'react'
import { useFilters } from '../../../hooks/useFilters'
import { useLocalOrderBy, useOrderBy } from '../../../hooks/useOrderBy'
import { useTranslation } from 'react-i18next'
import CardFoldable from '../../../components/Card/Foldable'
import InputSelect from '../../../components/InputSelect'
import { SelectVariant } from '../../../hooks/useVariant/variants/select'
import Input from '../../../components/Input'
import { TextVariant } from '../../../hooks/useVariant/variants/text'
import { CellProps, Column } from 'react-table'
import Table from '../../../components/Table'
import { DeploymentPageLayout } from './DeploymentPageLayout'
import { useCurrentDeploymentId } from './hooks/useCurrentDeploymentId'
import { useQuery } from '@apollo/client'
import { QUERY_ANIMATOR_DEPLOYMENT } from '../../../utils/gql/queries'
import { format } from 'date-fns'
import { DeleteCompanyMonitoringButton } from './components/DeleteCompanyMonitoringButton'
import { EditCompanyMonitoringButton } from './components/EditCompanyMonitoringButton'
import { CreateCompanyMonitoringButton } from './components/CreateCompanyMonitoringButton'
import {
  GetDeploymentQuery,
  GetDeploymentQueryVariables,
} from '../../../__generated__/graphql'
import { CompanyMonitoringTableRow } from '../../../types/CompanyMonitoring'

interface Filters {
  type: string
  status: string
  search: string
}

export const Monitoring: React.FC = () => {
  const { orderBy, getTableProps } = useOrderBy()
  const { filters, register } = useFilters<Filters>()
  const { t } = useTranslation()
  const id = useCurrentDeploymentId()
  const { data } = useQuery<GetDeploymentQuery, GetDeploymentQueryVariables>(
    QUERY_ANIMATOR_DEPLOYMENT,
    {
      variables: { id },
    },
  )
  const { filtered, statuses } = useMemo(() => {
    if (data?.deployment == null) return { statuses: [] as string[] }
    let filtered = data.deployment.companyMonitoring
    if (filters.type) {
      filtered = filtered.filter(v => v.type.toString() === filters.type)
    }
    if (filters.status) {
      filtered = filtered.filter(
        v => v.companyMonitoringStatus.name === filters.status,
      )
    }
    if (filters.search) {
      const lower = filters.search.toLowerCase()
      filtered = filtered.filter(
        v => v.note != null && v.note.toLowerCase().includes(lower),
      )
    }
    const statuses = data.deployment.companyMonitoring
      .map(v => v.companyMonitoringStatus.name)
      .filter((v, i, arr) => arr.indexOf(v) === i)
    return { filtered, statuses }
  }, [data, filters])

  const sorted = useLocalOrderBy(orderBy, filtered)

  return (
    <DeploymentPageLayout>
      <CardFoldable
        title={
          <h3 css={{ color: '#4F4F4F', fontSize: '18px' }}>
            {t(`animator.deployments.monitoring.title`)}
          </h3>
        }
      >
        <Flex
          direction="row"
          gap="16px"
          align="end"
          css={{ marginTop: '16px', marginBottom: '16px' }}
        >
          <div css={{ flex: 1 }}>
            <InputSelect
              variant={SelectVariant.white}
              label={t(`animator.deployments.monitoring.table.type`)}
              withError={false}
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t('animator.deployments.filter.options.all'),
                },
                {
                  value: 'false',
                  label: t('animator.deployments.monitoring.type.auto'),
                },
                {
                  value: 'true',
                  label: t('animator.deployments.monitoring.type.manual'),
                },
              ]}
              {...register('type')}
            />
          </div>
          <div css={{ flex: 1 }}>
            <InputSelect
              variant={SelectVariant.white}
              label={t(`animator.deployments.monitoring.table.status`)}
              withError={false}
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t('animator.deployments.filter.options.all'),
                },
                ...statuses.map(value => ({
                  value,
                  label: t(`animator.deployments.monitoring.status.${value}`),
                })),
              ]}
              {...register('status')}
            />
          </div>
          <div css={{ flex: 2 }}>
            <Input
              icon="magnifier"
              variant={TextVariant.white}
              withError={false}
              {...register('search')}
            />
          </div>
        </Flex>
        <Flex direction="column" gap="16px">
          <Table
            {...getTableProps()}
            columns={[
              {
                Header: t(
                  `animator.deployments.monitoring.table.date`,
                ) as string,
                Cell: ({ value }) =>
                  value ? (
                    format(new Date(value), 'dd/MM/yy')
                  ) : (
                    <span>Aucune</span>
                  ),
                accessor: 'date',
              },
              {
                Header: t(
                  `animator.deployments.monitoring.table.type`,
                ) as string,
                Cell: ({ value }) =>
                  t(
                    `animator.deployments.monitoring.type.${
                      value ? 'manual' : 'auto'
                    }`,
                  ),
                accessor: 'type',
              },
              {
                Header: t(
                  `animator.deployments.monitoring.table.note`,
                ) as string,
                accessor: 'note',
              },
              {
                Header: t(
                  `animator.deployments.monitoring.table.deadline`,
                ) as string,
                Cell: ({ value }) => format(new Date(value), 'dd/MM/yy'),
                accessor: 'deadline',
              },
              {
                Header: t(
                  `animator.deployments.monitoring.table.status`,
                ) as string,
                Cell: ({ value }) =>
                  t(`animator.deployments.monitoring.status.${value}`),
                accessor: 'companyMonitoringStatus.name',
              },
              {
                Header: t(
                  `animator.deployments.monitoring.table.actions`,
                ) as string,
                Cell: ({
                  row: { original },
                }: CellProps<CompanyMonitoringTableRow>) => (
                  <Flex direction="row" justify="center" gap="8px">
                    {original.type && (
                      <Fragment>
                        <EditCompanyMonitoringButton
                          companyMonitoring={original}
                        />
                        <DeleteCompanyMonitoringButton id={original.id} />
                      </Fragment>
                    )}
                  </Flex>
                ),
                id: 'actions',
                disableSortBy: true,
              } as Column,
            ]}
            data={sorted ?? []}
            loading={sorted == null}
          />
          <div>
            {data?.deployment && (
              <CreateCompanyMonitoringButton
                companyId={data.deployment.site.company.id}
                deploymentId={data.deployment.id}
              >
                Ajouter
              </CreateCompanyMonitoringButton>
            )}
          </div>
        </Flex>
      </CardFoldable>
    </DeploymentPageLayout>
  )
}

export default Monitoring
