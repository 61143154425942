/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'

import i18n from '../../../i18n/config'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import {
  NotificationTypes,
  useNotifications,
} from '../../../contexts/NotificationContext'
import Modal from '../../../components/Modal'
import Popup from '../../../components/Popup'

import { MUTATION_UPDATE_ONE_COMPANY } from '../../../utils/gql/mutations'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import {
  GetCompanyProfileQuery,
  UpdateOneCompanyMutation,
  UpdateOneCompanyMutationVariables,
} from '../../../__generated__/graphql'

const schema = yup.object().shape({
  bikeInside: yup.string().required(i18n.t('forms.errors.required')),
  bikeOutside: yup.string().required(i18n.t('forms.errors.required')),
  bikeUnsafe: yup.string().required(i18n.t('forms.errors.required')),
  carPlaces: yup.string().required(i18n.t('forms.errors.required')),
  carElectricStations: yup.string().required(i18n.t('forms.errors.required')),
})

type EditCompanyParkingInputs = {
  bikeInside: string
  bikeOutside: string
  bikeUnsafe: string
  carPlaces: string
  carElectricStations: string
}

export enum AnchorEditCompanyParkingType {
  COMPANPY_PARKING_BIKE = 'companyParkingBike',
  COMPANPY_PARKING_CAR = 'companyParkingCar',
}

const scrollIntoViewSettings: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'start',
}

interface EditCompanyModalProps {
  modalIsOpen: boolean
  closeModal: () => void
  data: GetCompanyProfileQuery
  companyId: string
  refetch: () => void
  anchorOnOpen?: AnchorEditCompanyParkingType
}

const EditCompanyParkingModal: React.FC<EditCompanyModalProps> = ({
  data,
  ...props
}) => {
  /* ANCHORS FOR EDIT MODAL */
  const companyParkingBikeRef = React.useRef<HTMLDivElement>(null)
  const companyParkingCarRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (props.modalIsOpen) {
      if (
        props.anchorOnOpen ===
        AnchorEditCompanyParkingType.COMPANPY_PARKING_BIKE
      ) {
        companyParkingBikeRef?.current?.scrollIntoView(scrollIntoViewSettings)
      } else if (
        props.anchorOnOpen === AnchorEditCompanyParkingType.COMPANPY_PARKING_CAR
      ) {
        companyParkingCarRef?.current?.scrollIntoView(scrollIntoViewSettings)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalIsOpen])

  const { register, handleSubmit, errors } = useForm<EditCompanyParkingInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const notifications = useNotifications()
  const { t } = useTranslation()
  const [updateCompany, { loading }] = useMutation<
    UpdateOneCompanyMutation,
    UpdateOneCompanyMutationVariables
  >(MUTATION_UPDATE_ONE_COMPANY)

  const onSubmit = (dataForm: EditCompanyParkingInputs) => {
    updateCompany({
      variables: {
        where: { id: props.companyId },
        data: {
          bikeSpaceCapacityInside: { set: parseInt(dataForm.bikeInside) },
          bikeSpaceCapacityOutside: { set: parseInt(dataForm.bikeOutside) },
          bikeSpaceCapacityUnsafe: { set: parseInt(dataForm.bikeUnsafe) },
          carSpaceCapacity: { set: parseInt(dataForm.carPlaces) },
          electricStationCapacity: {
            set: parseInt(dataForm.carElectricStations),
          },
        },
      },
    })
      .then(r => {
        if (r.data?.updateOneCompany?.id) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.notification.profileSuccessUpdate'),
          })
          props.refetch()
          props.closeModal()
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={props.modalIsOpen} onBackgroundClick={props.closeModal}>
      <Popup
        maxWidth={750}
        title={t('company.profile.editParkingTitle')}
        onClose={props.closeModal}
        footer={
          <Fragment>
            <Button type="tertiary" onClick={props.closeModal}>
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginRight: 40 }} />
            <Button
              loading={loading}
              type="primary"
              submit
              onClick={handleSubmit(onSubmit)}
            >
              {t('forms.button.save')}
            </Button>
          </Fragment>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div ref={companyParkingBikeRef} />
          <p
            css={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '1.8rem',
            }}
          >
            {t('auth.signupCompany.bikeFormDesc')}
          </p>
          <Input
            type="text"
            name="bikeInside"
            label={t('auth.signupCompany.bikeInside')}
            placeholder="ex: 0"
            defaultValue={data.company?.bikeSpaceCapacityInside}
            error={!!errors.bikeInside}
            helperText={errors.bikeInside?.message}
            onlyNumbers
            register={register}
          />
          <Input
            type="text"
            name="bikeOutside"
            label={t('auth.signupCompany.bikeOutside')}
            placeholder="ex: 36"
            defaultValue={data.company?.bikeSpaceCapacityOutside}
            error={!!errors.bikeOutside}
            helperText={errors.bikeOutside?.message}
            onlyNumbers
            register={register}
          />
          <Input
            type="text"
            name="bikeUnsafe"
            label={t('auth.signupCompany.bikeUnsafe')}
            placeholder="ex: 15"
            defaultValue={data.company?.bikeSpaceCapacityUnsafe}
            error={!!errors.bikeUnsafe}
            helperText={errors.bikeUnsafe?.message}
            onlyNumbers
            register={register}
          />
          <div ref={companyParkingCarRef} />
          <p
            css={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '1.8rem',
            }}
          >
            {t('auth.signupCompany.carFormDesc')}
          </p>
          <Input
            type="text"
            name="carPlaces"
            label={t('auth.signupCompany.carPlaces')}
            placeholder="ex: 150"
            defaultValue={data.company?.carSpaceCapacity}
            error={!!errors.carPlaces}
            helperText={errors.carPlaces?.message}
            onlyNumbers
            register={register}
          />
          <Input
            type="text"
            name="carElectricStations"
            label={t('auth.signupCompany.carElectricStations')}
            placeholder="ex: 5"
            defaultValue={data.company?.electricStationCapacity}
            error={!!errors.carElectricStations}
            helperText={errors.carElectricStations?.message}
            onlyNumbers
            register={register}
          />
        </form>
      </Popup>
    </Modal>
  )
}

export default EditCompanyParkingModal
