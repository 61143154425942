/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useTheme } from 'emotion-theming'
import { useNotifications } from '../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import Modal from '../../../components/Modal'
import Popup from '../../../components/Popup'
import Button from '../../../components/Button'
import {
  GetCompanyReferrerQueryVariables,
  GetCompanyProfileQuery,
  DeleteCompanySiteMutation,
  DeleteCompanySiteMutationVariables,
} from '../../../__generated__/graphql'
import { MUTATION_DELETE_COMPANY_SITE } from '../../../utils/gql/mutations'
import { DeepNestedProperty, DeepestNestedProperty } from '../../../utils/types'
import InputSelect from '../../../components/InputSelect'
import { Flex } from '../../../components/Flex'

interface DeleteSiteModalProps {
  sites: DeepNestedProperty<GetCompanyProfileQuery, 'company', 'sites'> | null
  site: DeepestNestedProperty<
    GetCompanyProfileQuery,
    'company',
    'sites',
    0
  > | null
  refetch: (newData?: Partial<GetCompanyReferrerQueryVariables>) => void
  onModalClose: () => void
  submitCloseModal: () => void
  isOpen?: boolean
}

const DeleteSiteModal: React.FC<DeleteSiteModalProps> = ({
  site,
  sites,
  refetch,
  onModalClose,
  submitCloseModal,
  isOpen = true,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const notifications = useNotifications()

  const [replacementSite, setReplacementSite] = React.useState<string | null>(
    null,
  )

  const [deleteCompanySite] = useMutation<
    DeleteCompanySiteMutation,
    DeleteCompanySiteMutationVariables
  >(MUTATION_DELETE_COMPANY_SITE, {
    refetchQueries: ['GetCompanyAllSites'],
  })

  const handleSubmit = async () => {
    try {
      await deleteCompanySite({
        variables: {
          deleteSiteId: site!.id,
          replacementSiteId: replacementSite!,
        },
      })

      refetch()
      submitCloseModal()
    } catch (error) {
      apiErrorCatcher(notifications)
    }
  }

  const sitesWithoutCurrentSite = sites!.filter(s => s?.id !== site?.id)
  const canDelete =
    sitesWithoutCurrentSite!.length > 0 && replacementSite !== null

  const onInputChange = (row: any) => {
    setReplacementSite(row.value)
  }

  return (
    <Modal isOpen={isOpen} onBackgroundClick={onModalClose}>
      <Popup
        maxWidth={750}
        title={t('animator.companies.profile.deleteSite')}
        onClose={onModalClose}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <div css={{ marginRight: 40 }} />

            <Button type="primary" onClick={onModalClose}>
              {t('forms.button.cancel')}
            </Button>

            {canDelete && (
              <Button
                // loading={loading}
                type="primaryError"
                onClick={handleSubmit}
              >
                {t('forms.button.confirm')}
              </Button>
            )}
          </div>
        }
      >
        {sitesWithoutCurrentSite!.length === 0 ? (
          <div>
            {t('animator.companies.profile.deleteSiteNoReplacementSites')}
          </div>
        ) : (
          <Flex direction="column" gap="8px">
            <label css={{ color: theme.colors.gray3 }}>
              {t('animator.companies.profile.deleteSiteSelectNewSite')}
            </label>

            <InputSelect
              onChange={onInputChange}
              options={sitesWithoutCurrentSite!.map(s => ({
                label: s.name,
                value: s.id,
              }))}
            />
          </Flex>
        )}
      </Popup>
    </Modal>
  )
}

export default DeleteSiteModal
