/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/Button'
import Modal from '../../../../../../components/Modal'
import Popup from '../../../../../../components/Popup'

interface ReleaseDepositConfirmationModalProps {
  closeModal: () => void
  onSubmit: () => Promise<void>
  onCancel: () => void
  name: string
}

const ReleaseDepositConfirmationModal: React.FC<
  ReleaseDepositConfirmationModalProps
> = ({ closeModal, onSubmit, onCancel, name }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const submit = () => {
    setLoading(true)
    onSubmit().finally(() => setLoading(false))
  }

  return (
    <Modal isOpen onBackgroundClick={closeModal}>
      <Popup
        maxWidth={750}
        title={t(
          'animator.company.employees.selected.modals.deposit.releaseDeposit.title',
        )}
        titleColor={theme.colors.success}
        closable={false}
        footer={
          <Fragment>
            <Button
              type="tertiary"
              onClick={onCancel}
              textColor={theme.colors.success}
            >
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginLeft: '14px' }}>
              <Button
                submit
                type="primarySuccess"
                loading={loading}
                onClick={submit}
              >
                {t('forms.button.confirm')}
              </Button>
            </div>
          </Fragment>
        }
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            css={{ textAlign: 'center' }}
            dangerouslySetInnerHTML={{
              __html: t(
                'animator.company.employees.selected.modals.deposit.releaseDeposit.body',
                { name },
              ),
            }}
          />
        </div>
      </Popup>
    </Modal>
  )
}

export default ReleaseDepositConfirmationModal
