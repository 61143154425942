/** @jsx jsx */
import React, { Fragment, useState } from 'react'
import { useTheme } from 'emotion-theming'
import { jsx } from '@emotion/core'
import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'
import { useTranslation } from 'react-i18next'
import Input from '../../../../components/Input'
import { useMutation } from '@apollo/client'
import {
  MUTATION_VERIFY_PASSWORD,
  MUTATION_ADD_ABSENCE_EMPLOYEE,
} from '../../../../utils/gql/mutations'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import {
  Appointment,
  AppointmentCategory,
  DeploymentType,
  VerifyPasswordMutation,
  VerifyPasswordMutationVariables,
  AddAbsenceEmployeeMutation,
  AddAbsenceEmployeeMutationVariables,
} from '../../../../__generated__/graphql'

interface InitialModalProps {
  isOpen: boolean
  onClose: (isConfirmed: boolean) => void
  employeeId: string
  contractUrl: string
  appointment: Appointment
}

const ModalAbsent: React.FC<InitialModalProps> = ({
  isOpen,
  onClose,
  appointment,
  employeeId,
  contractUrl,
}: InitialModalProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const notifications = useNotifications()

  const [addAbsenceEmployee] = useMutation<
    AddAbsenceEmployeeMutation,
    AddAbsenceEmployeeMutationVariables
  >(MUTATION_ADD_ABSENCE_EMPLOYEE, { refetchQueries: ['GetEmployeeDashboard'] })

  const [verifyPassword, { loading }] = useMutation<
    VerifyPasswordMutation,
    VerifyPasswordMutationVariables
  >(MUTATION_VERIFY_PASSWORD)

  const category = appointment.category
  const deploymentType = appointment.deployment?.deploymentType

  const i18nBasePath = `employee.dashboard.modal.${category}.`

  const handleSubmit = () => {
    if (category !== AppointmentCategory.LoanDelivery) {
      return addAbsence()
    }

    verifyPassword({
      variables: {
        password: password,
      },
    }).then(r => {
      if (r.data?.verifyPassword) addAbsence()
      else {
        notifications.newNotification({
          type: NotificationTypes.ERROR,
          message: t('employee.dashboard.modal.errorPassword'),
        })
      }
    })
  }

  const addAbsence = async () => {
    if (!appointment || !employeeId) return

    await addAbsenceEmployee({
      variables: {
        employeeId: employeeId,
        appointmentId: appointment.id,
      },
    })

    onClose(true)
  }

  return (
    <Modal isOpen={isOpen} onBackgroundClick={() => onClose(false)}>
      <Popup
        overflowY="auto"
        closable
        title={t(i18nBasePath + 'title')}
        onClose={() => onClose(false)}
        maxWidth={600}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button onClick={() => onClose(false)} type="tertiary">
                {t('shared.button.cancel')}
              </Button>
            </div>
            <Button
              loading={loading}
              type="primary"
              submit
              onClick={() => handleSubmit()}
            >
              {t('forms.button.confirm')}
            </Button>
          </Fragment>
        }
      >
        <div css={{ padding: '0 20px', color: theme.colors.gray1 }}>
          <p css={{ textAlign: 'center' }}>{t(i18nBasePath + 'subTitle')}</p>
          <p css={{ marginTop: '15px', textAlign: 'center' }}>
            {t(i18nBasePath + 'contentLine1')}
          </p>
          {[
            AppointmentCategory.LoanDelivery,
            AppointmentCategory.LoanReturn,
          ].includes(category) &&
            deploymentType === DeploymentType.Discovery && (
              <p css={{ marginTop: '15px', textAlign: 'center' }}>
                {t(i18nBasePath + 'contentLine2')}
                {contractUrl ? (
                  <a
                    css={{
                      textDecoration: 'underline',
                      color: theme.colors.gray1,
                    }}
                    href={contractUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {t(i18nBasePath + 'locationContact')}
                  </a>
                ) : (
                  <div>{t(i18nBasePath + 'locationContact')}</div>
                )}
                .
              </p>
            )}
          {category === AppointmentCategory.LoanDelivery && (
            <p css={{ marginTop: '15px', textAlign: 'center' }}>
              {t(i18nBasePath + 'contentLine3')}
              <div css={{ marginTop: '25px', textAlign: 'center' }}>
                {t('employee.dashboard.modal.putPassword')}
                <div css={{ marginTop: '10px' }}>
                  <Input
                    placeholder={t('forms.label.password')}
                    noPasswordPreview
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </p>
          )}
        </div>
      </Popup>
    </Modal>
  )
}

export default ModalAbsent
