/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import InputSelect from '../../../../components/InputSelect'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import i18n from '../../../../i18n/config'
import Button from '../../../../components/Button'

import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import Popup from '../../../../components/Popup'
import {
  CompanyType,
  UpdateOneCompanyMutation,
  UpdateOneCompanyMutationVariables,
} from '../../../../__generated__/graphql'
import { MUTATION_UPDATE_ONE_COMPANY } from '../../../../utils/gql/mutations'
import { QUERY_GET_COMPANY } from '../../../../utils/gql/queries'
import { useCompany } from '../hooks/useCompany'

const schema = yup.object().shape({
  name: yup.string().required(i18n.t('forms.errors.required')),
  companyType: yup.object().required(),
})

interface EditCompanyProfileForm {
  name: string
  companyType: {
    value: CompanyType
    label: string
  }
}

interface EditCompanyModalProps {
  isOpen: boolean
  onClose: () => void
  companyId: string
}

const EditCompanyModal: React.FC<EditCompanyModalProps> = ({
  isOpen,
  onClose,
  companyId,
}) => {
  const notifications = useNotifications()
  const { t } = useTranslation()
  const { register, handleSubmit, errors, control, setValue } =
    useForm<EditCompanyProfileForm>({
      resolver: yupResolver(schema),
      mode: 'onBlur',
    })

  const { data } = useCompany(companyId, isOpen)

  const [updateOneCompany, { loading }] = useMutation<
    UpdateOneCompanyMutation,
    UpdateOneCompanyMutationVariables
  >(MUTATION_UPDATE_ONE_COMPANY, {
    refetchQueries: [
      {
        query: QUERY_GET_COMPANY,
        variables: {
          where: {
            id: companyId,
          },
        },
      },
      'companiesWithDeployments',
    ],
  })

  const defaultCompanyType = React.useMemo(() => {
    return data?.company?.companyType !== null
      ? {
          value: data?.company?.companyType,
          label: t(`shared.companyTypeLabel.${data?.company?.companyType}`),
        }
      : undefined
  }, [data, t])

  React.useEffect(() => {
    if (data?.company) {
      setValue('name', data?.company?.name)
      if (defaultCompanyType) setValue('companyType', defaultCompanyType)
    }
  }, [data, setValue, isOpen, defaultCompanyType])

  const onSubmit = (data: EditCompanyProfileForm) => {
    if (loading) return

    updateOneCompany({
      variables: {
        where: { id: companyId },
        data: {
          name: { set: data.name },
          companyType: { set: data.companyType.value },
        },
      },
    })
      .then(result => {
        if (result.data?.updateOneCompany?.id) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('shared.notification.profileSuccessUpdate'),
          })
        }
        onClose()
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={isOpen} onBackgroundClick={onClose}>
      <Popup
        maxWidth={750}
        title={t('company.profile.editModalTitle')}
        onClose={onClose}
        footer={
          <Fragment>
            <Button type="tertiary" onClick={onClose}>
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginRight: 40 }} />
            <Button
              loading={loading}
              type="primary"
              onClick={handleSubmit(onSubmit)}
              submit
            >
              {t('forms.button.save')}
            </Button>
          </Fragment>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            css={{
              width: '100%',
              padding: '0 24px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Input
              type="text"
              name="name"
              defaultValue={data?.company?.name}
              label={t('forms.label.companyName')}
              register={register}
              error={!!errors.name}
              helperText={errors.name?.message}
            />

            <div css={{ marginBottom: '24px' }} />

            <Controller
              name="companyType"
              control={control}
              defaultValue={defaultCompanyType}
              render={({ onChange, onBlur, value, name }) => (
                <InputSelect
                  name={name}
                  label={t('forms.label.companyType')}
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  options={
                    [
                      CompanyType.Company,
                      CompanyType.Association,
                      CompanyType.Colectivity,
                      CompanyType.Healthcare,
                      CompanyType.PublicAdministration,
                      CompanyType.ClubCompany,
                      CompanyType.Other,
                    ].map((value: string) => ({
                      value,
                      label: t(`shared.companyTypeLabel.${value}`),
                    })) as any
                  }
                  error={!!errors.companyType}
                  helperText={
                    errors.companyType ? t('forms.errors.chooseAnOption') : ''
                  }
                />
              )}
            />
            <div css={{ marginBottom: '24px' }} />
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default EditCompanyModal
