/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useMutation, useQuery } from '@apollo/client'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import Button from '../../../../components/Button'
import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'
import InputSelect from '../../../../components/InputSelect'
import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'

import i18n from '../../../../i18n/config'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import Checkbox from '../../../../components/Checkbox'
import {
  GetFleetsAndCompanyFleetQuery,
  GetFleetsAndCompanyFleetQueryVariables,
  UpdateFleetMutation,
  UpdateFleetMutationVariables,
} from '../../../../__generated__/graphql'

const schema = yup.object().shape({
  fleet: yup.object().shape({
    value: yup.string().required(i18n.t('forms.errors.required')),
  }),
  eligibleToSpecialBikes: yup.boolean().required(),
})

interface FormInput {
  fleet: { value: string; label: string }
  eligibleToSpecialBikes: boolean
}

const MUTATION_UPDATE_FLEET = gql`
  mutation UpdateFleet(
    $companyId: String!
    $fleetId: String!
    $eligibleToSpecialBikes: Boolean!
  ) {
    updateOneCompany(
      where: { id: $companyId }
      data: {
        fleet: { connect: { id: $fleetId } }
        eligibleToSpecialBikes: { set: $eligibleToSpecialBikes }
      }
    ) {
      fleet {
        id
        name
        areaId
      }
    }
  }
`

const QUERY_GET_FLEETS_AND_COMPANY_FLEET = gql`
  query GetFleetsAndCompanyFleet($companyId: String!, $where: FleetWhereInput) {
    fleets(where: $where) {
      id
      areaId
      name
    }
    company(where: { id: $companyId }) {
      fleet {
        id
        areaId
        name
      }
      eligibleToSpecialBikes
    }
  }
`

interface ModifyFleetProps {
  companyId: string
}

const ModifyFleet: React.FC<ModifyFleetProps> = ({ companyId }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const notifications = useNotifications()
  const theme = useTheme()
  const { t } = useTranslation()
  const { data, refetch: refetchFleets } = useQuery<
    GetFleetsAndCompanyFleetQuery,
    GetFleetsAndCompanyFleetQueryVariables
  >(QUERY_GET_FLEETS_AND_COMPANY_FLEET, {
    variables: {
      companyId,
      where: {
        area: {
          company: {
            some: {
              id: {
                equals: companyId,
              },
            },
          },
        },
      },
    },
  })
  const [updateFleet] = useMutation<
    UpdateFleetMutation,
    UpdateFleetMutationVariables
  >(MUTATION_UPDATE_FLEET)
  const { handleSubmit, errors, control } = useForm<FormInput>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const onSubmit = (data: FormInput) => {
    updateFleet({
      variables: {
        companyId,
        fleetId: data.fleet.value,
        eligibleToSpecialBikes: data.eligibleToSpecialBikes,
      },
    })
      .then(() => {
        refetchFleets()
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t('company.modifyFleetModal.notificationSuccess'),
        })
        setModalOpen(false)
      })
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Fragment>
      <div
        css={{
          display: 'flex',
          flex: 1,
          height: '100%',
          minHeight: '147px',
          cursor: 'pointer',
        }}
      >
        <Card
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            height: '100%',
            backgroundColor: theme.colors.yellowLegend,
          }}
          onClick={() => setModalOpen(true)}
        >
          <Icon
            color={theme.colors.primary}
            type="map"
            customSize={{ width: 80, height: 80 }}
          />
          <div>
            <p
              css={{
                fontFamily: theme.fontFamily,
                fontWeight: 'bold',
                fontSize: '1.8rem',
                lineHeight: '32px',
                color: theme.colors.gray1,
                textAlign: 'center',
              }}
            >
              {t('company.profile.modifyFleet')}
            </p>
          </div>
        </Card>
      </div>
      <Modal isOpen={modalOpen} onBackgroundClick={() => setModalOpen(false)}>
        <Popup
          maxWidth={750}
          title={t('company.modifyFleetModal.title')}
          closable
          overflowY="visible"
          onClose={() => setModalOpen(false)}
          footer={
            <Fragment>
              <Button type="tertiary" onClick={() => setModalOpen(false)}>
                {t('forms.button.cancel')}
              </Button>
              <div css={{ marginLeft: '14px' }}>
                <Button submit onClick={handleSubmit(onSubmit)}>
                  {t('forms.button.confirm')}
                </Button>
              </div>
            </Fragment>
          }
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontSize: '1.6rem',
              lineHeight: '24px',
              padding: '0 60px',
              [theme.media.tablet]: {
                padding: '0 0',
              },
            }}
          >
            <p css={{ textAlign: 'center' }}>
              {t('company.modifyFleetModal.description')}
            </p>
            <p
              css={{ textAlign: 'center' }}
              dangerouslySetInnerHTML={{
                __html: t('company.modifyFleetModal.description2'),
              }}
            />
            <form onSubmit={handleSubmit(onSubmit)} css={{ width: '100%' }}>
              <div
                css={{ marginTop: '18px', width: '100%', marginBottom: '8px' }}
              >
                <Controller
                  name="fleet"
                  control={control}
                  defaultValue={{
                    label: data?.company?.fleet?.name,
                    value: data?.company?.fleet?.id,
                  }}
                  render={({ onChange, value, name }) => (
                    <InputSelect
                      name={name}
                      value={value}
                      onChange={onChange}
                      error={!!errors.fleet}
                      helperText={errors?.fleet?.value?.message}
                      options={
                        data?.fleets.map(fleet => ({
                          label: fleet.name,
                          value: fleet.id,
                        })) || []
                      }
                    />
                  )}
                />
              </div>

              <Controller
                name="eligibleToSpecialBikes"
                control={control}
                defaultValue={data?.company?.eligibleToSpecialBikes}
                render={({ onChange, value }) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                      gap: 48,
                      marginBottom: 20,
                    }}
                  >
                    <span
                      css={{ textAlign: 'center' }}
                      dangerouslySetInnerHTML={{
                        __html: i18n.t(
                          'animator.companies.acceptCompany.eligibleToSpecialBikes',
                        ),
                      }}
                    />

                    <Checkbox
                      isChecked={value === true}
                      label={i18n.t('animator.companies.acceptCompany.yes')}
                      size="medium"
                      onClick={() => onChange(true)}
                      error={!!errors.eligibleToSpecialBikes}
                    ></Checkbox>

                    <Checkbox
                      isChecked={value === false}
                      label={i18n.t('animator.companies.acceptCompany.no')}
                      size="medium"
                      onClick={() => onChange(false)}
                      error={!!errors.eligibleToSpecialBikes}
                    ></Checkbox>
                  </div>
                )}
              />
            </form>
          </div>
        </Popup>
      </Modal>
    </Fragment>
  )
}

export default ModifyFleet
