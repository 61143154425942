/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Popup from '../../../../components/Popup'
import Button from '../../../../components/Button'
import { ApolloError, gql, useMutation, useQuery } from '@apollo/client'
import { StoreState } from '../../../../redux/rootReducer'
import Icon from '../../../../components/Icon'

import apiErrorCatcher, {
  newErrorNotification,
} from '../../../../utils/apiErrorCatcher'
import { useNotifications } from '../../../../contexts/NotificationContext'
import Modal from '../../../../components/Modal'
import { errors } from '@goodwatt/shared'
import { ProgramStepType } from '@goodwatt/shared/dist/types'
import {
  // GetCompanyQuery,
  // GetCompanyQueryVariables,
  GetProgramStepsQuery,
  StepUpCompanyProgramStepMutation,
  StepUpCompanyProgramStepMutationVariables,
} from '../../../../__generated__/graphql'

// const QUERY_GET_COMPANY = gql`
//   query GetCompany($companyId: String!) {
//     company(where: { id: $companyId }) {
//       id
//       conventionFilePath
//       firstDeliveryAppointment {
//         date
//         location
//       }
//       firstTrainingAppointment {
//         date
//         location
//       }
//       firstReturnAppointment {
//         date
//         location
//       }
//     }
//   }
// `

const QUERY_GET_PROGRAM_STEPS = gql`
  query GetProgramSteps {
    programSteps(orderBy: { stepNbr: asc }) {
      id
      name
      stepNbr
    }
  }
`

const MUTATION_STEP_UP_PROGRAM_STEP = gql`
  mutation StepUpCompanyProgramStep($companyId: String!) {
    stepUpCompanyProgramStep(companyId: $companyId) {
      id
      programStep {
        stepNbr
      }
    }
  }
`

interface StepStatusModalProps {
  modalIsOpen: boolean
  currentStepNbr?: number
  description?: string
  onClick?: (e: React.MouseEvent) => void
  closeModal: () => void
  refetchCompany: () => void
}

const StepStatusModal: React.FC<StepStatusModalProps> = ({
  modalIsOpen,
  currentStepNbr,
  closeModal,
  refetchCompany,
}) => {
  const theme = useTheme()
  const notifications = useNotifications()
  const { t } = useTranslation()
  const { id: companyId } = useParams<{ id: string }>()
  const companyName: string = useSelector(
    (state: StoreState) => state?.menu?.subMenuTitle,
  )
  // const { data: companyRAW } = useQuery<
  //   GetCompanyQuery,
  //   GetCompanyQueryVariables
  // >(QUERY_GET_COMPANY, { variables: { companyId } })
  // const company = companyRAW?.company

  const [stepUpCompanyProgramStep, { loading: loadingMutation }] = useMutation<
    StepUpCompanyProgramStepMutation,
    StepUpCompanyProgramStepMutationVariables
  >(MUTATION_STEP_UP_PROGRAM_STEP)
  const { data, loading: loadingQuery } = useQuery<GetProgramStepsQuery>(
    QUERY_GET_PROGRAM_STEPS,
  )

  let nextStep = null

  if (
    data?.programSteps?.find(step => {
      return step.stepNbr > (currentStepNbr || 0)
    })
  ) {
    nextStep = data?.programSteps?.find(step => {
      return step.stepNbr > (currentStepNbr || 0)
    })
  }

  const onSubmit: (
    e: React.MouseEvent<Element, MouseEvent>,
  ) => void | Promise<void> | undefined = () => {
    if (!companyId) return
    stepUpCompanyProgramStep({ variables: { companyId } })
      .then(() => {
        refetchCompany()
        closeModal()
      })
      .catch((e: { graphQLErrors: ApolloError[] }) => {
        const notif = newErrorNotification(notifications)

        if (!e.graphQLErrors?.length) return notifications.anErrorOccured()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apiErrors = e.graphQLErrors.map((e: any) => e.message)

        if (apiErrors.includes(errors.CannotIncreaseCompanyStep().message)) {
          if (currentStepNbr === ProgramStepType.CONTRACTUALIZATION)
            return notif(t('animator.company.progress.stepStatus.errorStep1'))
          if (currentStepNbr === ProgramStepType.EMPLOYEE_LOANS_SELECTION)
            return notif(t('animator.company.progress.stepStatus.errorStep3'))
          if (currentStepNbr === ProgramStepType.LOAN_BIKES_IN_PROGRESS)
            return notif(t('animator.company.progress.stepStatus.errorStep5'))
        }

        if (
          apiErrors.includes(errors.ResourceNotFound('GEO_COORDINATES').message)
        ) {
          return notif(
            t(
              'animator.company.progress.stepStatus.errorStep4.coordinatesNotFound',
            ),
          )
        }
        if (
          apiErrors.includes(
            errors.MirioApiError('CANNOT_CREATE_GROUP').message,
          )
        ) {
          return notif(
            t('animator.company.progress.stepStatus.errorStep4.morioError'),
          )
        }
        return apiErrorCatcher(notifications)(e)
      })
  }

  return (
    <Modal isOpen={modalIsOpen} onBackgroundClick={closeModal}>
      <Popup
        title={t('animator.company.progress.stepStatus.confirmationPopup')}
        closable
        onClose={closeModal}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button type="tertiary" onClick={closeModal}>
                {t('forms.button.cancel')}
              </Button>
            </div>
            <Button type="primary" onClick={onSubmit} loading={loadingMutation}>
              {t('forms.button.confirm')}
            </Button>
          </Fragment>
        }
      >
        {loadingQuery ? (
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Icon
              type="loader"
              size="large"
              strokeColor={theme.colors.primary}
            />
          </div>
        ) : (
          <Fragment>
            <p
              css={{
                ...styles.paragraph,
                color: theme.colors.gray1,
              }}
            >
              {t('animator.company.progress.stepStatus.description1')}
            </p>
            <p
              css={{
                ...styles.paragraph,
                color: theme.colors.gray1,
              }}
              dangerouslySetInnerHTML={{
                __html: t('animator.company.progress.stepStatus.description2', {
                  companyName,
                  stepNbr: nextStep?.stepNbr,
                  stepLabel: t(`shared.programStepLabel.${nextStep?.name}`),
                }),
              }}
            />
          </Fragment>
        )}
      </Popup>
    </Modal>
  )
}

export default StepStatusModal

const styles = {
  paragraph: {
    'font-size': '16px',
    'line-height': '20px',
    'text-align': 'center',
  },
}
