/** @jsx jsx */
import React, { Fragment, useMemo, useState } from 'react'
import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'
import Button from '../../../../components/Button'
import Switch from '../../../../components/Switch'
import { jsx } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import InputSelect from '../../../../components/InputSelect'
import Input from '../../../../components/Input'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { MUTATION_CREATE_ONE_BIKE } from '../../../../utils/gql/mutations'

import {
  QUERY_ALL_FLEET_NAMES,
  QUERY_GET_BIKE_CONDITION,
  QUERY_GET_BIKE_MODELS,
  QUERY_GET_BIKES,
} from '../../../../utils/gql/queries'
import { useNotifications } from '../../../../contexts/NotificationContext'

import { useSelector } from 'react-redux'
import { StoreState } from '../../../../redux/rootReducer'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import { UserRoles } from '../../../../redux/user'
import {
  BikeSize,
  CreateOneBikeMutation,
  CreateOneBikeMutationVariables,
  GetAllFleetNamesQuery,
  GetAllFleetNamesQueryVariables,
  GetBikeConditionQuery,
  GetBikeConditionQueryVariables,
  GetBikeModelQuery,
  GetBikesQuery,
  GetBikesQueryVariables,
} from '../../../../__generated__/graphql'

interface ModalProps {
  isOpen: boolean
  closeModal: (shouldRefetch?: boolean) => void
}

interface AddBike {
  stickerId: string
  morioId: string
  bicycode: string
  keysId: string
  modelId: string | undefined
  fleetId: string | undefined
  size: BikeSize | undefined
  childSeat: boolean
  padlockId: string
  frameId: string
}

const initialAddBikeState: AddBike = {
  stickerId: '',
  morioId: '',
  bicycode: '',
  keysId: '',
  modelId: undefined,
  fleetId: undefined,
  size: undefined,
  childSeat: false,
  padlockId: '',
  frameId: '',
}

const AddBikeModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
  const { t } = useTranslation()
  const notifications = useNotifications()

  const { id: userId, role } = useSelector((state: StoreState) => state.user)
  const [bike, setBike] = useState<AddBike>(initialAddBikeState)

  const fleetSearchVariables: GetAllFleetNamesQueryVariables =
    role === UserRoles.ADMIN
      ? {}
      : {
          where: {
            area: {
              animator: {
                some: {
                  userId: { equals: userId },
                },
              },
            },
          },
        }

  const { data: fleets, loading: loadingFleets } = useQuery<
    GetAllFleetNamesQuery,
    GetAllFleetNamesQueryVariables
  >(QUERY_ALL_FLEET_NAMES, {
    variables: fleetSearchVariables,
  })

  const { data: bikeModels, loading: loadingBikeModels } =
    useQuery<GetBikeModelQuery>(QUERY_GET_BIKE_MODELS)

  const [createOneBike, { loading: createBikeLoading }] = useMutation<
    CreateOneBikeMutation,
    CreateOneBikeMutationVariables
  >(MUTATION_CREATE_ONE_BIKE)

  const { data: bikeConditionInfo, loading: bikeConditionLoading } = useQuery<
    GetBikeConditionQuery,
    GetBikeConditionQueryVariables
  >(QUERY_GET_BIKE_CONDITION, {
    variables: {
      where: {
        name: 'stock',
      },
    },
  })

  const [getBikeErrors, { loading: bikeErrorsLoading, data: bikeErrors }] =
    useLazyQuery<GetBikesQuery, GetBikesQueryVariables>(QUERY_GET_BIKES, {
      onCompleted: () => {
        if (!bikeErrors || bikeErrors.bikes.length > 0) return

        createOneBike({
          variables: {
            data: {
              stickerId: parseInt(bike.stickerId!),
              keysId: bike.keysId,
              morioId: bike.morioId,
              bicycode: bike.bicycode,
              bikeModel: { connect: { id: bike.modelId } },
              fleet: { connect: { id: bike.fleetId } },
              bikeCondition: {
                connect: { id: bikeConditionInfo?.bikeCondition?.id },
              },
              size: bike.size,
              childSeat: bike.childSeat,
              padlockId: bike.padlockId,
              frameId: bike.frameId,
            },
          },
        })
          .then(() => closeModal(true))
          .catch(apiErrorCatcher(notifications))
      },
    })

  const bikeSizeOptions = useMemo(() => {
    if (!bikeModels?.bikeModels) return []

    const selectedModel = bikeModels.bikeModels.find(
      bm => bm.id === bike.modelId,
    )

    if (!selectedModel) return []

    setBike(prevState => ({
      ...prevState,
      size: undefined,
      childSeat: false,
    }))

    return selectedModel.modelSizes.map(ms => ({
      value: ms,
      label: ms,
    }))
  }, [bike.modelId, bikeModels])

  const onSelectChange = React.useCallback(({ value }, { name }) => {
    setBike(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  const onBikeInputChange = React.useCallback(e => {
    e.persist()
    setBike(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }, [])

  const onChangeBikeChildSeat = () => {
    const disabled =
      bikeModels?.bikeModels.find(bm => bm.id === bike.modelId)
        ?.childSeatCompatibility === false

    if (disabled || !bike.modelId) {
      setBike(prevState => ({
        ...prevState,
        childSeat: false,
      }))
    } else {
      setBike(prevState => ({
        ...prevState,
        childSeat: !prevState.childSeat,
      }))
    }
  }

  const onModalClose = (shouldRefetch = false) => {
    closeModal(shouldRefetch)
  }

  const isLoading =
    createBikeLoading ||
    bikeConditionLoading ||
    loadingFleets ||
    loadingBikeModels ||
    bikeErrorsLoading

  const isFormValid =
    bike.stickerId && bike.modelId && bike.fleetId && bike.size

  const handleSubmit = () => {
    if (!isFormValid || isLoading) return

    getBikeErrors({
      variables: {
        where: {
          OR: [
            { stickerId: { equals: parseInt(bike.stickerId!) } },
            { morioId: { equals: bike.morioId } },
            bike.bicycode ? { bicycode: { equals: bike.bicycode } } : {},
            bike.keysId ? { keysId: { equals: bike.keysId } } : {},
            bike.frameId ? { frameId: { equals: bike.frameId } } : {},
            bike.padlockId ? { padlockId: { equals: bike.padlockId } } : {},
          ],
        },
      },
    })
  }

  const checkError = (propName: string) => {
    if (!bikeErrors?.bikes) return false
    return (
      bikeErrors.bikes.find(
        b =>
          // @ts-ignore
          b[propName]?.toString() ===
          bike[propName as keyof AddBike]?.toString(),
      ) !== undefined
    )
  }

  return (
    <Modal isOpen={isOpen} onBackgroundClick={onModalClose}>
      <Popup
        overflowY="visible"
        closable
        title={t('animator.bikes.modal.add.title')}
        onClose={() => onModalClose(false)}
        maxWidth={1000}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button onClick={() => onModalClose(false)} type="tertiary">
                {t('shared.button.cancel')}
              </Button>
            </div>
            <Button
              type="primary"
              submit
              loading={isLoading}
              onClick={handleSubmit}
              disabled={!isFormValid}
            >
              {t('forms.button.save')}
            </Button>
          </Fragment>
        }
      >
        <form onSubmit={handleSubmit}>
          <div css={{ display: 'flex', flexDirection: 'column', padding: 30 }}>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div css={{ marginRight: 20, flex: 3 }}>
                <Input
                  type="text"
                  name="stickerId"
                  onlyNumbers
                  onChange={onBikeInputChange}
                  error={checkError('stickerId')}
                  withError
                  helperText={
                    checkError('stickerId')
                      ? t('animator.bikes.modal.errors.alreadyUse')
                      : ''
                  }
                  label={t('animator.bikes.modal.fields.stickerId')}
                />
              </div>
              <div css={{ display: 'flex', flex: 5 }}>
                <InputSelect
                  name="fleetId"
                  label={t('forms.label.fleet')}
                  onChange={onSelectChange}
                  options={
                    fleets?.fleets
                      ? fleets.fleets.map(f => ({
                          value: f.id,
                          label: f.name,
                        }))
                      : []
                  }
                />
              </div>
            </div>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div css={{ marginRight: 20, flex: 3 }}>
                <Input
                  type="text"
                  name="morioId"
                  onChange={onBikeInputChange}
                  maxLength={8}
                  error={checkError('morioId')}
                  withError
                  helperText={
                    checkError('morioId')
                      ? t('animator.bikes.modal.errors.alreadyUse')
                      : ''
                  }
                  label={t('animator.bikes.modal.fields.morioId')}
                />
              </div>
              <div css={{ flex: 5 }}>
                <Input
                  type="text"
                  name="bicycode"
                  onChange={onBikeInputChange}
                  maxLength={12}
                  error={checkError('bicycode')}
                  withError
                  helperText={
                    checkError('bicycode')
                      ? t('animator.bikes.modal.errors.alreadyUse')
                      : ''
                  }
                  label={t('animator.bikes.modal.fields.bicycode')}
                />
              </div>
            </div>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div css={{ marginRight: 20, flex: 3 }}>
                <Input
                  type="text"
                  name="keysId"
                  onChange={onBikeInputChange}
                  maxLength={255}
                  error={checkError('keysId')}
                  withError
                  helperText={
                    checkError('keysId')
                      ? t('animator.bikes.modal.errors.alreadyUse')
                      : ''
                  }
                  label={t('animator.bikes.modal.fields.keysId')}
                />
              </div>

              <div css={{ marginRight: 20, flex: 3 }}>
                <Input
                  type="text"
                  name="frameId"
                  onChange={onBikeInputChange}
                  maxLength={255}
                  error={checkError('frameId')}
                  withError
                  helperText={
                    checkError('frameId')
                      ? t('animator.bikes.modal.errors.alreadyUse')
                      : ''
                  }
                  label={t('animator.bikes.modal.fields.frameId')}
                />
              </div>

              <div css={{ marginRight: 20, flex: 3 }}>
                <Input
                  type="text"
                  name="padlockId"
                  onChange={onBikeInputChange}
                  maxLength={255}
                  error={checkError('padlockId')}
                  withError
                  helperText={
                    checkError('padlockId')
                      ? t('animator.bikes.modal.errors.alreadyUse')
                      : ''
                  }
                  label={t('animator.bikes.modal.fields.padlockId')}
                />
              </div>
            </div>

            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div css={{ marginRight: 20, flex: 3 }}>
                <InputSelect
                  name="modelId"
                  label={t('animator.bikes.table.headers.bikeModelName')}
                  onChange={onSelectChange}
                  options={
                    bikeModels?.bikeModels
                      ? bikeModels.bikeModels.map(bm => ({
                          value: bm.id,
                          label: bm.name,
                        }))
                      : []
                  }
                />
              </div>

              <div css={{ marginRight: 20, flex: 2 }}>
                <InputSelect
                  name="size"
                  label={t('animator.bikes.table.headers.size')}
                  onChange={onSelectChange}
                  options={bikeSizeOptions}
                />
              </div>

              <div css={{ flex: 2 }}>
                <div css={{ width: '100%' }}>
                  <div css={{ marginLeft: '24px', marginBottom: '4px' }}>
                    <label
                      css={{
                        fontSize: '1.6rem',
                        fontWeight: 'normal',
                        color: '#828282',
                      }}
                    >
                      {t(`animator.bikes.modal.fields.childSeatEquiped`)}
                    </label>
                  </div>
                  <div
                    css={{
                      height: '44px',
                      marginBottom: '11px',
                      marginTop: '11px',
                    }}
                  >
                    <Switch
                      isChecked={bike.childSeat}
                      onClick={() => onChangeBikeChildSeat()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default AddBikeModal
