/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { Controller, useForm } from 'react-hook-form'

import Modal from '../../components/Modal'
import Popup from '../../components/Popup'
import EditAvatar from './EditAvatar'
import Button from '../../components/Button'
import Input from '../../components/Input'

import {
  NotificationTypes,
  useNotifications,
} from '../../contexts/NotificationContext'

import i18n from '../../i18n/config'
import apiErrorCatcher from '../../utils/apiErrorCatcher'
import {
  MUTATION_CREATE_ANIMATOR,
  MUTATION_UPDATE_ANIMATOR,
} from '../../utils/gql/mutations'
import { useTheme } from 'emotion-theming'
import { employeeNameFormatter } from '../../utils/formatter'

import { QUERY_AREAS } from '../../utils/gql/queries'
import InputSelect from '../../components/InputSelect'

import { AnimatorTableModal } from '../../types/admin/AdminAnimator'
import { EmployeeCivility, UserRoles } from '@goodwatt/shared/dist/types'
import { isNumber } from 'lodash'
import { countiesList } from '../../utils/countries'
import Checkbox from '../../components/Checkbox'
import {
  Civility,
  CreateAnimatorMutation,
  CreateAnimatorMutationVariables,
  GetAnimatorsQuery,
  GetAnimatorsQueryVariables,
  GetAreasQuery,
  Role,
  UpdateOneAnimatorMutation,
  UpdateOneAnimatorMutationVariables,
} from '../../__generated__/graphql'

const schema = yup.object().shape({
  firstName: yup.string().required(i18n.t('forms.errors.required')),
  lastName: yup.string().required(i18n.t('forms.errors.required')),
  street: yup.string().required(i18n.t('forms.errors.required')),
  civility: yup.string().required(i18n.t('forms.errors.required')),
  country: yup.object().shape({
    value: yup.string().required(i18n.t('forms.errors.required')),
  }),
  postalCode: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .length(5, i18n.t('forms.errors.wrongFormat')),
  city: yup.string().required(i18n.t('forms.errors.required')),
  email: yup.string().required(i18n.t('forms.errors.required')),
  phoneNumber: yup
    .string()
    .required(i18n.t('forms.errors.required'))
    .length(10, i18n.t('forms.errors.phoneFormat')),
})

interface EditAnimatorInfoForm {
  firstName: string
  lastName: string
  street: string
  country: {
    label: string
    value: string
  }
  civility: Civility
  area:
    | number
    | {
        value: number
      }
  email: string
  postalCode: string
  city: string
  phoneNumber: string
}

export enum AnchorEditEmployeeProfileType {
  USER_INFO = 'userInfo',
  USER_CONTACT = 'userContact',
  USER_ADDRESS = 'userAddress',
  USER_BIRTH = 'userBirth',
}

const scrollIntoViewSettings: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'start',
}

interface EditEmployeeProfileProps {
  animator: GetAnimatorsQuery['animators'][0] | null
  closeModal: () => void
  submitCloseModal: () => void
  refetch: (newData?: Partial<GetAnimatorsQueryVariables>) => void
  modalIsOpen?: boolean
  type: AnimatorTableModal
  anchorOnOpen?: AnchorEditEmployeeProfileType
}

const EditAnimatorProfile: React.FC<EditEmployeeProfileProps> = ({
  animator,
  closeModal,
  submitCloseModal,
  refetch,
  modalIsOpen = true,
  type = AnimatorTableModal.NONE,
  anchorOnOpen,
}) => {
  /* ANCHORS FOR EDIT MODAL */
  const userInfoRef = React.useRef<HTMLDivElement>(null)
  const userContactRef = React.useRef<HTMLDivElement>(null)
  const userAddressRef = React.useRef<HTMLDivElement>(null)
  const userBirthRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (modalIsOpen) {
      if (anchorOnOpen === AnchorEditEmployeeProfileType.USER_INFO) {
        userInfoRef?.current?.scrollIntoView(scrollIntoViewSettings)
      } else if (anchorOnOpen === AnchorEditEmployeeProfileType.USER_CONTACT) {
        userContactRef?.current?.scrollIntoView(scrollIntoViewSettings)
      } else if (anchorOnOpen === AnchorEditEmployeeProfileType.USER_BIRTH) {
        userBirthRef.current?.scrollIntoView(scrollIntoViewSettings)
      } else if (anchorOnOpen === AnchorEditEmployeeProfileType.USER_ADDRESS) {
        userAddressRef.current?.scrollIntoView(scrollIntoViewSettings)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsOpen])

  const theme = useTheme()
  const { t } = useTranslation()
  const notifications = useNotifications()

  const { register, handleSubmit, errors, control, setError } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [updateAnimator, { loading: updateLoading }] = useMutation<
    UpdateOneAnimatorMutation,
    UpdateOneAnimatorMutationVariables
  >(MUTATION_UPDATE_ANIMATOR)

  const [createAnimator, { loading: creationLoading }] = useMutation<
    CreateAnimatorMutation,
    CreateAnimatorMutationVariables
  >(MUTATION_CREATE_ANIMATOR)

  const onSubmit = (inputData: EditAnimatorInfoForm) => {
    const areaId = isNumber(inputData.area)
      ? inputData.area
      : inputData.area.value

    if (!inputData.country.value) {
      setError('country', {
        message: i18n.t('forms.errors.required'),
      })
      return
    }

    if (type === AnimatorTableModal.ADD) {
      createAnimator({
        variables: {
          data: {
            user: {
              create: {
                password: '',
                email: inputData.email,
                userRole: {
                  connect: {
                    name: UserRoles.ANIMATOR,
                  },
                },
              },
            },
            area: {
              connect: {
                id: areaId,
              },
            },
            signature: 'yes',
            civility: inputData.civility,
            country: inputData.country.value,
            avatarUrl: '',
            firstName: inputData.firstName,
            lastName: inputData.lastName,
            phoneNumber: inputData.phoneNumber,
            street: inputData.street,
            postalCode: inputData.postalCode,
            city: inputData.city,
          },
        },
      })
        .then(result => {
          if (result.data?.createOneAnimator?.id) {
            notifications.newNotification({
              type: NotificationTypes.SUCCESS,
              message: i18n.t('admin.animator.notification.successCreate'),
            })
            refetch()
            submitCloseModal()
          }
        })
        .catch(apiErrorCatcher(notifications))
    } else {
      updateAnimator({
        variables: {
          where: { id: animator?.id },
          data: {
            area: {
              connect: { id: areaId },
            },
            user: {
              update: {
                email: { set: inputData.email },
              },
            },
            firstName: { set: inputData.firstName },
            lastName: { set: inputData.lastName },
            country: { set: inputData.country.value },
            civility: { set: inputData.civility },
            phoneNumber: { set: inputData.phoneNumber },
            street: { set: inputData.street },
            postalCode: { set: inputData.postalCode },
            city: { set: inputData.city },
          },
        },
      })
        .then(result => {
          if (result.data?.updateOneAnimator?.id) {
            notifications.newNotification({
              type: NotificationTypes.SUCCESS,
              message: i18n.t('admin.animator.notification.successUpdate'),
            })
            refetch()
            submitCloseModal()
          }
        })
        .catch(_ => {
          apiErrorCatcher(notifications)
        })
    }
  }

  const { data: areaData } = useQuery<GetAreasQuery>(QUERY_AREAS)

  const areaOptions = areaData?.areas.map(x => ({
    label: x.name || '',
    value: x.id || '',
  }))

  return (
    <Modal isOpen={modalIsOpen} onBackgroundClick={closeModal}>
      <Popup
        maxWidth={750}
        title={t('employee.profile.editModalTitle', {
          name: employeeNameFormatter(animator?.firstName, animator?.lastName),
        })}
        onClose={closeModal}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <div css={{ marginRight: 40 }} />
            <Button
              loading={creationLoading || updateLoading}
              type="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {t('forms.button.save')}
            </Button>
          </div>
        }
      >
        <div ref={userInfoRef} />
        <EditAvatar
          resourceId={animator?.id || ''}
          role={Role.Animator}
          refetch={() => refetch()}
          avatarUrl={animator?.avatarUrl || undefined}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            css={{
              width: '100%',
              padding: '0 12px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              [theme.media.mobile]: {
                padding: '0',
              },
            }}
          >
            <Input
              type="text"
              name="firstName"
              defaultValue={animator?.firstName}
              label={t('forms.label.firstName')}
              register={register}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
            <Input
              type="text"
              name="lastName"
              defaultValue={animator?.lastName}
              label={t('forms.label.lastName')}
              register={register}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />

            <span
              style={{
                marginLeft: 21,
                marginBottom: 12,
                fontSize: '1.6rem',
                color: '#828282',
                alignSelf: 'flex-start',
              }}
            >
              Civilité
            </span>

            <Controller
              name="civility"
              control={control}
              defaultValue={animator?.civility}
              render={({ onChange, value }) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    gap: 48,
                    marginLeft: 21,
                  }}
                >
                  <Checkbox
                    isChecked={value === EmployeeCivility.MISTER}
                    label="Monsieur"
                    size="medium"
                    error={!!errors.civility}
                    onClick={() => onChange(EmployeeCivility.MISTER)}
                  ></Checkbox>
                  <Checkbox
                    isChecked={value === EmployeeCivility.MISTRESS}
                    label="Madame"
                    size="medium"
                    error={!!errors.civility}
                    onClick={() => onChange(EmployeeCivility.MISTRESS)}
                  ></Checkbox>
                </div>
              )}
            />

            <div css={{ marginBottom: 24 }} />

            <Controller
              name="area"
              control={control}
              defaultValue={animator?.area.id || ''}
              render={({ onChange, onBlur, value, name }) => (
                <InputSelect
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  label={t('forms.label.area')}
                  register={register}
                  error={!!errors.area}
                  helperText={errors.area?.message}
                  options={areaOptions || []}
                  defaultValue={value}
                />
              )}
            />

            <div css={{ marginBottom: 24 }} />
            <div ref={userContactRef} />
            <Input
              type="text"
              name="email"
              register={register}
              error={!!errors.email}
              helperText={errors.email?.message}
              defaultValue={animator?.user.email}
              label={t('forms.label.email')}
            />
            <Input
              type="text"
              name="phoneNumber"
              label={t('forms.label.phoneNumber')}
              defaultValue={animator?.phoneNumber}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber?.message}
              register={register}
              onlyNumbers
              maxLength={10}
            />
            <div css={{ marginBottom: 24 }} />
            <div ref={userAddressRef} />
            <Input
              type="text"
              name="street"
              defaultValue={animator?.street || undefined}
              label={t('forms.label.address')}
              error={!!errors.street}
              helperText={errors.street?.message}
              register={register}
            />
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                [theme.media.mobile]: {
                  flexDirection: 'column',
                },
              }}
            >
              <div
                css={{
                  flex: 2,
                  marginRight: 15,
                  [theme.media.mobile]: {
                    marginRight: 0,
                  },
                }}
              >
                <Input
                  type="text"
                  name="postalCode"
                  label={t('forms.label.postalCode')}
                  defaultValue={animator?.postalCode || undefined}
                  error={!!errors.postalCode}
                  helperText={errors.postalCode?.message}
                  onlyNumbers
                  maxLength={5}
                  register={register}
                />
              </div>
              <div css={{ flex: 3 }}>
                <Input
                  type="text"
                  name="city"
                  label={t('forms.label.city')}
                  defaultValue={animator?.city || undefined}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  register={register}
                />
              </div>
            </div>
            <Controller
              name="country"
              control={control}
              defaultValue={{
                label: animator?.country,
                value: animator?.country,
              }}
              render={({ onChange, onBlur, value, name }) => (
                <InputSelect
                  options={countiesList.map(x => ({
                    label: x,
                    value: x,
                  }))}
                  type="text"
                  name={name}
                  onBlur={onBlur}
                  value={value}
                  defaultValue={animator?.country || undefined}
                  label={t('forms.label.country')}
                  error={!!errors.country?.value}
                  helperText={errors.country?.value?.message}
                  register={register}
                  onChange={onChange}
                />
              )}
            />
            <div css={{ marginBottom: '24px' }} />
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                flexWrap: 'wrap',
                [theme.media.mobile]: {
                  flexDirection: 'column',
                },
              }}
            >
              {/* <div
                ref={userBirthRef}
                css={{
                  flex: 2,
                  marginRight: 15,
                  [theme.media.mobile]: {
                    marginRight: 0,
                  },
                }}
              >
                <Controller
                  name="birthdate"
                  control={control}
                  defaultValue={
                    animator?.birthdate ? new Date(animator?.birthdate) : null
                  }
                  render={({ onChange, value }) => {
                    return (
                      <DateTime
                        placeholder={t('forms.label.placeholderBirthdate')}
                        label={t('forms.label.birthdate')}
                        block={false}
                        value={value}
                        onChange={onChange}
                        type="date"
                        icon={null}
                        disableDatePicker
                        error={!!errors.birthdate}
                        helperText={errors.birthdate?.message}
                      />
                    )
                  }}
                />
              </div> */}
              {/* <div css={{ flex: 3 }}>
                <Input
                  type="text"
                  name="cityOfBirth"
                  label={t('forms.label.cityOfBirth')}
                  defaultValue={animator?.cityOfBirth || undefined}
                  error={!!errors.cityOfBirth}
                  helperText={errors.cityOfBirth?.message}
                  register={register}
                />
              </div> */}
            </div>
            {/* <Input
              type="text"
              name="height"
              label={t('forms.label.height')}
              placeholder={t('forms.label.heightPlaceholder')}
              description={t('forms.label.heightDescription')}
              defaultValue={(animator?.height || '').toString()}
              error={!!errors.height}
              helperText={errors.height?.message}
              register={register}
              onlyNumbers
              maxLength={3}
            /> */}
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default EditAnimatorProfile
