/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useQuery } from '@apollo/client'
import { useTheme } from 'emotion-theming'
import React, { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import CardInfo from '../../../components/CardInfo'
import { IconTypes } from '../../../components/Icon'
import ModifyPassword from '../../Company/Profile/ModifyPassword'
import { StoreState } from '../../../redux/rootReducer'
import { useHistory } from 'react-router-dom'
import { displayAvatar } from '../../../utils/files'
import { AnchorEditEmployeeProfileType } from '../../Shared/EditEmployeeModal'
import { AnchorEditHabitType } from '../../Shared/EditEmployeeHabitsModal'
import { GetEmployeeQuery } from '../../../__generated__/graphql'
import { QUERY_GET_EMPLOYEE_DASHBOARD } from '../../../utils/gql/queries'
import {
  DeploymentType,
  GetEmployeeDashboardQuery,
  GetEmployeeDashboardQueryVariables,
} from '../../../__generated__/graphql'
import Loader from '../../../components/Loader'
import { employeeNameFormatter, phoneFormatter } from '../../../utils/formatter'

export enum DocStatus {
  NEED_UPLOAD = 'NEED_UPLOAD',
  VALIDATING = 'WAITING_VALIDATION',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
}

export const getStatus = (
  document: string | null | undefined,
  isVerified: boolean | null | undefined,
): DocStatus | undefined => {
  if (!document && isVerified === null) return DocStatus.NEED_UPLOAD
  if (document && isVerified === null) return DocStatus.VALIDATING
  if (document && isVerified) return DocStatus.VERIFIED
  if (document && isVerified === false) return DocStatus.REJECTED
}

export const getDepositStatus = (
  depositId: string | null | undefined,
  depositExpirationDate: Date | null | undefined,
): DocStatus => {
  if (!depositId) return DocStatus.NEED_UPLOAD
  if (depositExpirationDate && new Date() >= new Date(depositExpirationDate))
    return DocStatus.NEED_UPLOAD
  if (depositId) return DocStatus.VERIFIED
  return DocStatus.NEED_UPLOAD
}

interface PreviewProps {
  data: GetEmployeeQuery
  openProfileModal: (anchor: AnchorEditEmployeeProfileType) => void
  openHabitsModal: (anchor: AnchorEditHabitType) => void
}

interface CardsInfo {
  avatar: boolean
  avatarUrl: string
  title: string
  data: {
    icon?: IconTypes
    description: string
  }[]
  onEditClicked?: () => void
}

interface CardsInfoData {
  icon?: IconTypes
  iconColor?: string
  description: string
}

const Preview: React.FC<PreviewProps> = ({
  data,
  openProfileModal,
  openHabitsModal,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const userId = useSelector((state: StoreState) => state.user.id)
  const history = useHistory()

  const { data: employee, loading } = useQuery<
    GetEmployeeDashboardQuery,
    GetEmployeeDashboardQueryVariables
  >(QUERY_GET_EMPLOYEE_DASHBOARD, {
    fetchPolicy: 'no-cache',
    variables: { where: { userId } },
  })

  const identityStatus = useMemo(
    () =>
      getStatus(
        employee?.employee?.identityCardFrontFilePath,
        employee?.employee?.identityCardVerified,
      ),
    [employee],
  )

  const addressStatus = useMemo(
    () =>
      getStatus(
        employee?.employee?.proofOfAddressFilePath,
        employee?.employee?.proofOfAddressVerified,
      ),
    [employee],
  )

  const getIconColor = (status: DocStatus | undefined): string => {
    if (status === DocStatus.NEED_UPLOAD) return theme.colors.primary
    if (status === DocStatus.VALIDATING) return theme.colors.yellowLegend
    if (status === DocStatus.REJECTED) return theme.colors.redLegend
    if (status === DocStatus.VERIFIED) return theme.colors.greenLegend
    return theme.colors.primary
  }

  const cautionStatus = useMemo((): DocStatus => {
    if (employee?.employee?.depositByCheque === true) {
      return DocStatus.VERIFIED
    }

    if (
      employee?.employee?.depositId &&
      employee?.employee?.depositExpirationDate &&
      new Date() < new Date(employee?.employee?.depositExpirationDate)
    )
      return DocStatus.VERIFIED
    return getDepositStatus(
      employee?.employee?.depositId,
      employee?.employee?.depositExpirationDate,
    )
  }, [employee])

  // TODO: What if user have multiple deployments?
  const employeeDeployment = employee?.employee?.employeeDeployments
    ? employee.employee.employeeDeployments[0]
    : null

  const employeeDeploymentType =
    employeeDeployment?.deployment?.deploymentType || DeploymentType.None

  const showDepositRemember =
    employeeDeploymentType === DeploymentType.Discovery

  const unknownLabelHandler = useCallback(
    (resource, label = ''): { description: string; textColor?: string } =>
      resource
        ? { description: label || resource }
        : {
            description: t('shared.errors.unknown'),
            textColor: theme.colors.error,
          },
    [t, theme],
  )

  const cardsInfoData: CardsInfoData[] = [
    {
      icon: 'idCard',
      iconColor: getIconColor(identityStatus as DocStatus), // Explicitly define the type of 'identityStatus' as 'DocStatus'
      ...unknownLabelHandler(
        ((): string => {
          if (identityStatus === DocStatus.NEED_UPLOAD) return t('employee.documents.idCardStatus.upload'); // prettier-ignore
          if (identityStatus === DocStatus.VALIDATING) return t('employee.documents.idCardStatus.waitingValidation'); // prettier-ignore
          if (identityStatus === DocStatus.REJECTED) return t('employee.documents.idCardStatus.refused'); // prettier-ignore
          if (identityStatus === DocStatus.VERIFIED) return t('employee.documents.idCardStatus.accepted'); // prettier-ignore
          return ''
        })(),
      ),
    },
    {
      icon: 'address',
      iconColor: getIconColor(addressStatus as DocStatus), // Explicitly define the type of 'addressStatus' as 'DocStatus'
      ...unknownLabelHandler(
        ((): string => {
          if (addressStatus === DocStatus.NEED_UPLOAD) return t('employee.documents.proofOfAddressStatus.upload'); // prettier-ignore
          if (addressStatus === DocStatus.VALIDATING) return t('employee.documents.proofOfAddressStatus.waitingValidation'); // prettier-ignore
          if (addressStatus === DocStatus.REJECTED) return t('employee.documents.proofOfAddressStatus.refused'); // prettier-ignore
          if (addressStatus === DocStatus.VERIFIED) return t('employee.documents.proofOfAddressStatus.accepted'); // prettier-ignore
          return ''
        })(),
      ),
    },
  ]

  showDepositRemember &&
    cardsInfoData.push({
      icon: 'deposit',
      iconColor: getIconColor(cautionStatus as DocStatus), // Explicitly define the type of 'depositStatus' as 'DocStatus'
      ...unknownLabelHandler(
        cautionStatus === DocStatus.VERIFIED
          ? t('employee.documents.depositStatus.accepted')
          : t('employee.documents.depositStatus.upload'),
      ),
    })

  const cardsInfo: CardsInfo[] = React.useMemo(
    () => [
      {
        avatar: true,
        avatarUrl: displayAvatar(data.employee?.avatarUrl || undefined),
        title: employeeNameFormatter(
          data.employee?.firstName,
          data.employee?.lastName,
        ),
        data: [
          {
            icon: 'calendarChecked',
            ...unknownLabelHandler(
              data.employee?.birthdate,
              format(new Date(data.employee?.birthdate), 'dd/MM/yyyy'),
            ),
          },
          {
            icon: 'locate',
            ...unknownLabelHandler(
              data.employee?.cityOfBirth,
              `${data.employee?.cityOfBirth}, France`,
            ),
          },
          {
            icon: 'height',
            ...unknownLabelHandler(
              data.employee?.height,
              `${data.employee?.height} cm`,
            ),
          },
        ],
        onEditClicked: () =>
          openProfileModal(AnchorEditEmployeeProfileType.USER_INFO),
        editIconColor:
          !data.employee?.firstName ||
          !data.employee?.lastName ||
          !data.employee?.birthdate ||
          !data.employee?.cityOfBirth ||
          !data.employee?.height
            ? theme.colors.error
            : theme.colors.gray1,
      },
      {
        avatar: false,
        avatarUrl: '',
        title: t('employee.profile.myDocuments'),
        data: [...cardsInfoData],
        onEditClicked: () => {
          history.push('/employee/documents')
        },
      },
      {
        avatar: false,
        avatarUrl: '',
        title: t('employee.profile.contact'),
        data: [
          {
            icon: 'home',
            ...unknownLabelHandler(data?.employee?.street),
          },
          {
            icon: 'home',
            iconColor: 'transparent',
            ...unknownLabelHandler(
              data?.employee?.postalCode && data?.employee?.city,
              `${data?.employee?.postalCode} ${data?.employee?.city}`,
            ),
          },
          {
            icon: 'envelope',
            description: data?.employee?.user.email || '',
          },
          {
            icon: 'phone',
            description: phoneFormatter(data?.employee?.phoneNumber || ''),
          },
        ],
        onEditClicked: () =>
          openProfileModal(AnchorEditEmployeeProfileType.USER_ADDRESS),
        editIconColor:
          !data?.employee?.user.email ||
          !data?.employee?.phoneNumber ||
          !data.employee?.street ||
          !data.employee?.city ||
          !data.employee?.postalCode
            ? theme.colors.error
            : theme.colors.gray1,
      },
      {
        avatar: false,
        avatarUrl: '',
        title: t('employee.profile.habits'),
        direction: 'row',
        data: [
          {
            icon: 'road',
            ...unknownLabelHandler(
              data?.employee?.travelMode?.name,
              t(`shared.travelModeLabel.${data?.employee?.travelMode?.name}`),
            ),
          },
          {
            icon: 'distance',
            ...unknownLabelHandler(
              data.employee?.workTravelDistance,
              `${data.employee?.workTravelDistance} km`,
            ),
          },
          {
            icon: 'clock',
            ...unknownLabelHandler(
              data.employee?.workTravelDuration,
              `${data.employee?.workTravelDuration} mn`,
            ),
          },
          {
            icon: 'bikeMenu',
            ...unknownLabelHandler(
              data?.employee?.employeeBikeHabit?.name,
              t(
                `shared.employeeBikeHabitLabel.${data?.employee?.employeeBikeHabit?.name}`,
              ),
            ),
          },
          {
            icon: 'lock',
            ...unknownLabelHandler(
              data.employee?.bikeParkingType?.name,
              t(
                `shared.bikeParkingLabel.${data.employee?.bikeParkingType?.name}`,
              ),
            ),
          },
        ],
        onEditClicked: () =>
          openHabitsModal(AnchorEditHabitType.USER_HABIT_WORK_TRAVEL),
        editIconColor:
          !data.employee?.travelMode?.name ||
          !data.employee?.workTravelDistance ||
          !data.employee?.workTravelDuration ||
          !data.employee?.employeeBikeHabit?.name ||
          !data.employee?.bikeParkingType?.name
            ? theme.colors.error
            : theme.colors.gray1,
      },
    ],
    [
      data,
      t,
      unknownLabelHandler,
      openProfileModal,
      openHabitsModal,
      theme,
      history,
      cardsInfoData,
    ],
  )

  if (loading && !data) return <Loader />

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      {cardsInfo.map((data, index) => (
        <React.Fragment key={index}>
          <div
            css={{
              width: 'calc(50% - 15px)',
              minWidth: '450px',
              marginBottom: 24,
              [theme.media.mobile]: {
                width: '100%',
                minWidth: 'auto',
              },
            }}
          >
            <CardInfo {...data} />
          </div>
        </React.Fragment>
      ))}
      <div
        css={{
          width: 'calc(50% - 15px)',
          minWidth: '450px',
          marginBottom: 24,
          [theme.media.mobile]: {
            width: '100%',
            minWidth: 'auto',
          },
        }}
      >
        <ModifyPassword />
      </div>
    </div>
  )
}

export default Preview
