/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { useForm, Controller } from 'react-hook-form'
import { constants } from '@goodwatt/shared'
import { useHistory } from 'react-router-dom'

import Button from '../../../components/Button'
import Dropzone from '../../../components/Dropzone'
import Modal from '../../../components/Modal'
import Popup from '../../../components/Popup'
import i18n from '../../../i18n/config'
import { useTheme } from 'emotion-theming'
import { gql, useMutation } from '@apollo/client'

import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import { useNotifications } from '../../../contexts/NotificationContext'
import {
  EmployeeDocumentType,
  UploadIdentityDocumentMutation,
  UploadIdentityDocumentMutationVariables,
} from '../../../__generated__/graphql'

const MUTATION_UPLOAD_IDENTITY_DOCUMENT = gql`
  mutation UploadIdentityDocument(
    $file0: Upload!
    $file1: Upload
    $documentType: employeeDocumentType!
  ) {
    uploadIdentityDocument(
      file0: $file0
      file1: $file1
      documentType: $documentType
    )
  }
`

const schema = yup.object().shape({
  file: yup
    .mixed()
    .test('fileNumbers', i18n.t('forms.errors.fileNumber'), value => {
      if (!value) return true
      return value.length < 3
    })
    .test('fileSize', i18n.t('forms.errors.fileSize'), value => {
      if (!value || value.length === 0) return true
      if (!value || value.length === 1)
        return value[0].size <= constants.MAX_DOCUMENT_SIZE
      return (
        value[0].size <= constants.MAX_DOCUMENT_SIZE &&
        value[1].size <= constants.MAX_DOCUMENT_SIZE
      )
    })
    .test('fileType', i18n.t('forms.errors.fileFormat'), value => {
      if (!value || value.length === 0) return true
      return Object.keys(constants.SUPPORTED_FORMATS_DOCS).includes(
        value[0].type,
      )
    }),
})

interface FormInput {
  file: FileList
}

interface UploadProfileDocumentsProps {
  isOpen: 'ID_CARD' | 'ADDRESS' | null
  closeModal: () => void
  closeModalPrev?: () => void
  refetch: () => void
  onBoardingMode?: boolean
}

const UploadProfileDocuments: React.FC<UploadProfileDocumentsProps> = ({
  isOpen,
  closeModal,
  closeModalPrev,
  refetch,
  onBoardingMode = false,
}) => {
  const theme = useTheme()
  const history = useHistory()
  const notifications = useNotifications()
  const { t } = useTranslation()
  const [uploadDocument, { loading }] = useMutation<
    UploadIdentityDocumentMutation,
    UploadIdentityDocumentMutationVariables
  >(MUTATION_UPLOAD_IDENTITY_DOCUMENT)
  const { handleSubmit, errors, control, trigger, reset } = useForm<FormInput>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const onSubmit = (data: FormInput) => {
    if (data.file && Object.keys(data.file).length)
      uploadDocument({
        variables: {
          file0: data.file[0],
          file1: Object.keys(data.file).length === 2 ? data.file[1] : null,
          documentType: isOpen as EmployeeDocumentType,
        },
      })
        .then(() => {
          refetch()
          reset()
          closeModal()
        })
        .catch(apiErrorCatcher(notifications))
    else closeModal()
  }

  return (
    <Modal isOpen={!!isOpen} onBackgroundClick={closeModal}>
      <form
        css={{ display: 'flex', flex: 1, justifyContent: 'center' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Popup
          footer={
            <Fragment>
              <div css={{ marginRight: 14 }}>
                {onBoardingMode ? (
                  <Button
                    type="tertiary"
                    onClick={closeModalPrev}
                    icon="back"
                    iconColor={theme.colors.blue1}
                  />
                ) : (
                  <Button
                    disabled={loading}
                    onClick={() => closeModal()}
                    type="tertiary"
                  >
                    {t('shared.button.cancel')}
                  </Button>
                )}
              </div>

              {onBoardingMode && (
                <Button
                  disabled={loading}
                  onClick={() => history.push('/employee/dashboard')}
                  type="tertiary"
                >
                  {t('forms.button.quit')}
                </Button>
              )}

              <Button
                submit
                type="primary"
                loading={loading}
                onClick={handleSubmit(onSubmit)}
              >
                {onBoardingMode
                  ? t('forms.button.next')
                  : t('shared.button.confirm')}
              </Button>
            </Fragment>
          }
          title={
            isOpen === 'ID_CARD'
              ? t('employee.documents.uploadModal.idCardTitle')
              : isOpen === 'ADDRESS'
              ? t('employee.documents.uploadModal.proofOfAddressTitle')
              : ''
          }
          closable
          onClose={closeModal}
        >
          <p
            css={{
              fontSize: '1.6rem',
              lineHeight: '20px',
              textAlign: 'center',
              marginBottom: '16px',
            }}
            dangerouslySetInnerHTML={{
              __html:
                isOpen === 'ID_CARD'
                  ? t('employee.documents.uploadModal.idCardDesc')
                  : isOpen === 'ADDRESS'
                  ? t('employee.documents.uploadModal.proofOfAddressDesc')
                  : '',
            }}
          />
          <Controller
            name="file"
            control={control}
            defaultValue={null}
            render={({ onChange, value }) => {
              return (
                <Dropzone
                  file={value}
                  multiple={isOpen === 'ID_CARD' ? true : false}
                  onChange={async file => {
                    onChange(file)
                    await trigger('file')
                  }}
                  error={!!errors?.file}
                  helperText={errors?.file?.message}
                />
              )
            }}
          />
        </Popup>
      </form>
    </Modal>
  )
}

export default UploadProfileDocuments
