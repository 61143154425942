/** @jsx jsx */
import { jsx } from '@emotion/core'
import { gql, useMutation } from '@apollo/client'
import { useTheme } from 'emotion-theming'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../components/Button'
import Modal from '../../../../../components/Modal'
import Popup from '../../../../../components/Popup'

import {
  NotificationTypes,
  useNotifications,
} from '../../../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../../../utils/apiErrorCatcher'
import { employeeNameFormatter } from '../../../../../utils/formatter'
import { GetEmployeeQuery } from '../../../../../__generated__/graphql'

const MUTATION_UNSELECT_EMPLOYEE_FROM_PROGRAM = gql`
  mutation UnselectEmployeeFromProgram($employeeId: String!) {
    updateSelectLoanEmployee(employeeId: $employeeId, selectEmployee: false) {
      id
    }
  }
`

interface WithdrawalProps {
  isOpen: boolean
  closeModal: () => void
  employee: GetEmployeeQuery['employee'] | null
  refetchTables: () => void
}

const WithdrawalModal: React.FC<WithdrawalProps> = ({
  isOpen,
  closeModal,
  employee,
  refetchTables,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [unselectEmployeeFromProgram, { loading }] = useMutation(
    MUTATION_UNSELECT_EMPLOYEE_FROM_PROGRAM,
  )
  const notifications = useNotifications()

  const employeeName = React.useMemo(
    () => employeeNameFormatter(employee?.firstName, employee?.lastName),
    [employee],
  )

  const onSubmit = () => {
    unselectEmployeeFromProgram({
      variables: {
        employeeId: employee?.id,
      },
    })
      .then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t(
            'animator.company.employees.selected.modals.employeeProgramWithdrawal.successNotification',
            { name: employeeName },
          ),
        })
        return refetchTables()
      })
      .then(() => closeModal())
      .catch(apiErrorCatcher(notifications))
  }

  return (
    <Modal isOpen={isOpen} onBackgroundClick={closeModal}>
      <Popup
        closable={false}
        title={t(
          'animator.company.employees.selected.modals.employeeProgramWithdrawal.title',
        )}
        titleColor={theme.colors.primary}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <span
            css={{ marginBottom: 25 }}
            dangerouslySetInnerHTML={{
              __html: t(
                'animator.company.employees.selected.modals.employeeProgramWithdrawal.description',
                { employeeName },
              ),
            }}
          />
          <span
            css={{ marginBottom: 35 }}
            dangerouslySetInnerHTML={{
              __html: t(
                'animator.company.employees.selected.modals.employeeProgramWithdrawal.confirm',
              ),
            }}
          />
        </div>
        <div
          css={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button type="tertiary" onClick={closeModal}>
            {t('forms.button.cancel')}
          </Button>
          <div css={{ marginRight: 40 }} />
          <Button loading={loading} type="primary" onClick={onSubmit}>
            {t('forms.button.confirm')}
          </Button>
        </div>
      </Popup>
    </Modal>
  )
}

export default WithdrawalModal
