import {
  EmployeeDeployment,
  EmployeeDeploymentStatus,
} from '../../../../__generated__/graphql'
import React from 'react'
import Checkbox from '../../../../components/Checkbox'
import theme from '../../../../styles/theme'
import { useUpdateEmployeeDeployment } from '../hooks/useUpdateEmployeeDeployment'
import { useSelectLoanEmployeeDeployment } from '../hooks/useSelectEmployeeDeployment'

export interface ToggleEmployeeDeploymentStatusButtonProps {
  employeeDeployment: Pick<EmployeeDeployment, 'id' | 'status'>
  disabled?: boolean
}

export const ToggleEmployeeDeploymentStatusButton: React.FC<
  ToggleEmployeeDeploymentStatusButtonProps
> = ({ employeeDeployment, disabled }) => {
  const isChecked =
    employeeDeployment.status !== EmployeeDeploymentStatus.Candidate
  const [mutate] = useUpdateEmployeeDeployment()
  const [selectLoan] = useSelectLoanEmployeeDeployment()

  return (
    <Checkbox
      onClick={async () => {
        if (disabled) return

        await Promise.all([
          mutate({
            variables: {
              id: employeeDeployment.id,
              data: {
                status: isChecked
                  ? EmployeeDeploymentStatus.Candidate
                  : EmployeeDeploymentStatus.Selected,
              },
            },
          }),
          selectLoan({
            variables: {
              employeeDeploymentId: employeeDeployment.id,
              selectBool: !isChecked,
            },
          }),
        ])
      }}
      isChecked={isChecked}
      color={disabled && !isChecked ? theme.colors.gray4 : theme.colors.success}
    />
  )
}
