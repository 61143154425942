/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useNotifications } from '../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import Modal from '../../../components/Modal'
import Popup from '../../../components/Popup'
import Button from '../../../components/Button'
import {
  GetCompanyReferrerQuery,
  GetCompanyReferrerQueryVariables,
  DeleteCompanyReferrerMutation,
  DeleteCompanyReferrerMutationVariables,
} from '../../../__generated__/graphql'

import { MUTATION_DELETE_COMPANY_REFERRER } from '../../../utils/gql/mutations'

interface DeleteReferrerModalProps {
  referrers: GetCompanyReferrerQuery['companyReferrer'][] | null | undefined
  referrer: GetCompanyReferrerQuery['companyReferrer'] | null | undefined
  refetch: (newData?: Partial<GetCompanyReferrerQueryVariables>) => void
  onModalClose: () => void
  submitCloseModal: () => void
  isOpen?: boolean
}

const DeleteReferrerModal: React.FC<DeleteReferrerModalProps> = ({
  referrer,
  referrers,
  refetch,
  onModalClose,
  submitCloseModal,
  isOpen = true,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()

  const [deleteReferrer, { loading }] = useMutation<
    DeleteCompanyReferrerMutation,
    DeleteCompanyReferrerMutationVariables
  >(MUTATION_DELETE_COMPANY_REFERRER, {
    refetchQueries: ['GetCompanyAllReferrer'],
  })

  const referrersWithoutCurrentReferrer = referrers!.filter(
    ref => ref?.id !== referrer?.id,
  )
  const canDelete =
    referrersWithoutCurrentReferrer!.length > 0 && referrer !== null

  const handleSubmit = async () => {
    if (!referrer) {
      return
    }

    try {
      await deleteReferrer({
        variables: {
          deleteCompanyReferrerId: referrer.id,
        },
      })

      refetch()
      submitCloseModal()
    } catch (error) {
      apiErrorCatcher(notifications)
    }
  }

  return (
    <Modal isOpen={isOpen} onBackgroundClick={onModalClose}>
      <Popup
        maxWidth={750}
        title={t('animator.companies.profile.deleteReferrer')}
        onClose={onModalClose}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <div css={{ marginRight: 40 }} />

            <Button type="tertiary" onClick={onModalClose}>
              {t('forms.button.cancel')}
            </Button>

            {canDelete && (
              <Button loading={loading} type="primary" onClick={handleSubmit}>
                {t('forms.button.confirm')}
              </Button>
            )}
          </div>
        }
      >
        {referrersWithoutCurrentReferrer!.length === 0 ? (
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {t('animator.companies.profile.deleteReferrerNoReferrers', {
              firstName: referrer?.firstName,
              lastName: referrer?.lastName,
            })}
          </div>
        ) : (
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {t('animator.companies.profile.deleteReferrerConfirmationMessage', {
              firstName: referrer?.firstName,
              lastName: referrer?.lastName,
            })}
          </div>
        )}
      </Popup>
    </Modal>
  )
}

export default DeleteReferrerModal
