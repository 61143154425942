/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux/rootReducer'
import CompanyProfile from '../../Shared/CompanyProfile'
import ModifyPassword from './ModifyPassword'

const Profile: React.FC = () => {
  const userId = useSelector((state: StoreState) => state.user.id)

  return (
    <CompanyProfile renderExtra={() => <ModifyPassword />} userId={userId} />
  )
}

export default Profile
