/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useTheme } from 'emotion-theming'
import Modal from '../../../../../components/Modal'
import Button from '../../../../../components/Button'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../../contexts/NotificationContext'
import { TableTypes } from '../TableRefetchContext'
import apiErrorCatcher from '../../../../../utils/apiErrorCatcher'
import i18n from '../../../../../i18n/config'
import { PendingCompaniesTableRow } from '../../../../../types/AnimatorCompanies'
import Popup from '../../../../../components/Popup'
import {
  AcceptCompanyMutation,
  AcceptCompanyMutationVariables,
} from '../../../../../__generated__/graphql'

const MUTATION_ACCEPT_COMPANY = gql`
  mutation AcceptCompany($companyId: String!) {
    acceptCompany(companyId: $companyId)
  }
`

interface ApproveModalProps {
  isOpen: boolean
  closeModal: (tablesToRefetch?: TableTypes[]) => void
  company: PendingCompaniesTableRow
}

const ApproveModal: React.FC<ApproveModalProps> = ({
  isOpen,
  closeModal,
  company,
}) => {
  const theme = useTheme()

  const [acceptCompany, { loading }] = useMutation<
    AcceptCompanyMutation,
    AcceptCompanyMutationVariables
  >(MUTATION_ACCEPT_COMPANY, {
    refetchQueries: [
      'companiesWithDeployments',
      'AnimatorGetCompaniesInActivityCount',
    ],
  })
  const notifications = useNotifications()

  const onSubmit = React.useCallback(() => {
    acceptCompany({
      variables: {
        companyId: company.id || '',
      },
    })
      .then(() => {
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: i18n.t(
            'animator.companies.acceptCompany.successNotification',
            { companyName: company.name },
          ),
        })
        closeModal([TableTypes.PENDING, TableTypes.ACCEPTED])
      })
      .catch(apiErrorCatcher(notifications))
  }, [acceptCompany, closeModal, company, notifications])

  return (
    <Modal isOpen={isOpen} onBackgroundClick={closeModal}>
      <Popup
        titleColor={theme.colors.success}
        title={i18n.t('animator.companies.acceptCompany.title')}
        closable
        overflowY="visible"
        maxWidth={750}
        onClose={closeModal as () => void}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button
                disabled={loading}
                onClick={() => closeModal()}
                type="tertiarySuccess"
              >
                {i18n.t('shared.button.cancel')}
              </Button>
            </div>
            <Button
              submit
              type="primarySuccess"
              loading={loading}
              onClick={onSubmit}
            >
              {i18n.t('shared.button.confirm')}
            </Button>
          </Fragment>
        }
      >
        <form onSubmit={onSubmit}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span
              css={{
                textAlign: 'center',
                marginBottom: 40,
              }}
            >
              {i18n.t('animator.companies.acceptCompany.warning')}
            </span>

            <span
              css={{ textAlign: 'center' }}
              dangerouslySetInnerHTML={{
                __html: i18n.t(
                  'animator.companies.acceptCompany.confirmation',
                  {
                    companyName: company.name,
                    companyCity: company.municipality.city,
                  },
                ),
              }}
            />
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default ApproveModal
