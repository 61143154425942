/** @jsx jsx */
import { gql, useQuery } from '@apollo/client'
import { jsx } from '@emotion/core'
import React, { useState } from 'react'
import { format } from 'date-fns'

import ModalConfirmation from './ModalConfirmation'
import Loader from '../../../../../../components/Loader'
import Modal from '../../../../../../components/Modal'
import Popup from '../../../../../../components/Popup'
import DocumentLine, { DocumentLineStateType } from './DocumentLine'

import apiErrorCatcher from '../../../../../../utils/apiErrorCatcher'
import { useNotifications } from '../../../../../../contexts/NotificationContext'
import { useTranslation } from 'react-i18next'
import usePromiseLazyQuery from '../../../../../../hooks/usePromiseLazyQuery'

import {
  QUERY_IDENTITY_CARD_BACK_DOCUMENT,
  QUERY_IDENTITY_CARD_FRONT_DOCUMENT,
  QUERY_PROOF_OF_ADDRESS_DOCUMENT,
} from '../../../../../../utils/gql/queries'

import { employeeNameFormatter } from '../../../../../../utils/formatter'
import {
  DeploymentType,
  DocumentTypes,
  DocumentsSetOptions,
  GetEmployeeDocumentsQuery,
  GetEmployeeDocumentsQueryVariables,
  GetEmployeeIdentityCardBackQuery,
  GetEmployeeIdentityCardBackQueryVariables,
  GetEmployeeIdentityCardFrontQuery,
  GetEmployeeIdentityCardFrontQueryVariables,
  GetEmployeeProofOfAddressQuery,
  GetEmployeeProofOfAddressQueryVariables,
} from '../../../../../../__generated__/graphql'

const QUERY_EMPLOYEE_DOCUMENTS = gql`
  query GetEmployeeDocuments($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      id
      firstName
      lastName
      identityCardFrontFilePath
      identityCardBackFilePath
      identityCardVerified
      proofOfAddressFilePath
      proofOfAddressVerified
      depositId
      depositExpirationDate
      depositByCheque
      depositReleasedAt
      bikeLoan {
        bikeReturned
        bike {
          id
        }
      }
    }
  }
`

interface ConfirmationModalState {
  isOpen: boolean
  type: null | DocumentTypes
  set: null | DocumentsSetOptions
}

const confirmationInitState: ConfirmationModalState = {
  isOpen: false,
  type: null,
  set: null,
}

interface EmployeeDocumentsModalProps {
  isOpen: boolean
  closeModal: () => void
  employeeId: string
  deploymentType: DeploymentType | null | undefined
  refetchTable?: () => void
}

const EmployeeDocumentsModal: React.FC<EmployeeDocumentsModalProps> = ({
  isOpen,
  closeModal,
  employeeId,
  deploymentType,
  refetchTable,
}) => {
  const { t } = useTranslation()
  const notifications = useNotifications()
  const [confirmationModal, setConfirmationModal] =
    useState<ConfirmationModalState>(confirmationInitState)
  const { data, loading, refetch } = useQuery<
    GetEmployeeDocumentsQuery,
    GetEmployeeDocumentsQueryVariables
  >(QUERY_EMPLOYEE_DOCUMENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: { id: employeeId },
    },
  })
  const queryGetProofOfAddress = usePromiseLazyQuery<
    GetEmployeeProofOfAddressQuery,
    GetEmployeeProofOfAddressQueryVariables
  >(QUERY_PROOF_OF_ADDRESS_DOCUMENT)
  const queryGetIdentityFront = usePromiseLazyQuery<
    GetEmployeeIdentityCardFrontQuery,
    GetEmployeeIdentityCardFrontQueryVariables
  >(QUERY_IDENTITY_CARD_FRONT_DOCUMENT)
  const queryGetIdentityBack = usePromiseLazyQuery<
    GetEmployeeIdentityCardBackQuery,
    GetEmployeeIdentityCardBackQueryVariables
  >(QUERY_IDENTITY_CARD_BACK_DOCUMENT)

  const getProofOfAddress = () => {
    queryGetProofOfAddress({
      where: { id: employeeId },
    })
      .then(r => {
        if (r.data?.employee?.getPresignedProofOfAddressDocument)
          window.open(
            r.data?.employee?.getPresignedProofOfAddressDocument,
            '_blank',
          )
      })
      .catch(apiErrorCatcher(notifications))
  }

  const getIdentityCardFront = async () => {
    if (data?.employee?.identityCardFrontFilePath) {
      const result = await queryGetIdentityFront({ where: { id: employeeId } })
      const doc = result.data?.employee?.getPresignedIdentityCardFrontDocument
      if (doc) window.open(doc, '_blank')
    }
  }

  const getIdentityCardBack = async () => {
    if (data?.employee?.identityCardBackFilePath) {
      const result = await queryGetIdentityBack({ where: { id: employeeId } })
      const doc = result.data?.employee?.getPresignedIdentityCardBackDocument
      if (doc) window.open(doc, '_blank')
    }
  }

  const identityState = (): DocumentLineStateType => {
    if (!data?.employee?.identityCardFrontFilePath) return 'NEED_TO_BE_UPLOADED'
    if (data.employee.identityCardVerified) return 'VERIFIED'
    if (data.employee.identityCardVerified === false) return 'REFUSED'
    if (data.employee.identityCardVerified === null) return null
    return null
  }

  const proofOfAddressState = (): DocumentLineStateType => {
    if (!data?.employee?.proofOfAddressFilePath) return 'NEED_TO_BE_UPLOADED'
    if (data.employee.proofOfAddressVerified) return 'VERIFIED'
    if (data.employee.proofOfAddressVerified === false) return 'REFUSED'
    if (data.employee.proofOfAddressVerified === null) return null
    return null
  }

  const depositState = (): DocumentLineStateType => {
    //if (data?.employee?.depositReleasedAt) return 'NEED_TO_BE_UPLOADED'
    if (data?.employee?.depositByCheque) return 'VERIFIED'
    if (!data?.employee?.depositId) return 'REFUSED'
    return 'NEED_TO_BE_UPLOADED'
  }

  const depositDescription = (): string => {
    // if (data?.employee?.depositReleasedAt)
    //   return t(
    //     'animator.company.employees.selected.documents.unavailableDeposit',
    //   )

    if (
      data?.employee?.depositId &&
      data.employee.depositExpirationDate &&
      new Date() < new Date(data.employee.depositExpirationDate)
    )
      return t('animator.company.employees.selected.documents.successDeposit', {
        ref: data?.employee?.depositId,
        date: format(
          new Date(data.employee.depositExpirationDate),
          'dd/MM/yyyy',
        ),
      })
    if (
      data?.employee?.depositId &&
      data.employee.depositExpirationDate &&
      new Date() >= new Date(data.employee.depositExpirationDate)
    )
      return t('animator.company.employees.selected.documents.expiredDeposit') // The deposit in that case here has expired

    if (data?.employee?.depositByCheque)
      return t(
        'animator.company.employees.selected.documents.unavailableByCheque',
      )

    if (!data?.employee?.depositId)
      return t(
        'animator.company.employees.selected.documents.unavailableDeposit',
      )
    return ''
  }

  const bikeLoanWithBike = data?.employee?.bikeLoan.filter(
    x => x.bikeReturned === false,
  )

  const employeeHasBike = bikeLoanWithBike && bikeLoanWithBike.length > 0

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onBackgroundClick={closeModal}>
        <Popup
          closable={false}
          title={`${t('animator.company.employees.selected.documents.title')}${
            data?.employee
              ? ` - ${employeeNameFormatter(
                  data.employee.firstName,
                  data.employee.lastName,
                )}`
              : ''
          }`}
        >
          {loading ? (
            <Loader />
          ) : (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                padding: '12px 12px 0px 12px',
              }}
            >
              <DocumentLine
                label={t(
                  'animator.company.employees.selected.documents.identity',
                )}
                state={identityState()}
                onDownloadFront={
                  data?.employee?.identityCardFrontFilePath
                    ? getIdentityCardFront
                    : undefined
                }
                onDownloadBack={
                  data?.employee?.identityCardBackFilePath
                    ? getIdentityCardBack
                    : undefined
                }
                onVerified={() =>
                  setConfirmationModal({
                    isOpen: true,
                    type: DocumentTypes.IdentityCard,
                    set: DocumentsSetOptions.Verified,
                  })
                }
                onRejected={() =>
                  setConfirmationModal({
                    isOpen: true,
                    type: DocumentTypes.IdentityCard,
                    set: DocumentsSetOptions.Rejected,
                  })
                }
              />
              <DocumentLine
                label={t(
                  'animator.company.employees.selected.documents.proofOfAddress',
                )}
                state={proofOfAddressState()}
                onDownload={
                  data?.employee?.proofOfAddressFilePath
                    ? getProofOfAddress
                    : undefined
                }
                onVerified={() =>
                  setConfirmationModal({
                    isOpen: true,
                    type: DocumentTypes.ProofOfAddress,
                    set: DocumentsSetOptions.Verified,
                  })
                }
                onRejected={() =>
                  setConfirmationModal({
                    isOpen: true,
                    type: DocumentTypes.ProofOfAddress,
                    set: DocumentsSetOptions.Rejected,
                  })
                }
              />
              {deploymentType !== DeploymentType.Rental && (
                <DocumentLine
                  label={t(
                    'animator.company.employees.selected.documents.deposit',
                  )}
                  state={depositState()}
                  onVerified={() =>
                    setConfirmationModal({
                      isOpen: true,
                      type: DocumentTypes.Deposit,
                      set: DocumentsSetOptions.Verified,
                    })
                  }
                  onRejected={
                    employeeHasBike
                      ? null
                      : () =>
                          setConfirmationModal({
                            isOpen: true,
                            type: DocumentTypes.Deposit,
                            set: DocumentsSetOptions.Rejected,
                          })
                  }
                  description={depositDescription()}
                />
              )}
            </div>
          )}
        </Popup>
      </Modal>
      {confirmationModal.isOpen && (
        <ModalConfirmation
          isOpen={confirmationModal.isOpen}
          closeModal={() => setConfirmationModal(confirmationInitState)}
          employeeId={employeeId}
          type={confirmationModal.type as DocumentTypes}
          set={confirmationModal.set as DocumentsSetOptions}
          refetchModal={refetch}
          refetchTable={refetchTable}
          name={
            data?.employee
              ? employeeNameFormatter(
                  data.employee.firstName,
                  data.employee.lastName,
                )
              : ''
          }
        />
      )}
    </React.Fragment>
  )
}

export default EmployeeDocumentsModal
