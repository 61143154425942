/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { useForm, Controller } from 'react-hook-form'
import { constants } from '@goodwatt/shared'

import Button from '../../../components/Button'
import Dropzone from '../../../components/Dropzone'
import Input from '../../../components/Input'
import InputSelect from '../../../components/InputSelect'
import Modal from '../../../components/Modal'
import Popup from '../../../components/Popup'
import i18n from '../../../i18n/config'

import {
  NotificationTypes,
  useNotifications,
} from '../../../contexts/NotificationContext'
import apiErrorCatcher from '../../../utils/apiErrorCatcher'
import { FetchResult } from '@apollo/client'
import { employeeNameFormatter } from '../../../utils/formatter'
import {
  AccessibilityAnimator,
  AccessibilityCompany,
  GetSelectedEmployeesQuery,
  UploadDocumentByAnimatorMutation,
  UploadDocumentByCompanyMutation,
} from '../../../__generated__/graphql'

const schema = yup.object().shape({
  file: yup
    .mixed()
    .required(i18n.t('forms.errors.required'))
    .test('fileSize', i18n.t('forms.errors.fileSize'), value => {
      if (!value || value.length === 0) return true
      return value[0].size <= constants.MAX_DOCUMENT_SIZE
    })
    .test('fileType', i18n.t('forms.errors.fileFormat'), value => {
      if (!value || value.length === 0) return true
      return Object.keys(constants.SUPPORTED_FORMATS_DOCS).includes(
        value[0].type,
      )
    }),
  filename: yup.string().required(i18n.t('forms.errors.required')),
  accessibility: yup.object().shape({
    value: yup.string().required(i18n.t('forms.errors.required')),
  }),
})

interface UploadDocumentModal {
  modalIsOpen: boolean
  closeModal: () => void
  companyId?: string
  refetch: () => void
  onUpload: ({
    title,
    file,
    accessibility,
  }: {
    title: string
    file: File
    accessibility: AccessibilityAnimator | AccessibilityCompany
    employeeId?: string
  }) => Promise<
    FetchResult<
      UploadDocumentByCompanyMutation | UploadDocumentByAnimatorMutation,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>
    >
  >
  accessibilitySelectOptions: { label: string; value: string }[]
  loading?: boolean
  employees?:
    | GetSelectedEmployeesQuery['selectedCompanyEmployees']
    | null
    | undefined
}

interface FormInput {
  filename: string
  accessibility: {
    value: Partial<AccessibilityAnimator | AccessibilityCompany>
    label: string
  }
  file: FileList
  employee?: {
    value: string
    label: string
  }
}

const UploadDocumentModal: React.FC<UploadDocumentModal> = ({
  modalIsOpen,
  closeModal,
  refetch,
  onUpload,
  loading = false,
  accessibilitySelectOptions,
  employees,
}) => {
  const notifications = useNotifications()
  const { t } = useTranslation()

  const { register, handleSubmit, errors, control, trigger, reset, watch } =
    useForm<FormInput>({
      resolver: yupResolver(schema),
      mode: 'onBlur',
    })

  const onSubmit = (data: FormInput) => {
    onUpload({
      title: data.filename,
      file: data.file[0],
      accessibility: data.accessibility.value,
      employeeId: data.employee?.value,
    })
      .then(() => {
        refetch()
        reset()
        closeModal()
        notifications.newNotification({
          type: NotificationTypes.SUCCESS,
          message: t('shared.documents.notifications.docAdded', {
            filename: data.filename,
          }),
        })
      })
      .catch(apiErrorCatcher(notifications))
  }

  const employeeOption = employees
    ? employees.map(employee => ({
        label: employeeNameFormatter(employee?.firstName, employee?.lastName),
        value: employee?.id,
      }))
    : []

  const documentAccessibility = watch('accessibility')

  return (
    <Modal isOpen={modalIsOpen} onBackgroundClick={closeModal}>
      <form
        css={{ display: 'flex', flex: 1, justifyContent: 'center' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Popup
          overflowY="visible"
          title={t('shared.documents.docModal.title')}
          closable
          maxWidth={750}
          onClose={closeModal}
          footer={
            <Fragment>
              <Button type="tertiary" onClick={closeModal} disabled={loading}>
                {t('forms.button.cancel')}
              </Button>
              <div css={{ marginLeft: '14px' }}>
                <Button submit loading={loading}>
                  {t('forms.button.confirm')}
                </Button>
              </div>
            </Fragment>
          }
        >
          <Controller
            name="file"
            control={control}
            defaultValue={null}
            render={({ onChange, value }) => {
              return (
                <Dropzone
                  file={value}
                  onChange={async file => {
                    onChange(file)
                    await trigger('dropzone')
                  }}
                  error={!!errors?.file}
                  helperText={errors?.file?.message}
                />
              )
            }}
          />
          <div css={{ marginBottom: 14 }} />
          <Input
            type="text"
            label={t('shared.documents.docModal.filenameLabel')}
            register={register}
            name="filename"
            error={!!errors.filename}
            helperText={errors?.filename?.message}
            defaultValue=""
          />
          <Controller
            name="accessibility"
            control={control}
            defaultValue={{ label: '', value: '' }}
            render={({ onChange, onBlur, value, name }) => {
              return (
                <InputSelect
                  value={value}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={!!errors.accessibility}
                  helperText={errors?.accessibility?.value?.message}
                  label={t('shared.documents.docModal.accessibility')}
                  placeholder={t('shared.placeholder.inputSelect')}
                  options={accessibilitySelectOptions}
                />
              )
            }}
          />
          {documentAccessibility?.value === AccessibilityAnimator.Employee && (
            <Controller
              name="employee"
              control={control}
              defaultValue={{ label: '', value: '' }}
              render={({ onChange, onBlur, value, name }) => {
                return (
                  <InputSelect
                    value={value}
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={!!errors.accessibility}
                    helperText={errors?.accessibility?.value?.message}
                    label={t('shared.documents.docModal.employee')}
                    placeholder={t('shared.placeholder.inputSelect')}
                    options={employeeOption}
                  />
                )
              }}
            />
          )}
        </Popup>
      </form>
    </Modal>
  )
}

export default UploadDocumentModal
