/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Controller, useForm } from 'react-hook-form'

import theme from '../../../../../styles/theme'

import Modal from '@goodwatt/client/src/components/Modal'
import Popup from '@goodwatt/client/src/components/Popup'
import Button from '@goodwatt/client/src/components/Button'
import Input from '@goodwatt/client/src/components/Input'
import DateTime from '@goodwatt/client/src/components/DateTime'

import { employeeNameFormatter } from '@goodwatt/client/src/utils/formatter'

import {
  NotificationTypes,
  useNotifications,
} from '@goodwatt/client/src/contexts/NotificationContext'

import i18n from '@goodwatt/client/src/i18n/config'
import apiErrorCatcher from '@goodwatt/client/src/utils/apiErrorCatcher'
import { MUTATION_UPDATE_EMPLOYEE_APPOINTMENT } from '@goodwatt/client/src/utils/gql/mutations'

import { useTheme } from 'emotion-theming'

import { parseISO, roundToNearestMinutes } from 'date-fns'
import {
  AppointmentCategory,
  Employee,
  EmployeeAppointmentInput,
  UpdateEmployeeAppointmentMutation,
  UpdateEmployeeAppointmentMutationVariables,
} from '../../../../../__generated__/graphql'

type EmployeeInput = {
  [KeyT in keyof Omit<
    Employee,
    '__typename' | 'absences' | 'babySeat' | 'companyId' | 'specialBikeType'
  >]?: Employee[KeyT] | null
} & {
  bikeStickerId?: number | null
}

interface AppointmentModalProps {
  employee: EmployeeInput | undefined | null
  deploymentId: string
  employeeDeploymentId: string
  onClose: () => void
  submitCloseModal: () => void
  refetch?: (newData?: Partial<Employee>) => void
  isOpen?: boolean
}

interface EditAppointmentForm {
  loanDeliveryAppointment: Date
  loanReturnAppointment: Date
  loanDeliveryAppointmentLocation: string
  loanReturnAppointmentLocation: string
}

const AppointmentModal: React.FC<AppointmentModalProps> = ({
  employee,
  deploymentId,
  employeeDeploymentId,
  onClose,
  submitCloseModal,
  refetch,
  isOpen = true,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const notifications = useNotifications()
  const { register, handleSubmit, control } = useForm({
    // resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [updateAppointment, { loading }] = useMutation<
    UpdateEmployeeAppointmentMutation,
    UpdateEmployeeAppointmentMutationVariables
  >(MUTATION_UPDATE_EMPLOYEE_APPOINTMENT)

  const bikeLoan = employee?.bikeLoan?.find(
    bikeLoan => bikeLoan.employeeDeploymentId === employeeDeploymentId,
  )

  let employeeHasBike = false

  if (bikeLoan) {
    employeeHasBike = bikeLoan?.bike?.stickerId !== null
  }

  const loanDeliveryAppointment = employee?.loanDeliveryAppointment?.find(
    appointment => appointment?.deploymentId === deploymentId,
  )

  const loanReturnAppointment = employee?.loanReturnAppointment?.find(
    appointment => appointment?.deploymentId === deploymentId,
  )

  let employeeHasReceivedBike = false
  let employeeHasReturnedBike = false
  let effectiveDeliveredBikeDate: Date | null = null
  let effectiveReturnedBikeDate: Date | null = null

  if (bikeLoan) {
    employeeHasReceivedBike = bikeLoan?.bikeDeliveredDate !== null
    employeeHasReturnedBike = bikeLoan?.bikeReturnedDate !== null
    effectiveDeliveredBikeDate = employeeHasReceivedBike
      ? roundToNearestMinutes(parseISO(bikeLoan?.bikeDeliveredDate), {
          nearestTo: 5,
        })
      : null
    effectiveReturnedBikeDate = employeeHasReturnedBike
      ? roundToNearestMinutes(parseISO(bikeLoan?.bikeReturnedDate), {
          nearestTo: 5,
        })
      : null
  }

  const onSubmit = (inputData: EditAppointmentForm) => {
    if (!employee || !employee.id) return

    const appointment: EmployeeAppointmentInput[] = []

    if (inputData.loanReturnAppointment) {
      // update loanReturnAppointment
      const newReturnDate = inputData.loanReturnAppointment.getTime()
      const currentReturnDate = new Date(loanReturnAppointment?.date).getTime()
      if (
        newReturnDate !== currentReturnDate ||
        inputData.loanReturnAppointmentLocation !==
          loanReturnAppointment?.location
      ) {
        appointment.push({
          date: inputData.loanReturnAppointment,
          location: inputData.loanReturnAppointmentLocation,
          category: AppointmentCategory.LoanReturn,
        })
      }
    }

    if (!employeeHasBike && inputData.loanDeliveryAppointment) {
      // update loanDeliveryAppointment
      const newLoanDate = inputData.loanDeliveryAppointment.getTime()
      const currentLoanDate = new Date(loanDeliveryAppointment?.date).getTime()
      if (
        newLoanDate !== currentLoanDate ||
        inputData.loanDeliveryAppointmentLocation !==
          loanDeliveryAppointment?.location
      ) {
        appointment.push({
          date: inputData.loanDeliveryAppointment,
          location: inputData.loanDeliveryAppointmentLocation,
          category: AppointmentCategory.LoanDelivery,
        })
      }
    }

    if (!appointment.length) return

    updateAppointment({
      refetchQueries: ['GetDeploymentEmployees'],
      variables: {
        employeeId: employee.id,
        deploymentId,
        data: { appointment },
      },
    })
      .then(result => {
        if (result.data?.updateEmployeeAppointment) {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: i18n.t('shared.notification.successUpdate'),
          })
          refetch?.()
          submitCloseModal()
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  const loanDate = loanDeliveryAppointment?.date
    ? new Date(loanDeliveryAppointment?.date)
    : null

  const loanReturnDate = loanReturnAppointment?.date
    ? new Date(loanReturnAppointment?.date)
    : null

  return (
    <Modal isOpen={isOpen} onBackgroundClick={onClose}>
      <Popup
        maxWidth={750}
        title={t('animator.company.updateAppointmentModal.title', {
          name: employeeNameFormatter(employee?.firstName, employee?.lastName),
        })}
        onClose={onClose}
        footer={
          <div
            css={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              //marginLeft: '-40px',
            }}
          >
            <Button type="tertiary" onClick={onClose}>
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginRight: 40 }} />
            <Button
              loading={loading}
              type="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {t('forms.button.save')}
            </Button>
          </div>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            css={{
              width: '100%',
              padding: '0 12px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              [theme.media.mobile]: {
                padding: '0',
              },
            }}
          >
            <div>
              <span
                css={{
                  textTransform: 'capitalize',
                }}
              >
                {t('shared.resources.employee')} :
              </span>
              <span
                css={{
                  fontWeight: 'bold',
                  marginLeft: 6,
                }}
              >
                {employeeNameFormatter(employee?.firstName, employee?.lastName)}
              </span>
            </div>

            <div css={{ marginBottom: 24 }} />

            <Controller
              name="loanDeliveryAppointment"
              control={control}
              defaultValue={loanDate}
              render={({ onChange, value }) => {
                return (
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'baseline',
                      width: '100%',
                      gap: 20,
                    }}
                  >
                    <p css={styles.fieldLabel}>
                      {t('animator.company.updateAppointmentModal.loan')}
                    </p>
                    <Input
                      type="text"
                      name="loanDeliveryAppointmentLocation"
                      defaultValue={loanDeliveryAppointment?.location}
                      register={register}
                      disabled={employeeHasBike}
                    />
                    <DateTime
                      block={false}
                      value={
                        employeeHasBike ? effectiveDeliveredBikeDate : value
                      }
                      onChange={onChange}
                      type="dateTime"
                      disabled={employeeHasBike}
                    />
                  </div>
                )
              }}
            />

            <Controller
              name="loanReturnAppointment"
              control={control}
              defaultValue={loanReturnDate}
              render={({ onChange, value }) => {
                return (
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'baseline',
                      width: '100%',
                      gap: 20,
                    }}
                  >
                    <p css={styles.fieldLabel}>
                      {t('animator.company.updateAppointmentModal.loanReturn')}
                    </p>
                    <Input
                      type="text"
                      name="loanReturnAppointmentLocation"
                      defaultValue={loanReturnAppointment?.location}
                      register={register}
                      disabled={employeeHasReturnedBike}
                    />
                    <DateTime
                      block={false}
                      value={
                        employeeHasReturnedBike
                          ? effectiveReturnedBikeDate
                          : value
                      }
                      onChange={onChange}
                      type="dateTime"
                      disabled={employeeHasReturnedBike}
                    />
                  </div>
                )
              }}
            />
            <div css={{ marginBottom: 170 }} />
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default AppointmentModal

const styles = {
  fieldLabel: {
    flexShrink: 0,
    width: 114,
    fontSize: '1.6rem',
    color: theme.colors.gray3,
  },
}
