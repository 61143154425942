/** @jsx jsx */
import { useLazyQuery, useMutation } from '@apollo/client'
import { jsx } from '@emotion/core'
import { useEffect, useState, Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import isEqual from 'lodash/isEqual'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

/** COMPONENTS */
import Button from '../../../../components/Button'
import InputSelect from '../../../../components/InputSelect'
import Modal from '../../../../components/Modal'
import Popup from '../../../../components/Popup'
import Input from '../../../../components/Input'
import DateTime from '@goodwatt/client/src/components/DateTime'

import EditBikeComponentsCondition, {
  ComponentRow,
} from './EditBikeComponentsCondition'
import EditBikeConditionalInputs from './ConditionalInputs'
import IdentifierInputs from './IdentifierInputs'

/** UTILITIES */
import {
  NotificationTypes,
  useNotifications,
} from '../../../../contexts/NotificationContext'
import { StoreState } from '../../../../redux/rootReducer'
import apiErrorCatcher from '../../../../utils/apiErrorCatcher'
import {
  MUTATION_UPDATE_BIKE_INFO,
  MUTATION_DECLAIR_BIKE_IN_REPAIR,
  MUTATION_UDPATE_CURRENT_BIKE_REPAIR,
  MUTATION_RECOVER_BIKE_FROM_REPAIR,
  MUTATION_ADD_DEMONSTRATION_TO_BIKE,
  MUTATION_DELETE_DEMONSTRATION_TO_BIKE,
  MUTATION_ADD_SOLD_TO_BIKE,
  MUTATION_DELETE_SOLD_TO_BIKE,
  MUTATION_ANIMATOR_SET_BIKE_STOLEN,
} from '../../../../utils/gql/mutations'

import {
  QUERY_CURRENT_BIKE_INFO,
  QUERY_BIKE_MODELS_NAME,
  QUERY_ALL_FLEET_NAMES,
  QUERY_GET_BIKE_MODELS,
} from '@goodwatt/client/src/utils/gql/queries'

/** TYPES */
import {
  BikeComponentPartType,
  BikeConditionType,
  StateOfUseType,
  UserRoles,
} from '@goodwatt/shared/dist/types'

import i18n from '../../../../i18n/config'
import {
  AddDemonstrationToBikeMutation,
  AddDemonstrationToBikeMutationVariables,
  AddSoldToBikeMutation,
  AddSoldToBikeMutationVariables,
  AnimatorSetBikeStolenMutation,
  AnimatorSetBikeStolenMutationVariables,
  BikeComponent,
  BikeComponentUpdateWithWhereUniqueWithoutBikeInput,
  BikeSize,
  BikeUpdateInput,
  DeclairBikeInRepairMutation,
  DeclairBikeInRepairMutationVariables,
  DeleteDemonstrationToBikeMutation,
  DeleteDemonstrationToBikeMutationVariables,
  DeleteSoldToBikeMutation,
  DeleteSoldToBikeMutationVariables,
  GetAllFleetNamesQuery,
  GetAllFleetNamesQueryVariables,
  GetBikeModelQuery,
  GetBikeModelsNamesQuery,
  GetBikeQuery,
  GetBikeQueryVariables,
  RecoverBikeFromRepairMutation,
  RecoverBikeFromRepairMutationVariables,
  UpdateBikeCurrentRepairMutation,
  UpdateBikeCurrentRepairMutationVariables,
  UpdateBikeInfoMutation,
  UpdateBikeInfoMutationVariables,
} from '../../../../__generated__/graphql'

type EditBikeInputs = {
  fleet: {
    value: string
    label: string
  }
  bikeCondition: {
    label: string
    value: BikeConditionType
  }
  beforeRepair: {
    repairerLocation: string
    resolvedDate: Date
    note: string
  }
  afterRepair: {
    price: number
    note: string
  }
  bikeModel: {
    label: string
    value: string
  }
  bicycode: string
  morioId: string
  keysId: string

  size: {
    label: BikeSize
    value: BikeSize
  }

  childSeat: boolean
  padlockId: string
  frameId: string

  soldDate: Date
  buyerName: string
  companyName: string
  demoReturnDate: Date
}

export type ComponentCondition = {
  id?: string
  touched: boolean
  note?: string | null
  stateOfUse?: StateOfUseType
}

const initialComponentCondition: ComponentCondition = {
  id: undefined,
  touched: false,
  note: null,
  stateOfUse: undefined,
}

export type EditBikeComponentCondition = {
  [BikeComponentPartType.BIKE_FRAME]: ComponentCondition
  [BikeComponentPartType.BRAKES]: ComponentCondition
  [BikeComponentPartType.BACK_WHEEL]: ComponentCondition
  [BikeComponentPartType.FRONT_WHEEL]: ComponentCondition
  [BikeComponentPartType.TRANSMISSIONS]: ComponentCondition
  [BikeComponentPartType.HANDLEBAR]: ComponentCondition
  [BikeComponentPartType.DEVICES]: ComponentCondition
  [BikeComponentPartType.BATTERY]: ComponentCondition
  [BikeComponentPartType.GLOBAL]: ComponentCondition
}

const initialEditBikeComponentCondition: EditBikeComponentCondition = {
  [BikeComponentPartType.BIKE_FRAME]: initialComponentCondition,
  [BikeComponentPartType.BRAKES]: initialComponentCondition,
  [BikeComponentPartType.BACK_WHEEL]: initialComponentCondition,
  [BikeComponentPartType.FRONT_WHEEL]: initialComponentCondition,
  [BikeComponentPartType.TRANSMISSIONS]: initialComponentCondition,
  [BikeComponentPartType.HANDLEBAR]: initialComponentCondition,
  [BikeComponentPartType.DEVICES]: initialComponentCondition,
  [BikeComponentPartType.BATTERY]: initialComponentCondition,
  [BikeComponentPartType.GLOBAL]: initialComponentCondition,
}

type ConditionalStateForm =
  | 'OTHER'
  | 'START_REPAIR'
  | 'UPDATE_CURRENT_REPAIR'
  | 'END_REPAIR'

interface EditModalProps {
  isOpen: boolean
  closeModal: (shouldRefetch?: boolean) => void
  bikeId?: string
}

const schema = yup.object().shape({
  bicycode: yup
    .string()
    .max(14, ({ max }) =>
      i18n.t('animator.bikes.modal.errors.bicycodeMaxLens', { max }),
    ),
})

const EditModal: React.FC<EditModalProps> = ({
  isOpen,
  closeModal,
  bikeId,
}) => {
  /* ReactStateHooks */
  const [internIsOpen, setInternIsOpen] = useState<boolean>(false)
  const [editedComponents, setEditedComponents] =
    useState<EditBikeComponentCondition>(initialEditBikeComponentCondition)
  const [bike, setBike] = useState<GetBikeQuery['bike'] | null | undefined>()
  const [bikeModels, setBikeModels] = useState<
    GetBikeModelQuery['bikeModels'] | null | undefined
  >()

  const [fleetOptions, setFleetOptions] = useState<
    { label: string; value: string }[]
  >([])
  const [bikeModelOptions, setBikeModelOptions] = useState<
    { label: string; value: string }[]
  >([])

  const { id: userId, role: userRole } = useSelector(
    (state: StoreState) => state.user,
  )

  const resetAllStates = () => {
    setInternIsOpen(false)
    setEditedComponents(initialEditBikeComponentCondition)
    setBike(null)
    setFleetOptions([])
    setBikeModelOptions([])
    setBikeModels([])
    reset()
  }

  /* ApolloHooks */
  const [getFleetList] = useLazyQuery<
    GetAllFleetNamesQuery,
    GetAllFleetNamesQueryVariables
  >(QUERY_ALL_FLEET_NAMES, {
    variables: {
      where:
        userRole === UserRoles.ADMIN
          ? {}
          : { area: { animator: { some: { userId: { equals: userId } } } } },
    },
    onCompleted: data => {
      if (data?.fleets?.length) {
        setFleetOptions(
          data.fleets.map(f => ({ value: f.name, label: f.name })),
        )
      }
    },
    onError: error =>
      apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
    fetchPolicy: 'network-only',
  })

  const [getBikeModelsList] = useLazyQuery<GetBikeModelsNamesQuery>(
    QUERY_BIKE_MODELS_NAME,
    {
      onCompleted: data => {
        if (data?.bikeModels?.length) {
          setBikeModelOptions(
            data.bikeModels.map(x => ({ value: x.name, label: x.name })),
          )
        }
      },
      onError: error =>
        apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
      fetchPolicy: 'network-only',
    },
  )

  const [getBikeInfo] = useLazyQuery<GetBikeQuery, GetBikeQueryVariables>(
    QUERY_CURRENT_BIKE_INFO,
    {
      onCompleted: data => {
        if (data.bike) {
          setBike(data.bike)
          setInternIsOpen(true)
        }
      },
      onError: error =>
        apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
      fetchPolicy: 'network-only',
    },
  )

  const [getBikeModels] = useLazyQuery<GetBikeModelQuery>(
    QUERY_GET_BIKE_MODELS,
    {
      onCompleted: data => {
        if (data?.bikeModels?.length) {
          setBikeModels(data.bikeModels)
        }
      },
      onError: error =>
        apiErrorCatcher(notifications)({ graphQLErrors: [error] }),
      fetchPolicy: 'network-only',
    },
  )

  const [addDemonstration, { loading: addDemonstrationLoading }] = useMutation<
    AddDemonstrationToBikeMutation,
    AddDemonstrationToBikeMutationVariables
  >(MUTATION_ADD_DEMONSTRATION_TO_BIKE)

  const [deleteDemonstration, { loading: deleteDemonstrationLoading }] =
    useMutation<
      DeleteDemonstrationToBikeMutation,
      DeleteDemonstrationToBikeMutationVariables
    >(MUTATION_DELETE_DEMONSTRATION_TO_BIKE)

  const [animatorSetBikeStolen] = useMutation<
    AnimatorSetBikeStolenMutation,
    AnimatorSetBikeStolenMutationVariables
  >(MUTATION_ANIMATOR_SET_BIKE_STOLEN)

  const [addSold, { loading: addSoldLoading }] = useMutation<
    AddSoldToBikeMutation,
    AddSoldToBikeMutationVariables
  >(MUTATION_ADD_SOLD_TO_BIKE)

  const [deleteSold, { loading: deleteSoldLoading }] = useMutation<
    DeleteSoldToBikeMutation,
    DeleteSoldToBikeMutationVariables
  >(MUTATION_DELETE_SOLD_TO_BIKE)

  const [editBike, { loading: editBikeLoading }] = useMutation<
    UpdateBikeInfoMutation,
    UpdateBikeInfoMutationVariables
  >(MUTATION_UPDATE_BIKE_INFO)
  const [declairRepair, { loading: declareRepairLoading }] = useMutation<
    DeclairBikeInRepairMutation,
    DeclairBikeInRepairMutationVariables
  >(MUTATION_DECLAIR_BIKE_IN_REPAIR)
  const [updateCurrentRepair, { loading: updateRepairLoading }] = useMutation<
    UpdateBikeCurrentRepairMutation,
    UpdateBikeCurrentRepairMutationVariables
  >(MUTATION_UDPATE_CURRENT_BIKE_REPAIR)
  const [recoverBike, { loading: recoverRepairLoading }] = useMutation<
    RecoverBikeFromRepairMutation,
    RecoverBikeFromRepairMutationVariables
  >(MUTATION_RECOVER_BIKE_FROM_REPAIR)

  const isLoading =
    editBikeLoading ||
    declareRepairLoading ||
    updateRepairLoading ||
    recoverRepairLoading ||
    addDemonstrationLoading ||
    deleteDemonstrationLoading ||
    addSoldLoading ||
    deleteSoldLoading

  /* OtherHooks */
  const notifications = useNotifications()
  const { t } = useTranslation()
  const { id: animatorUserId } = useSelector((state: StoreState) => state.user)
  const {
    register,
    handleSubmit,
    control,
    errors,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<EditBikeInputs>({
    resolver: yupResolver(schema),
  })
  const editedBikeCondition = watch('bikeCondition')

  useEffect(() => {
    if (isOpen && bikeId) {
      getBikeInfo({
        variables: {
          where: {
            id: bikeId,
          },
        },
      })
      getFleetList({
        variables: {
          where:
            userRole === UserRoles.ADMIN
              ? {}
              : {
                  area: { animator: { some: { userId: { equals: userId } } } },
                },
        },
      })
      getBikeModelsList()
      getBikeModels()
    }
  }, [
    userRole,
    userId,
    isOpen,
    bikeId,
    animatorUserId,
    getBikeInfo,
    getFleetList,
    getBikeModelsList,
    getBikeModels,
  ])

  const currentForm: ConditionalStateForm = useMemo(() => {
    if (bike?.bikeCondition.name === BikeConditionType.REPARATION) {
      if (
        editedBikeCondition?.value &&
        editedBikeCondition?.value !== BikeConditionType.REPARATION
      ) {
        return 'END_REPAIR'
      } else {
        return 'UPDATE_CURRENT_REPAIR'
      }
    } else if (editedBikeCondition?.value === BikeConditionType.REPARATION) {
      return 'START_REPAIR'
    }
    return 'OTHER'
  }, [bike, editedBikeCondition])

  const bikeConditionSelectOptions = useMemo(() => {
    if (
      !bike?.bikeCondition.name ||
      bike.bikeCondition.name === BikeConditionType.ON_LOAN
    ) {
      return [
        { value: BikeConditionType.STOLEN, label: t(`animator.bikes.filter.bikeConditions.options.${BikeConditionType.STOLEN}`) }, // prettier-ignore
      ]
    }

    const allOptions = [
      { value: BikeConditionType.DEMONSTRATION, label: t(`animator.bikes.filter.bikeConditions.options.${BikeConditionType.DEMONSTRATION}`) }, // prettier-ignore
      { value: BikeConditionType.OUT_OF_SERVICE, label: t(`animator.bikes.filter.bikeConditions.options.${BikeConditionType.OUT_OF_SERVICE}`) }, // prettier-ignore
      { value: BikeConditionType.REPARATION, label: t(`animator.bikes.filter.bikeConditions.options.${BikeConditionType.REPARATION}`) }, // prettier-ignore
      { value: BikeConditionType.STOCK, label: t(`animator.bikes.filter.bikeConditions.options.${BikeConditionType.STOCK}`) }, // prettier-ignore
      { value: BikeConditionType.SOLD, label: t(`animator.bikes.filter.bikeConditions.options.${BikeConditionType.SOLD}`) }, // prettier-ignore
      { value: BikeConditionType.STOLEN, label: t(`animator.bikes.filter.bikeConditions.options.${BikeConditionType.STOLEN}`) }, // prettier-ignore
    ]
    return allOptions.filter(
      bikeCondition => bikeCondition.value !== bike?.bikeCondition.name,
    )
  }, [bike, t])

  /** Event handlers */
  const handleComponentValueChange = <T extends StateOfUseType | string>(
    component: ComponentRow,
    value: { note: T } | { stateOfUse: T },
  ): void => {
    // Update any info is disabled if bike is currently in loan
    if (
      bike?.bikeCondition.name === BikeConditionType.ON_LOAN ||
      bike?.bikeCondition.name === BikeConditionType.STOLEN
    ) {
      notifications.newNotification({
        type: NotificationTypes.WARNING,
        message: t('animator.bikes.modal.fields.preventChangeDueToCondition'),
      })
      return
    }
    const { name: componentName, id } = component
    setEditedComponents(prevState => ({
      ...prevState,
      [componentName as BikeComponentPartType]: {
        ...prevState[componentName as BikeComponentPartType],
        id,
        touched: true,
        ...value,
      },
    }))
  }

  const computeEditBikePayload = (data: EditBikeInputs): BikeUpdateInput => {
    const editBikePayload: BikeUpdateInput = {}

    if (data.fleet && data.fleet.value !== bike?.fleet.name) {
      editBikePayload.fleet = {
        connect: {
          name: data.fleet.value,
        },
      }
    }

    if (
      data.bikeCondition.value !== BikeConditionType.REPARATION &&
      currentForm === 'OTHER'
    ) {
      editBikePayload.bikeCondition = {
        connect: {
          name: data.bikeCondition.value,
        },
      }
    }

    if (data.bikeModel.value !== bike?.bikeModel.id) {
      editBikePayload.bikeModel = {
        connect: {
          id: data.bikeModel.value,
        },
      }
    }

    if (data.bicycode !== bike?.bicycode) {
      editBikePayload.bicycode = {
        set: data.bicycode,
      }
    }

    if (data.morioId !== bike?.morioId) {
      editBikePayload.morioId = {
        set: data.morioId,
      }
    }

    if (data.keysId !== bike?.keysId) {
      editBikePayload.keysId = {
        set: data.keysId,
      }
    }

    if (data.size.value !== bike?.size) {
      editBikePayload.size = {
        set: data.size.value,
      }
    }

    if (data.childSeat !== bike?.childSeat) {
      editBikePayload.childSeat = {
        set: data.childSeat,
      }
    }

    if (data.padlockId) {
      editBikePayload.padlockId = {
        set: data.padlockId,
      }
    }

    if (data.frameId) {
      editBikePayload.frameId = {
        set: data.frameId,
      }
    }

    const componentsUpdateClause: BikeComponentUpdateWithWhereUniqueWithoutBikeInput[] =
      []
    for (const componentName in editedComponents) {
      const componentValue =
        editedComponents[componentName as BikeComponentPartType]
      if (!componentValue.touched) continue
      const previousComponentValue = bike?.bikeComponents.find(
        c => c.bikeComponentPart.name === componentName,
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type BikeComponentUdpateWithBikeComponentHistoriesCreate = any

      componentsUpdateClause.push({
        where: { id: componentValue.id },
        data: {
          note: { set: componentValue.note ?? previousComponentValue?.note },
          ...(componentValue.stateOfUse &&
          previousComponentValue?.stateOfUse.name !== componentValue.stateOfUse
            ? {
                stateOfUse: { connect: { name: componentValue.stateOfUse } },
                bikeComponentHistories: {
                  create: {
                    noteBefore: previousComponentValue?.note,
                    noteAfter:
                      componentValue.note || previousComponentValue?.note,
                    stateOfUseBefore: {
                      connect: {
                        name: previousComponentValue?.stateOfUse.name,
                      },
                    },
                    stateOfUseAfter: {
                      connect: {
                        name: componentValue.stateOfUse,
                      },
                    },
                  } as BikeComponentUdpateWithBikeComponentHistoriesCreate,
                },
              }
            : {}),
        },
      })
    }

    if (componentsUpdateClause.length > 0) {
      editBikePayload.bikeComponents = {
        update: componentsUpdateClause,
      }
    }
    return editBikePayload
  }

  const onSubmit = (data: EditBikeInputs) => {
    if (
      !isDirty &&
      isEqual(editedComponents, initialEditBikeComponentCondition)
    ) {
      onModalClose()
      return
    }
    let successMessageI18nPath = ''
    const submitEditPromises = []
    // Create a dynamic payload object
    const editBikeMutationPayload: BikeUpdateInput =
      computeEditBikePayload(data)

    if (editedBikeCondition?.value === BikeConditionType.STOLEN) {
      submitEditPromises.push(
        animatorSetBikeStolen({
          variables: {
            isStolen: true,
            bikeId: bike?.id as string,
          }
        }).then(() => !successMessageI18nPath ? (successMessageI18nPath = 'animator.bikes.modal.successNotification.bikeUpdated') : successMessageI18nPath), //prettier-ignore
      )
    } else if (bike?.bikeCondition.name === BikeConditionType.STOLEN) {
      submitEditPromises.push(
        animatorSetBikeStolen({
          variables: {
            isStolen: false,
            bikeId: bike?.id as string,
          }
        }).then(() => !successMessageI18nPath ? (successMessageI18nPath = 'animator.bikes.modal.successNotification.bikeUpdated') : successMessageI18nPath), //prettier-ignore
      )
    }

    if (editedBikeCondition?.value === BikeConditionType.SOLD) {
      if (!data.buyerName || !data.soldDate) {
        return notifications.newNotification({
          type: NotificationTypes.ERROR,
          message: t('animator.bikes.modal.errors.soldEmptyField'),
        })
      }

      submitEditPromises.push(
        addSold({
          variables: {
            bikeId: bike?.id as string,
            data: {
              buyerName: data.buyerName,
              soldAt: data.soldDate
            }
          }
        }).then(() => !successMessageI18nPath ? (successMessageI18nPath = 'animator.bikes.modal.successNotification.bikeUpdated') : successMessageI18nPath), //prettier-ignore
      )
    } else if (bike?.bikeSold?.soldAt) {
      submitEditPromises.push(
        deleteSold({
          variables: {
            bikeId: bike?.id as string,
          }
        }).then(() => !successMessageI18nPath ? (successMessageI18nPath = 'animator.bikes.modal.successNotification.bikeUpdated') : successMessageI18nPath), //prettier-ignore
      )
    }

    if (editedBikeCondition?.value === BikeConditionType.DEMONSTRATION) {
      if (!data.companyName || !data.demoReturnDate) {
        return notifications.newNotification({
          type: NotificationTypes.ERROR,
          message: t('animator.bikes.modal.errors.denoEmptyField'),
        })
      }

      submitEditPromises.push(
        addDemonstration({
          variables: {
            bikeId: bike?.id as string,
            data: {
              companyName: data.companyName,
              returnDate: data.demoReturnDate
            }
          }
        }).then(() => !successMessageI18nPath ? (successMessageI18nPath = 'animator.bikes.modal.successNotification.bikeUpdated') : successMessageI18nPath), //prettier-ignore
      )
    } else if (bike?.bikeDemonstration?.returnDate) {
      submitEditPromises.push(
        deleteDemonstration({
          variables: {
            bikeId: bike?.id as string,
          }
        }).then(() => !successMessageI18nPath ? (successMessageI18nPath = 'animator.bikes.modal.successNotification.bikeUpdated') : successMessageI18nPath), //prettier-ignore
      )
    }

    if (currentForm === 'START_REPAIR') {
      submitEditPromises.push(
        declairRepair({
          variables: {
            bikeId: bike?.id as string,
            repairerLocation: data.beforeRepair.repairerLocation,
            resolvedDate: data.beforeRepair.resolvedDate,
            note: data.beforeRepair.note,
          },
        }).then(() => (successMessageI18nPath = 'animator.bikes.modal.successNotification.repairCreated')), //prettier-ignore
      )
    } else if (currentForm === 'UPDATE_CURRENT_REPAIR') {
      submitEditPromises.push(
        updateCurrentRepair({
          variables: {
            repairId: bike?.repairs[0].id as string,
            repairerLocation: data.beforeRepair.repairerLocation,
            resolvedDate: data.beforeRepair.resolvedDate,
            note: data.beforeRepair.note,
          },
        })
        .then(() => (successMessageI18nPath = 'animator.bikes.modal.successNotification.repairUpdated')), //prettier-ignore
      )
    } else if (currentForm === 'END_REPAIR') {
      submitEditPromises.push(
        recoverBike({
          variables: {
            bikeId: bike?.id as string,
            price: data.afterRepair.price,
            newBikeCondition: data.bikeCondition.value,
            note: data.afterRepair.note,
          },
        }).then(() => (successMessageI18nPath = 'animator.bikes.modal.successNotification.repairEnded')), //prettier-ignore
      )
    }

    if (
      editBikeMutationPayload.fleet ||
      editBikeMutationPayload.bikeCondition ||
      editBikeMutationPayload.bikeComponents ||
      editBikeMutationPayload.bikeModel ||
      editBikeMutationPayload.bicycode ||
      editBikeMutationPayload.morioId ||
      editBikeMutationPayload.keysId ||
      editBikeMutationPayload.size ||
      editBikeMutationPayload.childSeat ||
      editBikeMutationPayload.padlockId ||
      editBikeMutationPayload.frameId
    ) {
      submitEditPromises.push(
        editBike({
          variables: {
            where: {
              id: bike?.id as string,
            },
            data: editBikeMutationPayload,
          },
        }).then(() => !successMessageI18nPath ? (successMessageI18nPath = 'animator.bikes.modal.successNotification.bikeUpdated') : successMessageI18nPath), //prettier-ignore
      )
    }

    if (submitEditPromises.length > 0) {
      Promise.all(submitEditPromises)
        .then(() => {
          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t(successMessageI18nPath, {
              stickerId: bike?.stickerId,
            }),
          })

          onModalClose(true)
        })
        .catch(e => {
          if (e.message === 'COMPANY_NOT_FOUND_NOT_FOUND')
            return notifications.newNotification({
              type: NotificationTypes.ERROR,
              message: t('animator.bikes.modal.errors.companyNotExist'),
            })

          if (e.message === 'MIRIO_ERROR_COULD_NOT_DECLARE_STOLEN') {
            return notifications.newNotification({
              type: NotificationTypes.ERROR,
              message: t('animator.bikes.modal.errors.morioIdNotFound'),
            })
          }

          notifications.newNotification({
            type: NotificationTypes.ERROR,
            message: e.message,
          })

          return apiErrorCatcher(notifications)
        })
    }
  }
  const onModalClose = (shouldRefetch = false) => {
    resetAllStates()
    closeModal(shouldRefetch)
  }

  const demoReturnDate = bike?.bikeDemonstration?.returnDate
    ? new Date(bike?.bikeDemonstration?.returnDate)
    : null

  const bikeSoldAt = bike?.bikeSold?.soldAt
    ? new Date(bike?.bikeSold?.soldAt)
    : null

  return (
    <Modal isOpen={internIsOpen} onBackgroundClick={onModalClose}>
      <Popup
        overflowY="auto"
        closable
        title={t('animator.bikes.modal.title')}
        onClose={() => onModalClose(false)}
        maxWidth={750}
        footer={
          <Fragment>
            <div css={{ marginRight: 14 }}>
              <Button onClick={() => onModalClose(false)} type="tertiary">
                {t('shared.button.cancel')}
              </Button>
            </div>
            <Button
              type="primary"
              submit
              onClick={handleSubmit(onSubmit)}
              loading={isLoading}
            >
              {t('forms.button.save')}
            </Button>
          </Fragment>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div css={{ display: 'flex', flexDirection: 'column', padding: 30 }}>
            <IdentifierInputs
              bike={bike}
              control={control}
              errors={errors}
              fleetOptions={fleetOptions}
              bikeModelOptions={bikeModelOptions}
              bikeModels={bikeModels}
            />
            <Controller
              name="bikeCondition"
              control={control}
              defaultValue={{
                value: bike?.bikeCondition.name,
                label: t(
                  `animator.bikes.filter.bikeConditions.options.${bike?.bikeCondition.name}`,
                ),
              }}
              render={({ onChange, value, name }) => (
                <InputSelect
                  label={t('animator.bikes.modal.fields.bikeCondition')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  options={bikeConditionSelectOptions}
                  noOptionMessage={t(
                    'animator.bikes.filter.bikeConditions.options.noOptions',
                  )}
                />
              )}
            />

            {(editedBikeCondition
              ? editedBikeCondition?.value === BikeConditionType.SOLD
              : bike?.bikeCondition.name === BikeConditionType.SOLD) && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <div css={{ marginRight: 20, flex: 3 }}>
                  <Controller
                    name="soldDate"
                    control={control}
                    defaultValue={bikeSoldAt}
                    render={({ onChange, value }) => (
                      <DateTime
                        label={t('animator.bikes.modal.fields.soldDate')}
                        block={false}
                        value={value}
                        onChange={onChange}
                        type="date"
                      />
                    )}
                  />
                </div>
                <div css={{ flex: 5 }}>
                  <Controller
                    name="buyerName"
                    control={control}
                    defaultValue={bike?.bikeSold?.buyerName || ''}
                    render={({ onChange, value, name }) => (
                      <Input
                        type="text"
                        name={name}
                        value={value || null}
                        onChange={onChange}
                        label={t('animator.bikes.modal.fields.buyer')}
                      />
                    )}
                  />
                </div>
              </div>
            )}

            {(editedBikeCondition
              ? editedBikeCondition?.value === BikeConditionType.DEMONSTRATION
              : bike?.bikeCondition.name ===
                BikeConditionType.DEMONSTRATION) && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <div css={{ marginRight: 20, flex: 5 }}>
                  <Controller
                    name="companyName"
                    control={control}
                    defaultValue={bike?.bikeDemonstration?.company.name}
                    render={({ onChange, value, name }) => (
                      <Input
                        type="text"
                        name={name}
                        error={!!errors.companyName}
                        helperText={errors.companyName?.message}
                        value={value}
                        onChange={onChange}
                        label={t('animator.bikes.modal.fields.company')}
                      />
                    )}
                  />
                </div>
                <div css={{ flex: 3 }}>
                  <Controller
                    name="demoReturnDate"
                    control={control}
                    defaultValue={demoReturnDate}
                    render={({ onChange, value }) => (
                      <DateTime
                        label={t('animator.bikes.modal.fields.returnDate')}
                        block={false}
                        value={value || null}
                        onChange={onChange}
                        type="dateTime"
                      />
                    )}
                  />
                </div>
              </div>
            )}

            <EditBikeConditionalInputs
              control={control}
              register={register}
              bikeInfos={bike}
              currentForm={currentForm}
            >
              {bike?.bikeComponents?.length && (
                <EditBikeComponentsCondition
                  components={bike.bikeComponents as BikeComponent[]}
                  editedComponents={editedComponents}
                  onStarClickAction={(component, value) =>
                    handleComponentValueChange<StateOfUseType>(component, value)
                  }
                  onNoteModalSubmit={(component, value) =>
                    handleComponentValueChange<string>(component, value)
                  }
                />
              )}
            </EditBikeConditionalInputs>
          </div>
        </form>
      </Popup>
    </Modal>
  )
}

export default EditModal
