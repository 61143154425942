/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import CardFoldable from '../../../../../components/Card/Foldable'
import InputSelect from '../../../../../components/InputSelect'
import Input from '../../../../../components/Input'
import CardTitle from '../../../../../components/Card/CardTitle'

import { TextVariant } from '../../../../../hooks/useVariant/variants/text'
import { SelectVariant } from '../../../../../hooks/useVariant/variants/select'
import { QUERY_ALL_FLEET_NAMES } from '../../../../../utils/gql/queries'
import { StoreState } from '../../../../../redux/rootReducer'
import { UserRoles } from '@goodwatt/shared/dist/types'
import {
  GetAllFleetNamesQuery,
  GetAllFleetNamesQueryVariables,
} from '../../../../../__generated__/graphql'
import CompanyDeploymentsGroupedList from '../CompanyDeploymentsList'
import { tryParseInt, useFilters } from '../../../../../hooks/useFilters'

const typeFilterOptions = [
  'NONE',
  'COMPANY',
  'COLECTIVITY',
  'PUBLIC_ADMINISTRATION',
  'HEALTHCARE',
  'ASSOCIATION',
  'CLUB_COMPANY',
  'OTHER',
]

const CompaniesAcceptedList: React.FC<{ pageSize: number }> = ({
  pageSize,
}) => {
  const { t } = useTranslation()
  const { filters, register } = useFilters()
  const { id: userId, role: userRole } = useSelector(
    (state: StoreState) => state.user,
  )

  const isAdmin = userRole === UserRoles.ADMIN

  const { data } = useQuery<
    GetAllFleetNamesQuery,
    GetAllFleetNamesQueryVariables
  >(QUERY_ALL_FLEET_NAMES, {
    variables: {
      where: isAdmin
        ? {}
        : { area: { animator: { some: { userId: { equals: userId } } } } },
    },
  })
  const theme = useTheme()

  const fleetFilterOptions = React.useMemo(
    () =>
      data?.fleets
        ? data?.fleets.map(({ name }: { name: string }) => ({
            value: name,
            label: name,
          }))
        : [],
    [data],
  )

  return (
    <div css={{ marginBottom: '30px' }}>
      <CardFoldable
        title={
          <CardTitle
            statusColor={theme.colors.greenLegend}
            label={t('animator.companies.inActivityTitleLabel')}
          />
        }
        isDefaultFolded={false}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            marginTop: '2.1rem',
            marginBottom: '2.1rem',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <div
            css={{
              display: 'flex',
              flex: 1,
              minWidth: '220px',
              paddingRight: '17px',
              width: '100%',
              [theme.media.mobile]: {
                paddingRight: '0px',
              },
            }}
          >
            <InputSelect
              variant={SelectVariant.white}
              label={t('animator.companies.filter.fleets')}
              withError={false}
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t('animator.companies.filter.options.all'),
                },
                ...fleetFilterOptions,
              ]}
              {...register('fleet')}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flex: 1,
              minWidth: '220px',
              paddingRight: '17px',
              width: '100%',
              [theme.media.mobile]: {
                paddingRight: '0px',
                marginTop: '14px',
              },
            }}
          >
            <InputSelect
              variant={SelectVariant.white}
              withError={false}
              label={t('animator.companies.filter.type')}
              defaultValue="all"
              options={[
                {
                  value: 'all',
                  label: t('animator.companies.filter.options.all'),
                },
                ...typeFilterOptions.map(value => ({
                  value,
                  label: t(`animator.companies.filter.options.type.${value}`),
                })),
              ]}
              {...register('type', tryParseInt)}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flex: 2,
              minWidth: '220px',
              [theme.media.tablet]: {
                marginTop: '14px',
              },
            }}
          >
            <Input
              icon="magnifier"
              variant={TextVariant.white}
              withError={false}
              {...register('multiFilter')}
            />
          </div>
        </div>
        <div css={{ margin: '0 -2.1rem' }}>
          <CompanyDeploymentsGroupedList filters={filters} />
        </div>
      </CardFoldable>
    </div>
  )
}

export default CompaniesAcceptedList
