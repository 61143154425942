/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, {
  Fragment,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { constants } from '@goodwatt/shared'
import { useMutation, useQuery } from '@apollo/client'

import Button from '../../../../../../components/Button'
import Icon from '../../../../../../components/Icon'
import Input from '../../../../../../components/Input'
import Modal from '../../../../../../components/Modal'
import Popup from '../../../../../../components/Popup'
import TableHeader from './TableHeader'
import TableCells from './TableCells'

import {
  QUERY_GET_BIKE_COMPONENT_HISTORIES,
  QUERY_GET_LOAN_PACKAGE_HISTORIES,
} from '../../../../../../utils/gql/queries'

import Loader from '../../../../../../components/Loader'
import { TableCellsProps } from './TableCells'
import {
  MUTATION_CREATE_RECLAIM_ON_DEPOSIT,
  MUTATION_RELEASE_DEPOSITS,
} from '../../../../../../utils/gql/mutations'

import apiErrorCatcher from '../../../../../../utils/apiErrorCatcher'
import {
  NotificationTypes,
  useNotifications,
} from '../../../../../../contexts/NotificationContext'

import TextArea from '../../../../../../components/TextArea'
import { computeRetentionAmount, computeTableInfos } from './helpers'
import ReleaseDepositConfirmationModal from './ReleaseDepositConfirmationModal'
import ReclaimDepositConfirmationModal from './ReclaimDepositConfirmationModal'
import { employeeNameFormatter } from '../../../../../../utils/formatter'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import i18n from '../../../../../../i18n/config'
import { yupResolver } from '@hookform/resolvers'
import {
  CreateReclaimOnDepositMutation,
  CreateReclaimOnDepositMutationVariables,
  GetBikeComponentHistoriesQuery,
  GetBikeComponentHistoriesQueryVariables,
  GetLoanPackageHistoriesQuery,
  GetLoanPackageHistoriesQueryVariables,
  BikeLoan,
} from '../../../../../../__generated__/graphql'

const schema = yup.object().shape({
  depositAmount: yup.number(),
  reason: yup.string().required(i18n.t('forms.errors.cautionReason')),
})

type DepositReleaseInputs = {
  depositAmount: number
  reason: string
}

interface DepositRetentionModalProps {
  closeModal: () => void
  refetch?: () => void
  employee: any | null
  employeeDeploymentId: string
}

const DepositRetentionModal: React.FC<DepositRetentionModalProps> = ({
  closeModal,
  employee,
  employeeDeploymentId,
  refetch,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const notifications = useNotifications()
  const whereClause = {
    variables: {
      where: {
        bikeLoan: {
          employeeDeploymentId: {
            equals: employeeDeploymentId,
          },
          employee: {
            id: {
              equals: employee?.id,
            },
          },
        },
      },
    },
  }

  const { register, handleSubmit, errors, control, getValues } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [amount, setAmount] = useState('0')

  const [amountError, setAmountError] = useState('')

  const currentBikeLoan = employee.bikeLoan.find(
    (b: BikeLoan) => b.employeeDeploymentId === employeeDeploymentId,
  )

  const { data: bikeComponents, loading: loadingBikeComponent } = useQuery<
    GetBikeComponentHistoriesQuery,
    GetBikeComponentHistoriesQueryVariables
  >(QUERY_GET_BIKE_COMPONENT_HISTORIES, whereClause)

  const { data: loanPackages, loading: loadingLoanPackage } = useQuery<
    GetLoanPackageHistoriesQuery,
    GetLoanPackageHistoriesQueryVariables
  >(QUERY_GET_LOAN_PACKAGE_HISTORIES, whereClause)

  const [createReclaimOnDeposit, { loading: mutationLoading }] = useMutation<
    CreateReclaimOnDepositMutation,
    CreateReclaimOnDepositMutationVariables
  >(MUTATION_CREATE_RECLAIM_ON_DEPOSIT, {
    refetchQueries: ['GetDeploymentEmployees'],
  })

  const [releaseDeposits] = useMutation(MUTATION_RELEASE_DEPOSITS, {
    refetchQueries: ['GetDeploymentEmployees'],
  })

  const estimatedTotal = useMemo(
    // @ts-ignore
    () => computeRetentionAmount(bikeComponents, loanPackages).toFixed(2),
    [loanPackages, bikeComponents],
  )

  useEffect(() => {
    setAmount(estimatedTotal)
  }, [estimatedTotal])

  const componentsData: {
    cells: TableCellsProps['data']
    reason: string
  } = useMemo(() => {
    return computeTableInfos(t, bikeComponents, loanPackages)
  }, [bikeComponents, loanPackages, t])

  const reclaimDeposite = (data: DepositReleaseInputs) => {
    setAmountError('')

    if (parseFloat(amount) > constants.DEPOSIT_AMOUNT) {
      setAmountError(
        t('animator.company.employees.selected.modals.deposit.errorAmount'),
      )

      return setPage('')
    }

    return createReclaimOnDeposit({
      variables: {
        amount: parseFloat(amount.replace(',', '.')),
        employeeId: employee?.id || '',
        reason: data.reason,
        employeeDeploymentId: employeeDeploymentId,
      },
    })
      .then(result => {
        if (result.data?.createReclaimOnDeposit) {
          closeModal()
          if (refetch) refetch()
        } else {
          notifications.newNotification({
            type: NotificationTypes.ERROR,
            message: t('forms.errors.anErrorOccured'),
          })
        }
      })
      .catch(apiErrorCatcher(notifications))
  }

  const releaseDeposite = useCallback(() => {
    setAmountError('')

    return releaseDeposits({
      variables: {
        employeeIds: [employee?.id],
      },
    })
      .then(result => {
        if (result.data?.releaseDeposits) {
          closeModal()
          if (refetch) refetch()

          notifications.newNotification({
            type: NotificationTypes.SUCCESS,
            message: t('forms.success.releaseDepositSuccess'),
          })
        } else {
          notifications.newNotification({
            type: NotificationTypes.ERROR,
            message: t('forms.errors.anErrorOccured'),
          })
        }
      })
      .catch(apiErrorCatcher(notifications))
  }, [releaseDeposits, closeModal, employee, notifications, refetch, t])

  const [page, setPage] = useState<string>()

  if (loadingBikeComponent || loadingLoanPackage) return <Loader />

  const sendEmail = async () => {
    // eslint-disable-next-line no-restricted-globals
    location.href = `mailto:${employee?.user.email}?subject=&body=${getValues(
      'reason',
    )}`
  }

  const isChequeAndEmpty =
    employee?.depositByCheque && Number(estimatedTotal) === 0

  return (
    <Modal isOpen onBackgroundClick={closeModal}>
      {page === 'CONFIRM_RELEASE_DEPOSIT' && (
        <ReleaseDepositConfirmationModal
          closeModal={closeModal}
          onCancel={() => setPage('')}
          onSubmit={() => releaseDeposite()}
          name={employeeNameFormatter(employee?.firstName, employee?.lastName)}
        />
      )}
      {page === 'CONFIRM_RECLAIM_DEPOSIT' && (
        <ReclaimDepositConfirmationModal
          closeModal={closeModal}
          onCancel={() => setPage('')}
          onSubmit={async () => {
            await handleSubmit(reclaimDeposite)()
          }}
          amount={amount}
          name={employeeNameFormatter(employee?.firstName, employee?.lastName)}
        />
      )}
      {page === 'CONFIRM_EMAIL_DEPOSIT' && (
        <ReclaimDepositConfirmationModal
          closeModal={closeModal}
          onCancel={() => setPage('')}
          onSubmit={async () => {
            await handleSubmit(reclaimDeposite)()
          }}
          amount={amount}
          title={t(
            'animator.company.employees.selected.modals.deposit.depositByChequeSendEmail.title',
          )}
          description={t(
            'animator.company.employees.selected.modals.deposit.depositByChequeSendEmail.body',
            {
              name: employeeNameFormatter(
                employee?.firstName,
                employee?.lastName,
              ),
            },
          )}
          name={employeeNameFormatter(employee?.firstName, employee?.lastName)}
        />
      )}
      <Popup
        visibility={!page}
        maxWidth={1000}
        title={
          !isChequeAndEmpty
            ? t('animator.company.employees.selected.modals.deposit.title')
            : t(
                'animator.company.employees.selected.modals.deposit.relaseByChequeTitle',
              )
        }
        closable={false}
        footer={
          <Fragment>
            <Button
              type="tertiary"
              onClick={closeModal}
              disabled={mutationLoading}
            >
              {t('forms.button.cancel')}
            </Button>
            <div css={{ marginLeft: '14px' }}>
              <Button
                submit
                type="primarySuccess"
                loading={mutationLoading}
                onClick={async () => {
                  if (isChequeAndEmpty) {
                    releaseDeposite()
                  } else {
                    setPage('CONFIRM_RELEASE_DEPOSIT')
                  }
                }}
              >
                {isChequeAndEmpty
                  ? t('forms.button.confirm')
                  : t(
                      'animator.company.employees.selected.modals.deposit.buttons.releaseDeposit',
                    )}
              </Button>
            </div>
            {!employee?.depositByCheque && employee.depositId && (
              <div css={{ marginLeft: '14px' }}>
                <Button
                  submit
                  type="primaryError"
                  loading={mutationLoading}
                  onClick={handleSubmit(_ =>
                    setPage('CONFIRM_RECLAIM_DEPOSIT'),
                  )}
                >
                  {t(
                    'animator.company.employees.selected.modals.deposit.buttons.reclaimDeposit',
                  )}
                </Button>
              </div>
            )}

            {employee?.depositByCheque && Number(estimatedTotal) > 0 && (
              <div css={{ marginLeft: '14px', textTransform: 'uppercase' }}>
                <Button
                  submit
                  type="primaryError"
                  loading={mutationLoading}
                  onClick={handleSubmit(_ => {
                    setPage('CONFIRM_EMAIL_DEPOSIT')
                    sendEmail()
                  })}
                >
                  <Icon type="mail" />
                  <span
                    css={{ marginLeft: '12px', textTransform: 'uppercase' }}
                  >
                    {t(
                      'animator.company.employees.selected.modals.deposit.buttons.depositEmail',
                    )}
                  </span>
                </Button>
              </div>
            )}
          </Fragment>
        }
      >
        {!isChequeAndEmpty ? (
          [
            <div
              key="1"
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    'animator.company.employees.selected.modals.deposit.idBike',
                    {
                      ref: currentBikeLoan?.bike?.stickerId,
                    },
                  ),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t(
                    'animator.company.employees.selected.modals.deposit.employee',
                    {
                      name: employeeNameFormatter(
                        employee?.firstName,
                        employee?.lastName,
                      ),
                    },
                  ),
                }}
              />
            </div>,

            <div key="2" css={{ width: '100%', overflowX: 'auto' }}>
              <div
                css={{
                  minWidth: '680px',
                }}
              >
                <TableHeader />
                <TableCells data={componentsData.cells} />
              </div>
            </div>,

            <div
              key="3"
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '24px',
              }}
            >
              <p css={{ textAlign: 'center' }}>
                <strong>
                  {t(
                    'animator.company.employees.selected.modals.deposit.totalDeposit',
                  )}
                </strong>
              </p>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '12px',
                }}
              >
                <div css={{ width: 100 }}>
                  <Input
                    block
                    type="number"
                    name="depositAmount"
                    error={!!errors.depositAmount}
                    helperText={errors.depositAmount?.message}
                    register={register}
                    defaultValue={estimatedTotal}
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                  />
                </div>
                <button
                  css={{ marginLeft: 14 }}
                  onClick={() => setAmount(estimatedTotal)}
                >
                  <Icon type="reload" color={theme.colors.gray3} />
                </button>
              </div>
              {amountError && (
                <p
                  css={{
                    fontSize: '1.4rem',
                    color: theme.colors.redLegend,
                    marginTop: '8px',
                  }}
                >
                  {amountError}
                </p>
              )}
              <div css={{ width: '100%', marginTop: 18 }}>
                <Controller
                  name="reason"
                  control={control}
                  render={({ onChange, onBlur, value, name }) => (
                    <TextArea
                      name={name}
                      type="text"
                      register={register}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      block
                      placeholder={t(
                        'animator.company.employees.selected.modals.deposit.reasonPlaceholder',
                      )}
                      error={!!errors.reason}
                      helperText={errors.reason?.message}
                    />
                  )}
                />
              </div>
            </div>,

            employee?.depositByCheque ? (
              <div
                key="4"
                css={{
                  margin: '18px 0',
                  textAlign: 'center',
                }}
              >
                <span css={{ color: theme.colors.redLegend }}>
                  {t(
                    'animator.company.employees.selected.modals.deposit.depositByCheque',
                  )}
                </span>
              </div>
            ) : null,

            !employee?.depositId && !employee?.depositByCheque ? (
              <div
                key="5"
                css={{
                  margin: '18px 0',
                  textAlign: 'center',
                }}
              >
                <span css={{ color: theme.colors.redLegend }}>
                  {t(
                    'animator.company.employees.selected.modals.deposit.noDeposit',
                  )}
                </span>
              </div>
            ) : null,
          ]
        ) : (
          <div
            key="4"
            css={{
              margin: '18px 0',
              textAlign: 'center',
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'animator.company.employees.selected.modals.deposit.relaseByCheque',
                  {
                    name: employeeNameFormatter(
                      employee?.firstName,
                      employee?.lastName,
                    ),
                  },
                ),
              }}
            />
          </div>
        )}
      </Popup>
    </Modal>
  )
}

export default DepositRetentionModal
